import React, { Component } from "react";
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import cardrona1 from '../img/5-Vroom with a View/2-Food/Cardrona Hotel/Cardrona Hotel 1.png';
import cardrona2 from '../img/5-Vroom with a View/2-Food/Cardrona Hotel/Cardrona Hotel 2.png';
import olivo1 from '../img/5-Vroom with a View/2-Food/Olivo/Olivo.png';
import oystercove1 from '../img/5-Vroom with a View/2-Food/Oyster Cove Restaurant _ Bar/Oyster Cove Restaurant _ Bar 1.png';
import oystercove2 from '../img/5-Vroom with a View/2-Food/Oyster Cove Restaurant _ Bar/Oyster Cove Restaurant _ Bar 2.png';
import miles1 from '../img/5-Vroom with a View/2-Food/Miles Better Pies/Miles Better Pies.png';
import greendragon1 from '../img/5-Vroom with a View/2-Food/Green Dragon Inn/Green Dragon Inn.png';

import NextArrow from '../img/slider-right.png';
import PrevArrow from '../img/slider-left.png';
import NextSliderArrow from '../img/Arrow Right.png';
import PrevSliderArrow from '../img/Arrow Left.png';

const SampleNextArrow = ({ onClick }) => (
  <div className="custom-arrow next" onClick={(e) => { onClick(e); handleClick('NextArrow'); }}>
    <img src={NextArrow} alt="Next" />
  </div>
);

const SamplePrevArrow = ({ onClick }) => (
  <div className="custom-arrow prev" onClick={(e) => { onClick(e); handleClick('PrevArrow'); }}>
    <img src={PrevArrow} alt="Previous" />
  </div>
);
  const SliderPrevArrow = ({ onClick }) => (
    <div className="custom-arrow next slick-arrow slick-next" onClick={onClick}>
      <img src={PrevSliderArrow} alt="Previous" />
    </div>
  );
  
  const SliderNextArrow = ({ onClick }) => (
    <div className="custom-arrow prev slick-arrow slick-prev" onClick={onClick}>
      <img src={NextSliderArrow} alt="Next" />
    </div>
  );

  const handleClick = (section) => {
    const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

    if (isMobile) {
      // Console logs for debugging
      //console.log(`Hover detected on section: ${section}`);
    
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'trackEventNoEcommerce',
        eventDetails: {
          category: 'photoscarousel',
          action: 'arrowclick',
          label: 'winterflydeas',
          value: 'food',
        },
      });
    
      // Console log after pushing to dataLayer
      //console.log('DataLayer after push:', window.dataLayer);
    }
  };

  const copyrightTexts = [
    [
      '',
      '',
    ],
    [
      '',
      '',
    ],
    [
      '<span class="white">© Oyster Cove Restaurant & Bar</span>',
      '<span class="white">© Oyster Cove Restaurant & Bar</span>',
    ],
    [
      '',
      '',
    ],
    [
      '<span>© Cardrona Hotel</span>',
      '<span>© Cardrona Hotel</span>',
    ],
  ];

export default class SimpleSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      lightboxImages: [
        [miles1],
        [olivo1],
        [oystercove1, oystercove2],
        [greendragon1],
        [cardrona1,cardrona2],
      ],
      lightboxPhotoIndexes: Array(4).fill(0).map(() => 0), // Individual indexes for each banner
      openLightbox: Array(4).fill(false), // Array to track open lightbox for each slider
      isMobile: window.innerWidth <= 980, // Initial check for mobile screen size
      activeIndex: null, // To track the active slider
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 980 });
  };

  openLightboxForIndex = (index) => {
    this.setState((prevState) => ({
      isOpen: prevState.isMobile,
      openLightbox: prevState.openLightbox.map((item, idx) => (idx === index ? true : item)),
      activeIndex: index,
      photoIndex: 0, // Set photoIndex to 0 when opening the lightbox for a particular index
    }));
  };


  render() {
    const {
      isOpen,
      lightboxImages,
      lightboxPhotoIndexes,
      isMobile,
      photoIndex,
      openLightbox,
      activeIndex,
    } = this.state;

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4.99,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPrevArrow />,
        draggable: false, // Disable mouse dragging
        swipe: false, 
        responsive: [
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 3.99,
            }
          },
          {
            breakpoint: 980,
            settings: {
              slidesToShow: 2.99,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1.99,
            }
          },
        ],
    };

    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
      };
      return (
        <div className="lodging-carousel">
          <Slider {...settings}>
            {lightboxImages.map((images, index) => (
              <div key={index}>
                <div className="thumbnail-banner">
                  <Slider {...settings2} className="">
                    {images.map((image, imgIndex) => (
                      <div
                      className="thumbnail-img thumbnail-banner-slide-img"
                      key={imgIndex}
                      onClick={() => this.openLightboxForIndex(index)}
                    >
                      <img className="full-width" src={image} alt={`Image ${imgIndex}`} />
                      <div
                          className="header-copyright top-right"
                          dangerouslySetInnerHTML={{ __html: copyrightTexts[index][imgIndex] }}
                        />
                    </div>
                    ))}
                  </Slider>
                </div>
              {index === 0 && (
                <div className="content">
                    <p className="number violet weight-medium">5</p>
                    <h3>Miles Better Pies, <br/><span className="violet">Te Anau</span></h3>
                    <p>Packed with fresh, flavourful ingredients that are worth taking along on the road with you</p>
                    <p className="small-text light-italic location">1 Lakefront Drive, Te Anau 9679</p>
                </div>  
              )}
              {index === 1 && (
                <div className="content">
                    <p className="number violet weight-medium">1</p>
                    <h3>Olivo, <br/><span className="violet">Marlborough</span></h3>
                    <p>Stop by Wairarapa's oldest commercial olive grove to sample the fruit of the season</p>
                    <p className="small-text light-italic location">136 Hinakura Road, Martinborough 5784</p>
                </div>  
              )}
              {index === 2 && (
                <div className="content">
                    <p className="number violet weight-medium">2</p>
                    <h3>Oyster Cove Restaurant & Bar, <br/><span className="violet">Bluff</span></h3>
                    <p>Savour the famous yet elusive Bluff oysters, harvested fresh off the coast during winter</p>
                    <p className="small-text light-italic location">8 Ward Parade, Bluff 9814</p>
                </div>  
              )}
              {index === 3 && (
                <div className="content">
                    <p className="number violet weight-medium">3</p>
                    <h3>Green Dragon Inn, <br/><span className="violet">Matamata</span></h3>
                    <p>Step into Hobbiton to enjoy a feast fit for a cross-country adventure that beckons</p>
                    <p className="small-text light-italic location">Hobbiton Movie Set, Matamata 3472</p>
                </div>  
              )}
              {index === 4 && (
                <div className="content">
                    <p className="number violet weight-medium">4</p>
                    <h3>Cardrona Hotel, <br/><span className="violet">Cardrona</span></h3>
                    <p>Dine at this historic hotel, enjoying a delicious meal with views of The Remarkables</p>
                    <p className="small-text light-italic location">Cardrona Valley Road, Cardrona 9382</p>
                </div>  
              )}
                {/* Lightbox for mobile */}
                {isOpen && isMobile && openLightbox[index] && (
                  <Lightbox
                    mainSrc={lightboxImages[index][photoIndex]}
                    nextSrc={lightboxImages[index][(photoIndex + 1) % lightboxImages[index].length]}
                    prevSrc={lightboxImages[index][(photoIndex + lightboxImages[index].length - 1) % lightboxImages[index].length]}
                    onCloseRequest={() => this.setState({ isOpen: false, openLightbox: Array(4).fill(false), activeIndex: null })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + lightboxImages[index].length - 1) % lightboxImages[index].length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % lightboxImages[index].length,
                      })
                    }
                  />
                )}
              </div>
            ))}
          </Slider>
        </div>
      );
    }
  }