import React, { Component } from "react";
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import nestkitchen1 from '../img/2-Peak Thrills/2-Food/Nest Kitchen _ Bar/Nest Kitchen _ Bar 1.png';
import nestkitchen2 from '../img/2-Peak Thrills/2-Food/Nest Kitchen _ Bar/Nest Kitchen _ Bar 2.png';
import kika1 from '../img/2-Peak Thrills/2-Food/Kika/Kika 1.png';
import kika2 from '../img/2-Peak Thrills/2-Food/Kika/Kika 2.png';
import oesteria1 from '../img/2-Peak Thrills/2-Food/Osteria Ohakune/Osteria Ohakune 1.png';
import oesteria2 from '../img/2-Peak Thrills/2-Food/Osteria Ohakune/Osteria Ohakune 2.png';
import knoll1 from '../img/2-Peak Thrills/2-Food/Knoll Ridge Chalet/Knoll Ridge Chalet 1.png';
import knoll2 from '../img/2-Peak Thrills/2-Food/Knoll Ridge Chalet/Knoll Ridge Chalet 2.png';
import NextArrow from '../img/slider-right.png';
import PrevArrow from '../img/slider-left.png';
import NextSliderArrow from '../img/Arrow Right.png';
import PrevSliderArrow from '../img/Arrow Left.png';

const SampleNextArrow = ({ onClick }) => (
  <div className="custom-arrow next" onClick={(e) => { onClick(e); handleClick('NextArrow'); }}>
    <img src={NextArrow} alt="Next" />
  </div>
);

const SamplePrevArrow = ({ onClick }) => (
  <div className="custom-arrow prev" onClick={(e) => { onClick(e); handleClick('PrevArrow'); }}>
    <img src={PrevArrow} alt="Previous" />
  </div>
);

  const SliderPrevArrow = ({ onClick }) => (
    <div className="custom-arrow next slick-arrow slick-next" onClick={onClick}>
      <img src={PrevSliderArrow} alt="Previous" />
    </div>
  );
  
  const SliderNextArrow = ({ onClick }) => (
    <div className="custom-arrow prev slick-arrow slick-prev" onClick={onClick}>
      <img src={NextSliderArrow} alt="Next" />
    </div>
  );

  const handleClick = (section) => {
    const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

    if (isMobile) {
      // Console logs for debugging
      //console.log(`Hover detected on section: ${section}`);
    
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'trackEventNoEcommerce',
        eventDetails: {
          category: 'photoscarousel',
          action: 'arrowclick',
          label: 'winterflydeas',
          value: 'food',
        },
      });
    
      // Console log after pushing to dataLayer
      //console.log('DataLayer after push:', window.dataLayer);
    }
  };


  const copyrightTexts = [
    [
      '<span class="white">© Nest Kitchen & Bar</span>',
      '<span>© Nest Kitchen & Bar</span>',
    ],
    [
      '<span class="white">© Kika</span>',
      '<span class="white">© Kika</span>',
    ],
    [
      '<span class="white">© Love Taupo</span>',
      '',
    ],
    [
      '<span class="white">© Osteria Ohakune</span>',
      '<span class="white">© Osteria Ohakune</span>',
    ],
  ];

export default class SimpleSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      lightboxImages: [
        [nestkitchen1, nestkitchen2],
        [kika1, kika2],
        [knoll1, knoll2],
        [oesteria1, oesteria2],
      ],
      lightboxPhotoIndexes: Array(4).fill(0).map(() => 0), // Individual indexes for each banner
      openLightbox: Array(4).fill(false), // Array to track open lightbox for each slider
      isMobile: window.innerWidth <= 980, // Initial check for mobile screen size
      activeIndex: null, // To track the active slider
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 980 });
  };

  openLightboxForIndex = (index) => {
    this.setState((prevState) => ({
      isOpen: prevState.isMobile,
      openLightbox: prevState.openLightbox.map((item, idx) => (idx === index ? true : item)),
      activeIndex: index,
      photoIndex: 0, // Set photoIndex to 0 when opening the lightbox for a particular index
    }));
  };


  render() {
    const {
      isOpen,
      lightboxImages,
      lightboxPhotoIndexes,
      isMobile,
      photoIndex,
      openLightbox,
      activeIndex,
    } = this.state;

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPrevArrow />,
        draggable: false, // Disable mouse dragging
        swipe: false,  
        responsive: [
          {
            breakpoint: 980,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
            }
          },
        ],
    };

    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
      };
      return (
        <div className="lodging-carousel food-carousel four-slides">
          <Slider {...settings}>
            {lightboxImages.map((images, index) => (
              <div key={index}>
                <div className="thumbnail-banner">
                  <Slider {...settings2} className="">
                    {images.map((image, imgIndex) => (
                      <div
                        className="thumbnail-img thumbnail-banner-slide-img"
                        key={imgIndex}
                        onClick={() => this.openLightboxForIndex(index)}
                      >
                        <img className="full-width" src={image} alt={`Image ${imgIndex}`} />
                        <div
                            className="header-copyright top-right"
                            dangerouslySetInnerHTML={{ __html: copyrightTexts[index][imgIndex] }}
                          />
                      </div>
                    ))}
                  </Slider>
                </div>
              {index === 0 && (
                <div className="content">
                    <p className="number violet weight-medium">1</p>
                    <h3>Nest Kitchen & Bar, <br/><span className="violet">Queenstown</span></h3>
                    <p>Savour local ingredients at Queenstown's highest alfresco eatery</p>
                    <p className="small-text light-italic location">139 Fernhill Road, Fernhill, <br/>Queenstown 9300, New Zealand</p>
                </div>  
              )}
              {index === 1 && (
                <div className="content">
                    <p className="number violet weight-medium">2</p>
                    <h3>Kika, <br/><span className="violet">Wanaka</span></h3>
                    <p>Modern twist on iconic Kiwi delights</p>
                    <p className="small-text light-italic location">2 Dunmore Street, Wānaka 9305, <br/>New Zealand</p>
                </div>  
              )}
              {index === 2 && (
                <div className="content">
                    <p className="number violet weight-medium">3</p>
                    <h3>Knoll Ridge Chalet, <br/><span className="violet">Ruapehu</span></h3>
                    <p>Dine in the skies at New Zealand's highest restaurant </p>
                    <p className="small-text light-italic location">Whakapapa Ski Area, Top of Bruce Road, Mt Ruapehu, Tongariro National Park, New Zealand 3951</p>
                </div>  
              )}
              {index === 3 && (
                <div className="content">
                    <p className="number violet weight-medium">4</p>
                    <h3>Osteria Ohakune, <br/><span className="violet">Ruapehu</span></h3>
                    <p>Loving recipes that transcend generations</p>
                    <p className="small-text light-italic location">2 Dunmore Street, Wānaka 9305, <br/>New Zealand</p>
                </div>  
              )}
                {/* Lightbox for mobile */}
                {isOpen && isMobile && openLightbox[index] && (
                  <Lightbox
                    mainSrc={lightboxImages[index][photoIndex]}
                    nextSrc={lightboxImages[index][(photoIndex + 1) % lightboxImages[index].length]}
                    prevSrc={lightboxImages[index][(photoIndex + lightboxImages[index].length - 1) % lightboxImages[index].length]}
                    onCloseRequest={() => this.setState({ isOpen: false, openLightbox: Array(4).fill(false), activeIndex: null })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + lightboxImages[index].length - 1) % lightboxImages[index].length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % lightboxImages[index].length,
                      })
                    }
                  />
                )}
              </div>
            ))}
          </Slider>
        </div>
      );
    }
  }