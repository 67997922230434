import React, { Component } from "react";
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import aroha1 from '../img/4-Chill Out and About/3-Lodging/Aro Ha Wellness Retreat/Aro Ha Wellness Retreat 1.png';
import aroha2 from '../img/4-Chill Out and About/3-Lodging/Aro Ha Wellness Retreat/Aro Ha Wellness Retreat 2.png';
import laketaupo1 from '../img/4-Chill Out and About/3-Lodging/Lake Taupo Holiday Resort/Lake Taupo Holiday Resort 1.png';
import laketaupo2 from '../img/4-Chill Out and About/3-Lodging/Lake Taupo Holiday Resort/Lake Taupo Holiday Resort 2.png';
import malborough1 from '../img/4-Chill Out and About/3-Lodging/The Marlborough/The Marlborough 1.png';
import malborough2 from '../img/4-Chill Out and About/3-Lodging/The Marlborough/The Marlborough 2.png';
import treetops1 from '../img/4-Chill Out and About/3-Lodging/Treetops Lodge _ Estate/Treetops Lodge _ Estate 1.png';
import treetops2 from '../img/4-Chill Out and About/3-Lodging/Treetops Lodge _ Estate/Treetops Lodge _ Estate 2.png';
import whakarewarewa1 from '../img/4-Chill Out and About/3-Lodging/Whakarewarewa/Whakarewarewa 1.png';
import whakarewarewa2 from '../img/4-Chill Out and About/3-Lodging/Whakarewarewa/Whakarewarewa 2.png';
import NextArrow from '../img/slider-right.png';
import PrevArrow from '../img/slider-left.png';
import NextSliderArrow from '../img/Arrow Right.png';
import PrevSliderArrow from '../img/Arrow Left.png';

const SampleNextArrow = ({ onClick }) => (
  <div className="custom-arrow next" onClick={(e) => { onClick(e); handleClick('NextArrow'); }}>
    <img src={NextArrow} alt="Next" />
  </div>
);

const SamplePrevArrow = ({ onClick }) => (
  <div className="custom-arrow prev" onClick={(e) => { onClick(e); handleClick('PrevArrow'); }}>
    <img src={PrevArrow} alt="Previous" />
  </div>
);
  const SliderPrevArrow = ({ onClick }) => (
    <div className="custom-arrow next slick-arrow slick-next" onClick={onClick}>
      <img src={PrevSliderArrow} alt="Previous" />
    </div>
  );
  
  const SliderNextArrow = ({ onClick }) => (
    <div className="custom-arrow prev slick-arrow slick-prev" onClick={onClick}>
      <img src={NextSliderArrow} alt="Next" />
    </div>
  );

  const handleClick = (section) => {
    const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

    if (isMobile) {
      // Console logs for debugging
      //console.log(`Hover detected on section: ${section}`);
    
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'trackEventNoEcommerce',
        eventDetails: {
          category: 'photoscarousel',
          action: 'arrowclick',
          label: 'winterflydeas',
          value: 'lodging',
        },
      });
    
      // Console log after pushing to dataLayer
      //console.log('DataLayer after push:', window.dataLayer);
    }
  };

  const copyrightTexts = [
    [
      '<span class="white">© Whakarewarewa</span>',
      '<span class="white">© Graeme Murray</span>',
    ],
    [
      '<span class="white">© Treetops Lodge & Estate</span>',
      '<span class="white">© Treetops Lodge & Estate</span>',
    ],
    [
      '<span class="white">© The Marlborough</span>',
      '<span class="white">© The Marlborough</span>',
    ],
    [
      '<span>© Love Taupo</span>',
      '<span>© Lake Taupo Holiday Resort</span>',
    ],
    [
      '<span class="white">© Aro Hã Wellness Retreat</span>',
      '<span>© Aro Hã Wellness Retreat</span>',
    ],
  ];

  export default class SimpleSlider extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isOpen: false,
        lightboxImages: [
          [whakarewarewa1,whakarewarewa2],
          [treetops1,treetops2],
          [malborough1,malborough2],
          [laketaupo1,laketaupo2],
          [aroha1,aroha2],
        ],
        lightboxPhotoIndexes: Array(4).fill(0).map(() => 0), // Individual indexes for each banner
        openLightbox: Array(4).fill(false), // Array to track open lightbox for each slider
        isMobile: window.innerWidth <= 980, // Initial check for mobile screen size
        activeIndex: null, // To track the active slider
      };
    }
  
    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
    }
  
    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }
  
    handleResize = () => {
      this.setState({ isMobile: window.innerWidth <= 980 });
    };
  
    openLightboxForIndex = (index) => {
      this.setState((prevState) => ({
        isOpen: prevState.isMobile,
        openLightbox: prevState.openLightbox.map((item, idx) => (idx === index ? true : item)),
        activeIndex: index,
        photoIndex: 0, // Set photoIndex to 0 when opening the lightbox for a particular index
      }));
    };
  
  
    render() {
      const {
        isOpen,
        lightboxImages,
        lightboxPhotoIndexes,
        isMobile,
        photoIndex,
        openLightbox,
        activeIndex,
      } = this.state;
  
      const settings = {
          dots: false,
          infinite: true,
          slidesToShow: 4.99,
          slidesToScroll: 1,
          arrows: true,
          nextArrow: <SliderNextArrow />,
          prevArrow: <SliderPrevArrow />,
          draggable: false, // Disable mouse dragging
          swipe: false,
          responsive: [
            {
              breakpoint: 1366,
              settings: {
                slidesToShow: 3.99,
              }
            },
            {
              breakpoint: 980,
              settings: {
                slidesToShow: 2.99,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1.99,
              }
            },
          ],
      };
  
      const settings2 = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        };
        return (
          <div className="lodging-carousel">
            <Slider {...settings}>
              {lightboxImages.map((images, index) => (
                <div key={index}>
                  <div className="thumbnail-banner">
                    <Slider {...settings2} className="">
                      {images.map((image, imgIndex) => (
                        <div
                        className="thumbnail-img thumbnail-banner-slide-img"
                        key={imgIndex}
                        onClick={() => this.openLightboxForIndex(index)}
                      >
                        <img className="full-width" src={image} alt={`Image ${imgIndex}`} />
                        <div
                            className="header-copyright top-right"
                            dangerouslySetInnerHTML={{ __html: copyrightTexts[index][imgIndex] }}
                          />
                      </div>
                      ))}
                    </Slider>
                  </div>
                {index === 1 && (
                  <div className="content">
                      <p className="number violet weight-medium">1</p>
                      <h3>Treetops Lodge & <br/>Estate, <span className="violet">Roturua</span></h3>
                      <p>All inclusive resort with dining and experiences with the family</p>
                      <p className="small-text light-italic location">351 Kearoa Road, RD1, Horohoro, <br/>Rotorua, New Zealand</p>
                  </div>  
                )}
                {index === 2 && (
                  <div className="content">
                      <p className="number violet weight-medium">2</p>
                      <h3>The Marlborough, <br/><span className="violet">Marlborough</span></h3>
                      <p>Stay in luxury, right in the heart of New Zealand's famous wine region</p>
                      <p className="small-text light-italic location">776 Rapaura Road, Rapaura 7273,<br/>Marlborough, New Zealand</p>
                  </div>  
                )}
                {index === 3 && (
                  <div className="content">
                      <p className="number violet weight-medium">3</p>
                      <h3>Lake Taupo Holiday <br/>Resort, <span className="violet">Taupo</span></h3>
                      <p>Your best for rest and rejuvenation with thermal pools, family activities, and more</p>
                      <p className="small-text light-italic location">10 Hoopers Inlet Road, RD2 <br/>Dunedin 9077, New Zealand</p>
                  </div>  
                )}
                {index === 4 && (
                  <div className="content">
                      <p className="number violet weight-medium">4</p>
                      <h3>Aro Hā Wellness <br/>Retreat, <span className="violet">Glenorchy</span></h3>
                      <p>Dedicate your body and soul to a luxurious retreat with everything taken care of</p>
                      <p className="small-text light-italic location">33 Station Valley Rd, Wyuna Rise, <br/>Glenorchy 9372, New Zealand</p>
                  </div>  
                )}
                {index === 0 && (
                  <div className="content">
                      <p className="number violet weight-medium">5</p>
                      <h3>Whakarewarewa, <br/><span className="violet">Roturua</span></h3>
                      <p>Experience culture and tradition with an overnight stay in a living Mãori village</p>
                      <p className="small-text light-italic location">17 Tryon Street, Whakarewarewa, Rotorua 3010, New Zealand</p>
                  </div>  
                )}
                  {/* Lightbox for mobile */}
                  {isOpen && isMobile && openLightbox[index] && (
                    <Lightbox
                      mainSrc={lightboxImages[index][photoIndex]}
                      nextSrc={lightboxImages[index][(photoIndex + 1) % lightboxImages[index].length]}
                      prevSrc={lightboxImages[index][(photoIndex + lightboxImages[index].length - 1) % lightboxImages[index].length]}
                      onCloseRequest={() => this.setState({ isOpen: false, openLightbox: Array(4).fill(false), activeIndex: null })}
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + lightboxImages[index].length - 1) % lightboxImages[index].length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + 1) % lightboxImages[index].length,
                        })
                      }
                    />
                  )}
                </div>
              ))}
            </Slider>
          </div>
        );
      }
    }