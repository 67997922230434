import React, { useEffect, useState, useRef } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import Slider from "react-slick";
import scrolldown from '../img/Arrow Down.png';
import banner from '../img/5-Vroom with a View/Hero Banner_Vroom with a View.png';
import bannerMobile from '../img/Mobile/5-Vroom with a View/Hero Banner_Vroom with a View.png';
import crossisland1 from '../img/5-Vroom with a View/1-Activities/Cross-island Wine Trail/Cross-island Wine Trail 1.png';
import crossisland2 from '../img/5-Vroom with a View/1-Activities/Cross-island Wine Trail/Cross-island Wine Trail 2.png';
import crossisland1Mobile from '../img/Mobile/5-Vroom with a View/1-Activities/Cross-island Wine Trail/Cross-island Wine Trail 1.png';
import crossisland2Mobile from '../img/Mobile/5-Vroom with a View/1-Activities/Cross-island Wine Trail/Cross-island Wine Trail 2.png';
import forgotten1 from '../img/5-Vroom with a View/1-Activities/Forgotten World Highway (State Highway 43)/Forgotten World Highway (State Highway 43) 1.png';
import forgotten2 from '../img/5-Vroom with a View/1-Activities/Forgotten World Highway (State Highway 43)/Forgotten World Highway (State Highway 43) 2.png';
import forgotten1Mobile from '../img/Mobile/5-Vroom with a View/1-Activities/Forgotten World Highway (State Highway 43)/Forgotten World Highway (State Highway 43) 1.png';
import forgotten2Mobile from '../img/Mobile/5-Vroom with a View/1-Activities/Forgotten World Highway (State Highway 43)/Forgotten World Highway (State Highway 43) 2.png';
import freeneasy1 from '../img/5-Vroom with a View/1-Activities/Free _ Easy Cross-island Campervan/Free _ Easy Cross-island Campervan 1.png';
import freeneasy2 from '../img/5-Vroom with a View/1-Activities/Free _ Easy Cross-island Campervan/Free _ Easy Cross-island Campervan 2.png';
import freeneasy1Mobile from '../img/Mobile/5-Vroom with a View/1-Activities/Free _ Easy Cross-island Campervan/Free _ Easy Cross-island Campervan 1.png';
import freeneasy2Mobile from '../img/Mobile/5-Vroom with a View/1-Activities/Free _ Easy Cross-island Campervan/Free _ Easy Cross-island Campervan 2.png';
import snowhighway1 from '../img/5-Vroom with a View/1-Activities/Snow Highway/Snow Highway.png';
import snowhighway1Mobile from '../img/Mobile/5-Vroom with a View/1-Activities/Snow Highway/Snow Highway-1.png';
import volcanic1 from '../img/5-Vroom with a View/1-Activities/The Volcanic Loop/The Volcanic Loop 1.png';
import volcanic2 from '../img/5-Vroom with a View/1-Activities/The Volcanic Loop/The Volcanic Loop 2.png';
import volcanic1Mobile from '../img/Mobile/5-Vroom with a View/1-Activities/The Volcanic Loop/The Volcanic Loop 1.png';
import volcanic2Mobile from '../img/Mobile/5-Vroom with a View/1-Activities/The Volcanic Loop/The Volcanic Loop 2.png';
import southern1 from '../img/5-Vroom with a View/1-Activities/Southern Scenic Route/Southern Scenic Route.png';
import southern1Mobile from '../img/Mobile/5-Vroom with a View/1-Activities/Southern Scenic Route/Southern Scenic Route 1.png';
import Dining from '../components/VroomDining';
import ImageWithHotspots from '../components/ImageWithHotspots-Vroom';

import LodgingCarousel from '../components/VroomLodging';
import Itinerary from '../components/VroomItinerary';
import NextArrow from '../img/slider-right.png';
import PrevArrow from '../img/slider-left.png'
import mountain from '../img/6-Across All Pages/Footer Image_Base Mountain.png';
import booknow from '../img/Book Now Button.png';
import { useStickyBox } from 'react-sticky-box';


const Vroom = () => {
    const [activeLink, setActiveLink] = useState('');
    const [parentWidths, setParentWidths] = useState({
        child1: 0,
        child2: 0,
        child3: 0,
        child4: 0,
    });

    const childRefs = {
        childRef1: useRef(null),
        childRef2: useRef(null),
        childRef3: useRef(null),
        childRef4: useRef(null),
    };
    const location = useLocation();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const newsletter = useRef(null);

    const trackClickEvent = (section) => {
        const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

        if (isMobile) {
            // Console logs for debugging
            //console.log(`Click detected on section: ${section}`);

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'trackEvent',
                eventDetails: {
                    category: 'StickyMenu',
                    action: section,
                },
            });

            // Console log after pushing to dataLayer
            console.log('DataLayer after push:', window.dataLayer);
        }
    };

    const handleClick = (ref, sectionName) => (event) => {
        event.preventDefault();

        // Call trackClickEvent with the sectionName parameter
        trackClickEvent(sectionName);

        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });

            // Set a timeout to delay setting the active state
            setTimeout(() => {
                setActiveLink(sectionName);
            }, 500); // Delay in milliseconds (adjust as needed)
        }
    };

    const getParentWidth = (ref) => {
        if (ref.current) {
            const parentStyles = window.getComputedStyle(ref.current.parentElement);
            const widthWithoutPadding =
                ref.current.parentElement.offsetWidth -
                parseFloat(parentStyles.paddingLeft) -
                parseFloat(parentStyles.paddingRight);
            return widthWithoutPadding;
        }
        return 0;
    };

    useEffect(() => {
        const getSectionHeightByClass = (className) => {
            const sections = document.querySelectorAll(className);
            if (!sections || sections.length === 0) return 0;

            // Assuming all sections have the same height
            const section = sections[0];
            const sectionStyles = window.getComputedStyle(section);
            return parseFloat(sectionStyles.height);
        };

        let initialPositions = {};
        let desktopInitialPositions = {};
        let bottomPositions = {};
        let newBottomHeight = {};
        let heights = {};
        let stickyLeftMenuHeight = {};

        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            for (const section in initialPositions) {

                const topPosition = initialPositions[section];
                const bottomPosition = bottomPositions[section];

                const isMobile = window.innerWidth <= 980;
                if (isMobile) {
                    if (scrollTop >= (topPosition - 200) && scrollTop <= bottomPosition) {
                        setActiveLink(section);
                    }
                } else {
                    if (scrollTop >= (topPosition - 500) && scrollTop <= (bottomPosition)) {
                        setActiveLink(section);
                        //console.log('Current Section:', section);
                    }
                }
            }
        };

        const handleResize = () => {
            setTimeout(() => {
                const width1 = getParentWidth(childRefs.childRef1);
                const width2 = getParentWidth(childRefs.childRef2);
                const width3 = getParentWidth(childRefs.childRef3);
                const width4 = getParentWidth(childRefs.childRef4);

                setParentWidths({
                    child1: width1,
                    child2: width2,
                    child3: width3,
                    child4: width4,
                });

                initialPositions = {
                    activities: ref1.current.getBoundingClientRect().top + window.pageYOffset,
                    food: ref2.current.getBoundingClientRect().top + window.pageYOffset,
                    lodging: ref3.current.getBoundingClientRect().top + window.pageYOffset,
                    itinerary: ref4.current.getBoundingClientRect().top + window.pageYOffset,
                };
                desktopInitialPositions = {
                    activities: initialPositions.activities + 100,
                    food: initialPositions.food - 5,
                    lodging: initialPositions.lodging - 5,
                    itinerary: initialPositions.itinerary - 20,
                };

                heights = {
                    activities: getSectionHeightByClass('.activities'),
                    food: getSectionHeightByClass('.food'),
                    lodging: getSectionHeightByClass('.lodging'),
                    itinerary: getSectionHeightByClass('.itinerary'),
                };

                stickyLeftMenuHeight = {
                    activities: getSectionHeightByClass('.activities .sticky-left-menu'),
                    food: getSectionHeightByClass('.food .sticky-left-menu'),
                    lodging: getSectionHeightByClass('.lodging .sticky-left-menu'),
                    itinerary: getSectionHeightByClass('.itinerary .sticky-left-menu'),
                }

                // Calculate bottom positions
                bottomPositions = {
                    activities: initialPositions.activities + heights.activities,
                    food: initialPositions.food + heights.food,
                    lodging: initialPositions.lodging + heights.lodging,
                    itinerary: initialPositions.itinerary + heights.itinerary,
                };
                newBottomHeight = {
                    activities: bottomPositions.activities - stickyLeftMenuHeight.activities + 80,
                    food: bottomPositions.food - stickyLeftMenuHeight.food - 120,
                    lodging: bottomPositions.lodging - stickyLeftMenuHeight.lodging,
                    itinerary: bottomPositions.itinerary - stickyLeftMenuHeight.itinerary - 165,
                };

                handleScroll();
            }, 1000);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        // Initial call to handleResize
        handleResize();
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    useEffect(() => {
        window.onload = () => {
            if (location.hash === "#itinerary") {
                setTimeout(() => {
                    ref4.current.scrollIntoView({ behavior: "smooth" });
                }, 500)
            }
        };
    }, [location]);

    const sliderHandleClick = (section) => {
        const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

        if (isMobile) {
            // Console logs for debugging
            //console.log(`Hover detected on section: ${section}`);

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'trackEventNoEcommerce',
                eventDetails: {
                    category: 'photoscarousel',
                    action: 'arrowclick',
                    label: 'winterflydeas',
                    value: 'activities',
                },
            });

            // Console log after pushing to dataLayer
            //console.log('DataLayer after push:', window.dataLayer);
        }
    };

    const SampleNextArrow = ({ onClick }) => {
        return (
            <div className="custom-arrow next" onClick={(e) => { onClick(e); sliderHandleClick('NextArrow'); }}>
                <img src={NextArrow} alt="Next" />
            </div>
        );
    };

    const SamplePrevArrow = ({ onClick }) => {
        return (
            <div className="custom-arrow prev" onClick={(e) => { onClick(e); sliderHandleClick('PrevArrow'); }}>
                <img src={PrevArrow} alt="Previous" />
            </div>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const activitiesStickyRef = useStickyBox(
        {
            offsetTop: 50,
            offsetBottom: 20
        }
    );
    const foodStickyRef = useStickyBox(
        {
            offsetTop: 50,
            offsetBottom: 20
        }
    );
    const lodgingStickyRef = useStickyBox(
        {
            offsetTop: 50,
            offsetBottom: 20
        }
    );
    const itineraryStickyRef = useStickyBox(
        {
            offsetTop: 50,
            offsetBottom: 20
        }
    );

    return (
        <div className="subpage-body vroom-page">
            <div id="top" className="subPage-banner" ref={ref5}>
                <div className="banner-img desktop"><img alt="" src={banner} /></div>
                <div className="banner-img mobile"><img alt="" src={bannerMobile} /></div>
                <div className="banner-header white">
                    <h1>Vroom with a View</h1>
                    <p className="sub-header">Get on the road for an epic self-drive with views to match</p>
                </div>
            </div>
            <div className="sticky-menu z-index-1" >
                <ul className="">
                    <li className={activeLink === 'activities' ? 'active' : ''}>
                        <a href="#activities" onClick={handleClick(ref1, 'activities')}>
                            Activities
                        </a>
                    </li>
                    <li className={activeLink === 'food' ? 'active' : ''}>
                        <a href="#food" onClick={handleClick(ref2, 'food')}>
                            Food
                        </a>
                    </li>
                    <li className={activeLink === 'lodging' ? 'active' : ''}>
                        <a href="#lodging" onClick={handleClick(ref3, 'lodging')}>
                            Lodging
                        </a>
                    </li>
                    <li className={activeLink === 'itinerary' ? 'active' : ''} >
                        <a className="mobile" href="#itinerary" onClick={handleClick(ref4, 'itinerary')}>
                            Flexi-tinerary
                        </a>
                        <a className="desktop" href="#itinerary" onClick={handleClick(ref4, 'itinerary')}>
                            Flexi-tinerary: Aiken Chia
                        </a>
                    </li>
                </ul>
            </div>
            <div className="activities subpage-section">
                <div className="anchor" id="activities" ref={ref1}></div>
                <div ref={activitiesStickyRef} className="subpage-left parent">
                    <div className="sticky-left-menu child" ref={childRefs.childRef1} style={{ width: `${parentWidths.child1}px` }}>
                        <h2 className="violet">Activities</h2>
                        <h3>Hit the open road for a winter <br />self-drive with views that'll rev <br />up your excitement</h3>
                        <p>The best way to see New Zealand is on their 94,000km network of roads. Whether you're renting a car or going all out with a self-contained campervan, you'll enjoy stunning views of snowy mountain ranges, crystal-clear waters and picturesque towns at every turn – just don't forget to rent some snow chains if you're planning to drive up to any of the snow resorts.</p>
                        <p>Get inspired by the many themed highways and heritage routes, filled with little towns worthy of a detour. Plus, a jam-packed itinerary is the only jam you'll encounter on the roads during the winter season!</p>
                    </div>
                </div>
                <div className="subpage-right">
                    <div className="w-100">
                        <div className="thumbnail-banner">
                            <Slider className="desktop" {...settings}>
                                <div><img alt="" className="full-width" src={freeneasy1} /></div>
                                <div><img alt="" className="full-width" src={freeneasy2} /></div>
                            </Slider>
                            <Slider className="mobile" {...settings}>
                                <div><img alt="" className="full-width" src={freeneasy1Mobile} /></div>
                                <div><img alt="" className="full-width" src={freeneasy2Mobile} /></div>
                            </Slider>
                        </div>
                    </div>
                    <div className="two-col freeneasy">
                        <div className="w-5 col-left">
                            <div className="content py-0 left">
                                <h3>Free & Easy Cross-island Campervan</h3>
                                <p>Nothing is more “Vroom with a View” than a literal room that moves with you! Rent a campervan that fits your needs when you arrive, and embark on a mix-and-match road trip adventure from the suggestions above (or elsewhere).</p>
                                <p>Spend anywhere from 1-2 weeks to cover either North or South Islands, or up to 5 weeks (or more) to cover both islands, while enjoying the flexibility to set up camp in a wide range of campsites, holiday parks, and even most public land – New Zealand has over 500 “freedom camping” locations that allow you to legally park and stay for the night in your self-contained campervan for free.</p>
                            </div>
                        </div>
                        <div className="w-5 col-right">
                            <div className="content py-0">
                                <div className="tip-box seat">
                                    <p>
                                        <span className="weight-medium">Fly Savvy with Air New Zealand:</span><br />
                                        Catch up on rest before the long  drive by upgrading to the best economy seat on board with  Economy Skycouch<sup>TM</sup>. Or go OneUp<sup>TM</sup> and request for a cabin class upgrade to take your inflight comfort, food and entertainment to the next level.
                                    </p>
                                    <button className="learn-more"><a target="_blank" rel="noopener noreferrer" href="https://www.airnewzealand.com.sg/oneup">LEARN MORE</a></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="two-col">
                        <div className="w-5">
                            <div className="thumbnail-banner">
                                <Slider className="desktop" {...settings}>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={forgotten1} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right white">© Forgotten World Adventures</div>
                                        </div>
                                    </div>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={forgotten2} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right white">© Max Stussi</div>
                                        </div>
                                    </div>
                                </Slider>
                                <Slider className="mobile"{...settings}>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={forgotten1Mobile} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right white">© Forgotten World Adventures</div>
                                        </div>
                                    </div>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={forgotten2Mobile} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right white">© Max Stussi</div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                        <div className="w-5">
                            <div className="content right">
                                <h3>Forgotten World Highway <br />(State Highway 43)</h3>
                                <p>Drive along New Zealand's oldest heritage trail that's equal parts scenic and mysterious. Built on 19th-century bridle paths, this 3-hour stretch takes you from Taumarunui to Stratford alongside railways through historic sights, natural wonders, and forgotten towns.</p>
                                <p>Swap your car for a rail cart at Forgotten World Adventures, test your driving precision through the harrowingly-narrow Moki Tunnel (a.k.a. Hobbit's Hole), and stop by Whangamōmona for your own passport souvenir at this fascinating “republic”. Shakespeare fans will love the final stop named after the playwright's hometown, where you can park your ride along one of the many streets named after his characters.</p>
                                <p className="small-text light-italic location">State Highway 43, North Island</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-100">
                        <div className="thumbnail-banner mt-20">
                            <Slider className="desktop" {...settings}>
                                <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={crossisland1} />
                                    <div className="header-copyright top-right">
                                        <div className="header-copyright-right">© Gary Lisbon</div>
                                    </div>
                                </div>
                                <div><img alt="" className="full-width" src={crossisland2} /></div>
                            </Slider>
                            <Slider className="mobile" {...settings}>
                                <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={crossisland1Mobile} />
                                    <div className="header-copyright top-right">
                                        <div className="header-copyright-right">© Gary Lisbon</div>
                                    </div>
                                </div>
                                <div><img alt="" className="full-width" src={crossisland2Mobile} /></div>
                            </Slider>
                        </div>
                        <div className="content">
                            <div className="d-flex align-items-center">
                                <h3>Cross-island Wine Trail</h3>
                            </div>
                            <p>Enjoy a relaxing exploration through three significant wine regions across the Cook Straits. You can visit vineyards and cellars at a more leisurely pace during the off-peak season, admire verdant forests, and indulge in glorious gastronomic delights. For all the wine connoisseurs out there, this 3-hour route is best experienced as a 4-6 day (or longer) journey, allowing you to savour at a safe and gradual pace with enough breaks before your next drive.</p>
                            <p>Starting in North Island, make your way south from Hawke's Bay towards Martinborough, as you stop along the many vineyards, such as wineries in the Wairarapa region at the heart of this northern leg. Then, drive onto a ferry at Wellington that takes you through the picturesque Marlborough Sounds in the South Island. Continue your journey back on land to discover Picton and Blenheim, with their Winter Fireplace Trail for warm welcomes and intimate tours thanks to the smaller off-season crowds.</p>
                            <p className="small-text light-italic location">State Highway 2 & 1S, North & South Islands</p>
                        </div>
                    </div>
                    <div className="two-col">
                        <div className="w-4 content left pt-0">
                            <div className="d-flex align-items-center">
                                <h3>The Volcanic Loop</h3>
                            </div>
                            <p>Discover a dynamic range of landscapes from snow-capped active volcanoes and winding desert roads, to relaxing thermal pools and rolling hills from a movie set. Set off on a winter adventure from Auckland towards not Middle-earth, but middle North Island, as you circle around Lake Taupo and the Tongariro National Park at its heart.</p>
                            <p>The crisp, clear winter skies greets you with spectacular views of diverse terrains, from the verdant Hobbiton movie set, bubbling mud baths of Rotorua and serene Lake Taupo, to the rugged Rangipo Desert road and dramatic Tongariro National Park, as steam vents from active volcanoes melt away fresh snow. Leave your 4 wheels behind and explore the famous Tongariro Alpine Crossing on foot, touted as New Zealand's greatest day walk, for a challenging but equally rewarding adventure, before returning to your vehicle to continue along your journey.</p>
                            <p className="small-text light-italic location">Tongariro National Park, North Islands</p>
                        </div>
                        <div className="w-6 image right">
                            <div className="thumbnail-banner">
                                <Slider className="desktop" {...settings}>
                                    <div><img alt="" className="full-width" src={volcanic1} /></div>
                                    <div><img alt="" className="full-width" src={volcanic2} /></div>
                                </Slider>
                                <Slider className="mobile" {...settings}>
                                    <div><img alt="" className="full-width" src={volcanic1Mobile} /></div>
                                    <div><img alt="" className="full-width" src={volcanic2Mobile} /></div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className="w-100">
                        <div className="thumbnail-banner mt-20">
                            <div className="desktop"><img alt="" className="full-width" src={southern1} /></div>
                            <div className="mobile"><img alt="" className="full-width" src={southern1Mobile} /></div>
                            <div className="travel-tip desktop">
                                <span className="weight-medium">Travel Pro Tip:</span><br />
                                You can use your current driving licence in New Zealand without needing an International Driving Permit (IDP). If it's in a different language, just bring an accurate English translation along.
                            </div>
                        </div>
                        <div className="content">
                            <div className="d-flex align-items-center">
                                <h3>Southern Scenic Route</h3>
                            </div>
                            <p>Shift gears from scenic ski slopes to captivating views of coastlines, fiords and rugged terrain on the road less travelled. From the alpine resorts in Queenstown, set off on a journey to uncover New Zealand's southernmost treasures.</p>
                            <p>Start west at Te Anau, the gateway to the fiords, before reaching Milford Sound, as you treat yourself to some of the country's most scenic road trip views. Then head south towards Invercargill, New Zealand's southernmost city filled with heritage buildings, where you can find delicious seafood such as the famous oysters in neighbouring Bluff. Before ending in Dunedin, make a detour to Nugget Point lighthouse, one of the country's oldest lighthouses. Home to some of the world's rarest penguins, it's also a meeting place for seals, sea lions and elephant seals.</p>
                            <p className="small-text light-italic location">Invercargill, South Island</p>
                        </div>
                    </div>
                    <div className="two-col">
                        <div className="w-5">
                            <div className="thumbnail-banner">
                                <Slider className="desktop" {...settings}>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={snowhighway1} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right">© Miles Hoden</div>
                                        </div>
                                    </div>
                                </Slider>
                                <Slider className="mobile" {...settings}>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={snowhighway1Mobile} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right">© Miles Hoden</div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                        <div className="w-5 content right pt-0">
                            <div className="d-flex align-items-center">
                                <h3>Snow Highway</h3>
                            </div>
                            <p>Love driving and skiing? Combine your two passions on the informal “Snow Highway”, co-designed by New Zealand's youngest Olympic Gold medalist to highlight the South Island's best ski spots, from Christchurch all the way to Queenstown and Wanaka.</p>
                            <p>This week-long journey takes you to ski resorts from award winners to hidden gems, lets you try out unique skiing experiences such as glacier skiing at Tasman Glacier, and also gives you plenty of chances to level up your skills with various slopes that cater to a wide range of experience-levels. Do take note of road conditions particularly up in the mountains and have your snow chains ready – or park your car and hop on a shuttle if you prefer.</p>
                            <p className="small-text light-italic location">Queenstown, South Island</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="scroll-down section next-stop-food">
                <p><span className="serif-bold">Next stop,</span> <span className="violet">Food</span></p>
                <a href="#lodging" onClick={handleClick(ref2)} className="scroll-down-btn"><img src={scrolldown} alt="scroll-down" /></a>
            </div>

            <div className="food purple-bg subpage-section minus-margin-top section">
                <div className="anchor" id="food" ref={ref2}></div>
                <div ref={foodStickyRef} className="subpage-left w-27 purple-bg z-index-2 parent">
                    <div className="sticky-left-menu child" ref={childRefs.childRef2} style={{ width: `${parentWidths.child2}px` }}>
                        <h2 className="violet">Food</h2>
                        <h3>Fuel up on delicious Kiwi <br />and international cuisine</h3>
                        <p>Enjoy the freedom and flexibility to try out a wider range of restaurants when you're in the driver's seat. Or stop by the many quaint towns to visit the local favourites as you make your way across the island.</p>
                    </div>
                </div>
                <div className="subpage-right w-73">
                    <Dining />
                </div>
            </div>

            <div className="scroll-down section next-stop-lodging pt-0">
                <p><span className="serif-bold">Next stop,</span> <span className="violet">Lodging</span></p>
                <a href="#lodging" onClick={handleClick(ref3)} className="scroll-down-btn"><img src={scrolldown} alt="scroll-down" /></a>
            </div>

            <div className="lodging subpage-section minus-margin-top section">
                <div className="anchor" id="lodging" ref={ref3}></div>
                <div ref={lodgingStickyRef} className="subpage-left w-27 white-bg z-index-2 parent">
                    <div className="sticky-left-menu child" ref={childRefs.childRef3} style={{ width: `${parentWidths.child3}px` }}>
                        <h2 className="violet">Lodging</h2>
                        <h3>The perfect places to park <br />yourself down for the night</h3>
                        <p>Whether it's a base for nearby excursions, a rest stop on your epic road trip journey, or a holiday park to refresh your campervan, enjoy savings on accommodation during the winter off-peak season.</p>
                    </div>
                </div>
                <div className="subpage-right w-73">
                    <LodgingCarousel />
                </div>
            </div>

            <div className="scroll-down section">
                <p><span className="serif-bold">Next stop,</span> <span className="violet">Flexi-itinerary</span></p>
                <a href="#lodging" onClick={handleClick(ref4)} className="scroll-down-btn"><img src={scrolldown} alt="scroll-down" /></a>
            </div>

            <div className="itinerary violet-bg subpage-section minus-margin-top section">
                <div className="anchor" id="itinerary" ref={ref4}></div>
                <div ref={itineraryStickyRef} className="subpage-left w-35 violet-bg z-index-2 parent">
                    <div className="sticky-left-menu child" ref={childRefs.childRef4} style={{ width: `${parentWidths.child4}px` }}>
                        <h2 className="lightpurple">Flexi-tinerary</h2>
                        <h3>Aiken Chia (@aikenchia)</h3>
                        <p>Put a foodie on the road in New Zealand, and you'll be guaranteed a food-fuelled itinerary with stunning sceneries to match.</p>
                        <p>Check out some of the highlights on Aiken's weeklong road trip from Wellington to Martinborough, and get inspired to plan your own itinerary with his flexi-tinerary as your base – just mix and match with any suggestions from above.</p>
                    </div>
                </div>
                <div className="subpage-right w-65">
                    <Itinerary />
                    <button className="download-pdf"><a onClick={handleClick(newsletter)}>Download PDF</a></button>
                </div>
            </div>

            <div className="adventure-map darkpurple-bg subpage-section minus-margin-top section z-index-4">
                <h2 className="violet">Locate <br />your next <br />adventure</h2>
                <ImageWithHotspots />
            </div>

            <div className="darkpurple-bg mountainImg z-index-4">
                <img className="full-width" src={mountain} alt="mountain" />
            </div>

            <div className="footer-CTA text-center px-5 z-index-4 white-bg">
                <h2 className="pb-4">Ready for your winter escape?</h2>
                <a href="https://flightbookings.airnewzealand.com.sg/vbook/actions/ext-search" target="_blank" rel="noopener noreferrer" id="winter-escape-cta"><img className="bookNow-btn" src={booknow} alt="booknow" /></a>
            </div>

            <div className="newsletter purple-bg section text-center z-index-4">
                <div className="anchor" id="newsletter" ref={newsletter}></div>
                <h2 className="pb-4">Download this itinerary plus get the latest flight deals, travel tips and more</h2><br />
                <iframe src="https://xd.wayin.com/display/container/dc/5a286949-e63d-46e2-a54b-6dd107541d8b" frameborder="0" scrolling="no" width="100%" height="670" id="ngxFrame5a286949-e63d-46e2-a54b-6dd107541d8b"  allowTransparency="true"></iframe>
            </div>

            <div className="services-page sub-page purple-bg z-index-4">
                <div className="d-flex flex-wrap">
                    <div className="explore-more text-center">
                        <p className="pb-5 violet">Explore more winter holidays below</p>
                        <div className="scroll-down text-center absolute full-width pt-5">
                            <img src={scrolldown} alt="scroll-down" />
                        </div>
                    </div>
                    <div className='services-pagination-container'>
                        <div className="col-4 services-pagination-col peak d-flex flex-wrap align-items-center justify-content-center">
                            <NavHashLink to='/peak-thrills'><h2>Peak <br />Thrills</h2></NavHashLink>
                        </div>
                        <div className="col-4 services-pagination-col wonders d-flex flex-wrap align-items-center justify-content-center">
                            <NavHashLink to='/wonders-in-the-wild'><h2>Wonders in <br />the Wild</h2></NavHashLink>
                        </div>
                        <div className="col-4 services-pagination-col chillOut d-flex flex-wrap align-items-center justify-content-center">
                            <NavHashLink to='/chill-out-and-about'><h2>Chill Out <br />and About</h2></NavHashLink>
                        </div>
                    </div>
                    <div className="scroll-down text-center absolute full-width">
                        <p className="white">Back to the top</p>
                        <a href="#top"><img src={scrolldown} alt="scroll-down" /></a>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Vroom;