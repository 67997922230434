import React, { useEffect, useState, useRef } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import Slider from "react-slick";
import scrolldown from '../img/Arrow Down.png';
import banner from '../img/3-Wonders in the Wild/Hero Banner_Wonders in the Wild.png';
import bannerMobile from '../img/Mobile/3-Wonders in the Wild/Hero Banner_Wonders in the Wild.png';
import hapuku from '../img/3-Wonders in the Wild/3-Lodging/Hapuku Lodge Tree Houses - Banner image.png';
import hapukuMobile from '../img/Mobile/3-Wonders in the Wild/3-Lodging/Hapuku Lodge Tree Houses - Banner image.png';
import milford1 from '../img/3-Wonders in the Wild/1-Activities/Milford Sound Cruise/Milford Sound Cruise 1.png';
import milford2 from '../img/3-Wonders in the Wild/1-Activities/Milford Sound Cruise/Milford Sound Cruise 2.png';
import milford1Mobile from '../img/Mobile/3-Wonders in the Wild/1-Activities/Milford Sound Cruise/Milford Sound Cruise 1.png';
import milford2Mobile from '../img/Mobile/3-Wonders in the Wild/1-Activities/Milford Sound Cruise/Milford Sound Cruise 2.png';
import southernlights1 from '../img/3-Wonders in the Wild/1-Activities/Southern Lights/Southern Lights 1.png';
import southernlights2 from '../img/3-Wonders in the Wild/1-Activities/Southern Lights/Southern Lights 2.png';
import tranz1 from '../img/3-Wonders in the Wild/1-Activities/Tranz Alpine Train/Tranz Alpine Train 1.png';
import tranz2 from '../img/3-Wonders in the Wild/1-Activities/Tranz Alpine Train/Tranz Alpine Train 2.png';
import tranz1Mobile from '../img/Mobile/3-Wonders in the Wild/1-Activities/Tranz Alpine Train/Tranz Alpine Train 1.png';
import tranz2Mobile from '../img/Mobile/3-Wonders in the Wild/1-Activities/Tranz Alpine Train/Tranz Alpine Train 2.png';
import whale1 from '../img/3-Wonders in the Wild/1-Activities/Whale Watching/Whale Watching 1.png';
import whale2 from '../img/3-Wonders in the Wild/1-Activities/Whale Watching/Whale Watching 2.png';
import whale1Mobile from '../img/Mobile/3-Wonders in the Wild/1-Activities/Whale Watching/Whale Watching 1.png';
import whale2Mobile from '../img/Mobile/3-Wonders in the Wild/1-Activities/Whale Watching/Whale Watching 2.png';
import zealandia1 from '../img/3-Wonders in the Wild/1-Activities/Zealandia/Zealandia 1.png';
import zealandia2 from '../img/3-Wonders in the Wild/1-Activities/Zealandia/Zealandia 2.png';
import Dining from '../components/WondersDining';
import ImageWithHotspots from '../components/ImageWithHotspots-Wonders';

import LodgingCarousel from '../components/WondersLodging';
import Itinerary from '../components/WondersItinerary';
import NextArrow from '../img/slider-right.png';
import PrevArrow from '../img/slider-left.png'
import familyIcon from '../img/Family Friendly Icon.png';
import mountain from '../img/6-Across All Pages/Footer Image_Base Mountain.png';
import booknow from '../img/Book Now Button.png';
import { useStickyBox } from 'react-sticky-box';


const Wonders = () => {
    const [activeLink, setActiveLink] = useState('');
    const [parentWidths, setParentWidths] = useState({
        child1: 0,
        child2: 0,
        child3: 0,
        child4: 0,
    });

    const childRefs = {
        childRef1: useRef(null),
        childRef2: useRef(null),
        childRef3: useRef(null),
        childRef4: useRef(null),
    };
    const location = useLocation();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const newsletter = useRef(null);

    const trackClickEvent = (section) => {
        const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

        if (isMobile) {
            // Console logs for debugging
            //console.log(`Click detected on section: ${section}`);

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'trackEvent',
                eventDetails: {
                    category: 'StickyMenu',
                    action: section,
                },
            });

            // Console log after pushing to dataLayer
            //console.log('DataLayer after push:', window.dataLayer);
        }
    };

    const handleClick = (ref, sectionName) => (event) => {
        event.preventDefault();

        // Call trackClickEvent with the sectionName parameter
        trackClickEvent(sectionName);

        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });

            // Set a timeout to delay setting the active state
            setTimeout(() => {
                setActiveLink(sectionName);
            }, 500); // Delay in milliseconds (adjust as needed)
        }
    };

    const getParentWidth = (ref) => {
        if (ref.current) {
            const parentStyles = window.getComputedStyle(ref.current.parentElement);
            const widthWithoutPadding =
                ref.current.parentElement.offsetWidth -
                parseFloat(parentStyles.paddingLeft) -
                parseFloat(parentStyles.paddingRight);
            return widthWithoutPadding;
        }
        return 0;
    };

    useEffect(() => {
        const getSectionHeightByClass = (className) => {
            const sections = document.querySelectorAll(className);
            if (!sections || sections.length === 0) return 0;

            // Assuming all sections have the same height
            const section = sections[0];
            const sectionStyles = window.getComputedStyle(section);
            return parseFloat(sectionStyles.height);
        };

        let initialPositions = {};
        let desktopInitialPositions = {};
        let bottomPositions = {};
        let newBottomHeight = {};
        let heights = {};
        let stickyLeftMenuHeight = {};

        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            for (const section in initialPositions) {
                const topPosition = initialPositions[section];
                const bottomPosition = bottomPositions[section];

                const isMobile = window.innerWidth <= 980;
                if (isMobile) {
                    if (scrollTop >= (topPosition - 200) && scrollTop <= bottomPosition) {
                        setActiveLink(section);
                    }
                } else {
                    if (scrollTop >= (topPosition - 500) && scrollTop <= (bottomPosition)) {
                        setActiveLink(section);
                        //console.log('Current Section:', section);
                    }
                }
            }
        };

        const handleResize = () => {
            setTimeout(() => {
                const width1 = getParentWidth(childRefs.childRef1);
                const width2 = getParentWidth(childRefs.childRef2);
                const width3 = getParentWidth(childRefs.childRef3);
                const width4 = getParentWidth(childRefs.childRef4);

                setParentWidths({
                    child1: width1,
                    child2: width2,
                    child3: width3,
                    child4: width4,
                });

                initialPositions = {
                    activities: ref1.current.getBoundingClientRect().top + window.pageYOffset,
                    food: ref2.current.getBoundingClientRect().top + window.pageYOffset,
                    lodging: ref3.current.getBoundingClientRect().top + window.pageYOffset,
                    itinerary: ref4.current.getBoundingClientRect().top + window.pageYOffset,
                };
                desktopInitialPositions = {
                    activities: initialPositions.activities + 100,
                    food: initialPositions.food,
                    lodging: initialPositions.lodging,
                    itinerary: initialPositions.itinerary - 15,
                };

                heights = {
                    activities: getSectionHeightByClass('.activities'),
                    food: getSectionHeightByClass('.food'),
                    lodging: getSectionHeightByClass('.lodging'),
                    itinerary: getSectionHeightByClass('.itinerary'),
                };

                stickyLeftMenuHeight = {
                    activities: getSectionHeightByClass('.activities .sticky-left-menu'),
                    food: getSectionHeightByClass('.food .sticky-left-menu'),
                    lodging: getSectionHeightByClass('.lodging .sticky-left-menu'),
                    itinerary: getSectionHeightByClass('.itinerary .sticky-left-menu'),
                }

                // Calculate bottom positions
                bottomPositions = {
                    activities: initialPositions.activities + heights.activities,
                    food: initialPositions.food + heights.food,
                    lodging: initialPositions.lodging + heights.lodging,
                    itinerary: initialPositions.itinerary + heights.itinerary,
                };
                newBottomHeight = {
                    activities: bottomPositions.activities - stickyLeftMenuHeight.activities,
                    food: bottomPositions.food - stickyLeftMenuHeight.food - 85,
                    lodging: bottomPositions.lodging - stickyLeftMenuHeight.lodging + 30,
                    itinerary: bottomPositions.itinerary - stickyLeftMenuHeight.itinerary - 165,
                };

                handleScroll();
            }, 1000);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        // Initial call to handleResize
        handleResize();
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    useEffect(() => {
        window.onload = () => {
            if (location.hash === "#itinerary") {
                setTimeout(() => {
                    ref4.current.scrollIntoView({ behavior: "smooth" });
                }, 500)
            }
        };
    }, [location]);

    const sliderHandleClick = (section) => {
        const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

        if (isMobile) {
            // Console logs for debugging
            //console.log(`Hover detected on section: ${section}`);

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'trackEventNoEcommerce',
                eventDetails: {
                    category: 'photoscarousel',
                    action: 'arrowclick',
                    label: 'winterflydeas',
                    value: 'activities',
                },
            });

            // Console log after pushing to dataLayer
            //console.log('DataLayer after push:', window.dataLayer);
        }
    };


    const SampleNextArrow = ({ onClick }) => {
        return (
            <div className="custom-arrow next" onClick={(e) => { onClick(e); sliderHandleClick('NextArrow'); }}>
                <img src={NextArrow} alt="Next" />
            </div>
        );
    };

    const SamplePrevArrow = ({ onClick }) => {
        return (
            <div className="custom-arrow prev" onClick={(e) => { onClick(e); sliderHandleClick('PrevArrow'); }}>
                <img src={PrevArrow} alt="Previous" />
            </div>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };


    const activitiesStickyRef = useStickyBox(
        {
            offsetTop: 50,
            offsetBottom: 20
        }
    );
    const foodStickyRef = useStickyBox(
        {
            offsetTop: 50,
            offsetBottom: 20
        }
    );
    const lodgingStickyRef = useStickyBox(
        {
            offsetTop: 50,
            offsetBottom: 20
        }
    );
    const itineraryStickyRef = useStickyBox(
        {
            offsetTop: 50,
            offsetBottom: 20
        }
    );

    return (
        <div className="subpage-body wonders-page">
            <div id="top" className="subPage-banner" ref={ref5}>
                <div className="banner-img desktop"><img alt="" src={banner} /></div>
                <div className="banner-img mobile"><img alt="" src={bannerMobile} /></div>
                <div className="banner-header white">
                    <h1>Wonders in the Wild</h1>
                    <p className="sub-header">Cosy up with stunning landscapes and surprising outdoor experiences</p>
                </div>
            </div>
            <div className="sticky-menu z-index-1" >
                <ul className="">
                    <li className={activeLink === 'activities' ? 'active' : ''}>
                        <a href="#activities" onClick={handleClick(ref1, 'activities')}>
                            Activities
                        </a>
                    </li>
                    <li className={activeLink === 'food' ? 'active' : ''}>
                        <a href="#food" onClick={handleClick(ref2, 'food')}>
                            Food
                        </a>
                    </li>
                    <li className={activeLink === 'lodging' ? 'active' : ''}>
                        <a href="#lodging" onClick={handleClick(ref3, 'lodging')}>
                            Lodging
                        </a>
                    </li>
                    <li className={activeLink === 'itinerary' ? 'active' : ''} >
                        <a className="desktop" href="#itinerary" onClick={handleClick(ref4, 'itinerary')}>
                            Flexi-tinerary: Nicole Chen
                        </a>
                        <a className="mobile" href="#itinerary" onClick={handleClick(ref4, 'itinerary')}>
                            Flexi-tinerary
                        </a>
                    </li>
                </ul>
            </div>
            <div className="activities subpage-section">
                <div className="anchor" id="activities" ref={ref1}></div>
                <div ref={activitiesStickyRef} className="subpage-left parent">
                    <div className="sticky-left-menu child" ref={childRefs.childRef1} style={{ width: `${parentWidths.child1}px` }}>
                        <h2 className="violet">Activities</h2>
                        <h3>Head over to our winter <br />wonderland and catch <br />nature's natural wonders <br />in action.</h3>
                        <p>Our Air New Zealand crew aren't the only ones waiting to greet you. From migratory whales and nocturnal wildlife, to towering cliffs and waterfalls, a warm welcome awaits you this winter at some of Air New Zealand's top picks for unique outdoor experiences during the winter months.</p>
                    </div>
                </div>
                <div className="subpage-right">
                    <div className="w-100">
                        <div className="thumbnail-banner">
                            <Slider className="desktop" {...settings}>
                                <div><img alt="" className="full-width" src={whale1} /></div>
                                <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={whale2} />
                                    <div className="header-copyright top-right">
                                        <div className="header-copyright-right white">© Wings Over Whales</div>
                                    </div>
                                </div>
                            </Slider>
                            <Slider className="mobile" {...settings}>
                                <div><img alt="" className="full-width" src={whale1Mobile} /></div>
                                <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={whale2Mobile} />
                                    <div className="header-copyright top-right">
                                        <div className="header-copyright-right white">© Wings Over Whales</div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                        <div className="content">
                            <h3>Whale Watching, <span className="violet">Kaikoura</span></h3>
                            <div className="family-tooltip">
                                <img alt="" className="family-icon" src={familyIcon} />
                                <span className="tooltip-hover-text">Family-friendly</span>
                            </div>
                            <p>Bring your little ones to meet our resident giant sperm whales who live off the coast all year long, without having to deal with summer crowds. Plus, catch a glimpse of humpbacks, southern rights, pilot whales and other majestic creatures during peak migration season in the winter, especially between June and July.</p>
                            <p>While tours operate several times throughout the day, early morning excursions are often quieter with calmer waters, perfect for starting your day right.</p>
                            <p className="small-text light-italic location">Whaleway Station Road, Kaikōura 7340, New Zealand</p>
                        </div>
                    </div>
                    <div className="w-100 pt-50">
                        <div className="thumbnail-banner">
                            <Slider className="desktop" {...settings}>
                                <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={tranz1} />
                                    <div className="header-copyright top-right">
                                        <div className="header-copyright-right">© Great Journeys New Zealand</div>
                                    </div>
                                </div>
                                <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={tranz2} />
                                    <div className="header-copyright top-right">
                                        <div className="header-copyright-right">© Great Journeys New Zealand</div>
                                    </div>
                                </div>
                            </Slider>
                            <Slider className="mobile" {...settings}>
                                <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={tranz1Mobile} />
                                    <div className="header-copyright top-right">
                                        <div className="header-copyright-right">© Great Journeys New Zealand</div>
                                    </div>
                                </div>
                                <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={tranz2Mobile} />
                                    <div className="header-copyright top-right">
                                        <div className="header-copyright-right">© Great Journeys New Zealand</div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                        <div className="content">
                            <h3>TranzAlpine Train, <span className="violet">Southern Alps</span></h3>
                            <p>Whisk through gorgeous mountain ranges, forests and lakes dusted with snow, and enjoy short breaks to hop off, stretch your legs and take in these stunning sights. The TranzAlpine train from Christchurch to Greymouth takes you through some of the world's most striking natural landscapes, from the warm and cosy comfort of its premium carriages. </p>
                            <p>Departing early morning from Christchurch, it's a 5-hour journey complete with audio commentary, detailing the rich heritage passing through. Spend the night at Greymouth, or just an hour exploring the town before hopping on the train back to Christchurch.</p>
                            <p className="small-text light-italic location">Troup Drive, Addington, Christchurch 8011, New Zealand</p>
                        </div>
                    </div>
                    <div className="two-col milford">
                        <div className="w-55 content left">
                            <h3>Milford Sound Cruise, <span className="violet">Fiordland</span></h3>
                            <div className="family-tooltip">
                                <img alt="" className="family-icon" src={familyIcon} />
                                <span className="tooltip-hover-text">Family-friendly</span>
                            </div>
                            <p>Hop on a scenic cruise along one of New Zealand's most spectacular fiords with epic snow-capped mountains and one of the tallest waterfalls in the world. Although famous for being one of the wettest places in New Zealand (up to 200 rainy days a year), rainfall tends to occur outside the winter season, giving you higher chances of enjoying clear skies and unobstructed views of these majestic marvels.</p>
                            <p>While tours and cruises operate on reduced timetables, you'll get to enjoy the serenity of the fiords without the crowds. Go there earlier in the day to maximise the shorter daylight hours during winter.</p>
                            <p className="small-text light-italic location">31 Milford Sound Highway, Milford Sound <br />9679, New Zealand</p>
                            <div className="travel-tip mobile">
                                <span className="weight-medium">Travel Pro Tip:</span><br />
                                Look out for winter schedules when planning excursions on tours and cruises, which may run on reduced schedules based on crowds.
                            </div>
                        </div>
                        <div className="w-45 image right">
                            <div className="thumbnail-banner">
                                <Slider className="desktop" {...settings}>
                                    <div><img alt="" className="full-width" src={milford1} /></div>
                                    <div><img alt="" className="full-width" src={milford2} /></div>
                                </Slider>
                                <Slider className="mobile" {...settings}>
                                    <div><img alt="" className="full-width" src={milford1Mobile} /></div>
                                    <div><img alt="" className="full-width" src={milford2Mobile} /></div>
                                </Slider>
                                <div className="travel-tip desktop">
                                    <span className="weight-medium">Travel Pro Tip:</span><br />
                                    Look out for winter schedules when planning excursions on tours and cruises, which may run on reduced schedules based on crowds.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="two-col">
                        <div className="w-6">
                            <div className="thumbnail-banner">
                                <Slider {...settings}>
                                    <div><img alt="" className="full-width" src={southernlights1} /></div>
                                    <div><img alt="" className="full-width" src={southernlights2} /></div>
                                </Slider>
                            </div>
                        </div>
                        <div className="w-4 content desktop">
                            <div className="tip-box plane">
                                <p>
                                    <span className="weight-medium">Fly Savvy with Air New Zealand:</span><br />
                                    While all window seats will get you scenic views as you approach New Zealand, the right side welcomes you with sunrise over downtown Auckland and a chance of spotting the snow-capped Mount Ruapehu on a clear, winter day.
                                </p>
                                <button className="learn-more"><a target="blank" rel="noreferrer" href="https://www.airnewzealand.com.sg/seat-select ">LEARN MORE</a></button>
                            </div>
                        </div>
                    </div>
                    <div className="two-col pt-0">
                        <div className="w-7">
                            <h3>Southern Lights, <span className="violet">Stewart Island</span></h3>
                            <p>Treat your senses to the glowing skies at the world's southernmost Dark Sky Sanctuary, where you can catch a glimpse of the Southern Lights during clear winter nights especially around June and July, arguably one of the best times to see them dance in the sky.</p>
                            <p>With longer hours of darkness ranging as wide as 7pm–6am, you'll get more chances to witness this event during the winter months, with auroras tending to peak when the sun is on the opposite side of the Earth, sometime around midnight.</p>
                            <p className="small-text light-italic location">Stewart Island, New Zealand</p>
                            <div className="mobile pt-50">
                                <div className="tip-box plane">
                                    <p>
                                        <span className="weight-medium">Fly Savvy with Air New Zealand:</span><br />
                                        While all window seats will get you scenic views as you approach New Zealand, the right side welcomes you with sunrise over downtown Auckland and a chance of spotting the snow-capped Mount Ruapehu on a clear, winter day.
                                    </p>
                                    <button className="learn-more"><a target="blank" rel="noreferrer" href="https://www.airnewzealand.com.sg/seat-select ">LEARN MORE</a></button>
                                </div>
                            </div>
                        </div>
                        <div className="w-3"></div>
                    </div>
                    <div className="two-col">
                        <div className="w-6">
                            <div className="thumbnail-banner">
                                <Slider {...settings}>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={zealandia1} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right white">© Brendan Doran</div>
                                        </div>
                                    </div>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={zealandia2} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right">© Rob Suisted</div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                        <div className="w-4 content pt-0">
                            <h3>Zealandia, <span className="violet">Wellington</span></h3>
                            <div className="family-tooltip">
                                <img alt="" className="family-icon" src={familyIcon} />
                                <span className="tooltip-hover-text">Family-friendly</span>
                            </div>
                            <p>Every season has its own unique experiences, and winter is no exception. Explore this sanctuary during winter to witness rare species of migratory birds flocking together, chattering and foraging away.</p>
                            <p>Plus with shorter daylight hours, the Zealandia by Night tour starts earlier, where you'll get to catch a glimpse of nocturnal creatures such as the rare little spotted kiwi emerging earlier in the evening, allowing young families to wrap up the day sooner</p>
                            <p className="small-text light-italic location">53 Waiapu Road, Karori, Wellington 6012, <br />New Zealand</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="scroll-down section pt-0">
                <p><span className="serif-bold">Next stop,</span> <span className="violet">Food</span></p>
                <a href="#lodging" onClick={handleClick(ref2)} className="scroll-down-btn"><img src={scrolldown} alt="scroll-down" /></a>
            </div>

            <div className="food purple-bg subpage-section minus-margin-top section z-index-1">
                <div className="anchor" id="food" ref={ref2}></div>
                <div ref={foodStickyRef} className="subpage-left w-27 purple-bg parent">
                    <div className="sticky-left-menu child" ref={childRefs.childRef2} style={{ width: `${parentWidths.child2}px` }}>
                        <h2 className="violet">Food</h2>
                        <h3>Warm yourself up <br />with delicious winter <br />delights, freshly <br />sourced near you.</h3>
                        <p>Nature on a plate, coming right up. Explore unique New Zealand dishes made with fresh, seasonal produce.</p>
                        <p>Unearth a wide variety of deliciously comforting flavours from the land and sea that will warm you up from the inside out.</p>
                    </div>
                </div>
                <div className="subpage-right w-73">
                    <Dining />
                </div>
            </div>

            <div className="banner thumbnail-banner z-index-2">
                <img alt="" className="full-width desktop" src={hapuku} />
                <img alt="" className="full-width mobile" src={hapukuMobile} />
                <div className="scroll-down">
                    <p><span className="serif-bold white">Next stop,</span> <span className="white">Lodging</span></p>
                    <a href="#lodging" onClick={handleClick(ref3)} className="scroll-down-btn"><img src={scrolldown} alt="scroll-down" /></a>
                </div>
            </div>

            <div className="lodging subpage-section minus-margin-top section z-index-3">
                <div className="anchor" id="lodging" ref={ref3}></div>
                <div ref={lodgingStickyRef} className="subpage-left w-27 white-bg parent">
                    <div className="sticky-left-menu child" ref={childRefs.childRef3} style={{ width: `${parentWidths.child3}px` }}>
                        <h2 className="violet">Lodging</h2>
                        <h3>Be nestled in the beauty <br />of nature.</h3>
                        <p>Spend a night in tranquillity while still enjoying the warmth and comfort of modern day living. New Zealand has everything you need to make winter your wonderland.</p>
                    </div>
                </div>
                <div className="subpage-right w-73">
                    <LodgingCarousel />
                </div>
            </div>

            <div className="scroll-down section">
                <p><span className="serif-bold">Next stop,</span> <span className="violet">Flexi-itinerary</span></p>
                <a href="#lodging" onClick={handleClick(ref4)} className="scroll-down-btn"><img src={scrolldown} alt="scroll-down" /></a>
            </div>

            <div className="itinerary violet-bg subpage-section minus-margin-top section">
                <div className="anchor" id="itinerary" ref={ref4}></div>
                <div ref={itineraryStickyRef} className="subpage-left w-35 violet-bg parent">
                    <div className="sticky-left-menu child" ref={childRefs.childRef4} style={{ width: `${parentWidths.child4}px` }}>
                        <h2 className="lightpurple">Flexi-tinerary</h2>
                        <h3>Nicole Chen (@lielnicole)</h3>
                        <p>Ready for a little winter exploration out in New Zealand's great outdoors? Join Nicole as she goes from land to sky, uncovering the many natural wonders and meeting New Zealand's native wildlife.</p>
                        <p>Get inspired to plan your own itinerary with her flexi-tinerary as your base — just mix and match with any suggestions from above.</p>
                    </div>
                </div>
                <div className="subpage-right w-65">
                    <Itinerary />
                    <button className="download-pdf"><a onClick={handleClick(newsletter)}>Download PDF</a></button>
                </div>
            </div>

            <div className="adventure-map darkpurple-bg subpage-section minus-margin-top section z-index-4">
                <h2 className="violet">Locate <br />your next <br />adventure</h2>
                <ImageWithHotspots />
            </div>

            <div className="darkpurple-bg mountainImg z-index-4">
                <img className="full-width" src={mountain} alt="mountain" />
            </div>

            <div className="footer-CTA text-center px-5 z-index-4 white-bg">
                <h2 className="pb-4">Ready for your winter escape?</h2>
                <a href="https://flightbookings.airnewzealand.com.sg/vbook/actions/ext-search" target="_blank" rel="noopener noreferrer" id="winter-escape-cta"><img className="bookNow-btn" src={booknow} alt="booknow" /></a>
            </div>

            <div className="newsletter purple-bg section text-center z-index-4">
                <div className="anchor" id="newsletter" ref={newsletter}></div>
                <h2 className="pb-4">Download this itinerary plus get the latest flight deals, travel tips and more</h2><br />
                <iframe src="https://xd.wayin.com/display/container/dc/5a286949-e63d-46e2-a54b-6dd107541d8b" frameborder="0" scrolling="no" width="100%" height="670" id="ngxFrame5a286949-e63d-46e2-a54b-6dd107541d8b"  allowTransparency="true"></iframe>
            </div>

            <div className="services-page sub-page purple-bg z-index-4">
                <div className="d-flex flex-wrap">
                    <div className="explore-more text-center">
                        <p className="pb-5 violet">Explore more winter holidays below</p>
                        <div className="scroll-down text-center absolute full-width pt-5">
                            <img src={scrolldown} alt="scroll-down" />
                        </div>
                    </div>
                    <div className='services-pagination-container'>
                        <div className="col-4 services-pagination-col peak d-flex flex-wrap align-items-center justify-content-center">
                            <NavHashLink to='/peak-thrills'><h2>Peak <br />Thrills</h2></NavHashLink>
                        </div>
                        <div className="col-4 services-pagination-col chillOut d-flex flex-wrap align-items-center justify-content-center">
                            <NavHashLink to='/chill-out-and-about'><h2>Chill Out <br />and About</h2></NavHashLink>
                        </div>
                        <div className="col-4 services-pagination-col vroom d-flex flex-wrap align-items-center justify-content-center">
                            <NavHashLink to='/vroom-with-a-view'><h2>Vroom with <br />a View</h2></NavHashLink>
                        </div>
                    </div>
                    <div className="scroll-down text-center absolute full-width">
                        <p className="white">Back to the top</p>
                        <a href="#top"><img src={scrolldown} alt="scroll-down" /></a>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Wonders;