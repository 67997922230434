import React, { Component } from "react";
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import whangamomona1 from '../img/5-Vroom with a View/3-Lodging/The Whangamomona Hotel/The Whangamomona Hotel.png';
import riversedge1 from '../img/5-Vroom with a View/3-Lodging/River_s Edge Ruru Tent/Rivers Edge Ruru Tent 1.png';
import riversedge2 from '../img/5-Vroom with a View/3-Lodging/River_s Edge Ruru Tent/Rivers Edge Ruru Tent 2.png';
import wanaka1 from '../img/5-Vroom with a View/3-Lodging/Wanaka TOP 10 Holiday Park/Wanaka TOP 10 Holiday Park.png';
import plateau1 from '../img/5-Vroom with a View/3-Lodging/Plateau Lodge/Plateau Lodge.png';
import NextArrow from '../img/slider-right.png';
import PrevArrow from '../img/slider-left.png';
import NextSliderArrow from '../img/Arrow Right.png';
import PrevSliderArrow from '../img/Arrow Left.png';

const SampleNextArrow = ({ onClick }) => (
  <div className="custom-arrow next" onClick={(e) => { onClick(e); handleClick('NextArrow'); }}>
    <img src={NextArrow} alt="Next" />
  </div>
);

const SamplePrevArrow = ({ onClick }) => (
  <div className="custom-arrow prev" onClick={(e) => { onClick(e); handleClick('PrevArrow'); }}>
    <img src={PrevArrow} alt="Previous" />
  </div>
);
  const SliderPrevArrow = ({ onClick }) => (
    <div className="custom-arrow next slick-arrow slick-next" onClick={onClick}>
      <img src={PrevSliderArrow} alt="Previous" />
    </div>
  );
  
  const SliderNextArrow = ({ onClick }) => (
    <div className="custom-arrow prev slick-arrow slick-prev" onClick={onClick}>
      <img src={NextSliderArrow} alt="Next" />
    </div>
  );

  const handleClick = (section) => {
    const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

    if (isMobile) {
      // Console logs for debugging
      //console.log(`Hover detected on section: ${section}`);
    
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'trackEventNoEcommerce',
        eventDetails: {
          category: 'photoscarousel',
          action: 'arrowclick',
          label: 'winterflydeas',
          value: 'lodging',
        },
      });
    
      // Console log after pushing to dataLayer
      //console.log('DataLayer after push:', window.dataLayer);
    }
  };

  const copyrightTexts = [
    [
      '<span>© Whanagamomona Hotel</span>',
      '',
    ],
    [
      "<span class='white'>© River's Edge Ruru Tent</span>",
      "<span class='white'>© River's Edge Ruru Tent</span>",
    ],
    [
      '<span>© Wanaka TOP 10 Holiday Park</span>',
      '',
    ],
    [
      '<span class="white">© Plateau Lodge</span>',
      '',
    ],
  ];

  export default class SimpleSlider extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isOpen: false,
        lightboxImages: [
          [whangamomona1],
          [riversedge1,riversedge2],
          [wanaka1],
          [plateau1],
        ],
        lightboxPhotoIndexes: Array(4).fill(0).map(() => 0), // Individual indexes for each banner
        openLightbox: Array(4).fill(false), // Array to track open lightbox for each slider
        isMobile: window.innerWidth <= 980, // Initial check for mobile screen size
        activeIndex: null, // To track the active slider
      };
    }
  
    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
    }
  
    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }
  
    handleResize = () => {
      this.setState({ isMobile: window.innerWidth <= 980 });
    };
  
    openLightboxForIndex = (index) => {
      this.setState((prevState) => ({
        isOpen: prevState.isMobile,
        openLightbox: prevState.openLightbox.map((item, idx) => (idx === index ? true : item)),
        activeIndex: index,
        photoIndex: 0, // Set photoIndex to 0 when opening the lightbox for a particular index
      }));
    };
  
  
    render() {
      const {
        isOpen,
        lightboxImages,
        lightboxPhotoIndexes,
        isMobile,
        photoIndex,
        openLightbox,
        activeIndex,
      } = this.state;
  
      const settings = {
          dots: false,
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: true,
          nextArrow: <SliderNextArrow />,
          prevArrow: <SliderPrevArrow />,
          draggable: false, // Disable mouse dragging
          swipe: false,  
          responsive: [
            {
              breakpoint: 980,
              settings: {
                slidesToShow: 3,
              }
            },
            {
                breakpoint: 767,
                settings: {
                  slidesToShow: 2,
                }
              },
          ],
      };
  
      const settings2 = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        };
        return (
          <div className="lodging-carousel four-slides">
            <Slider {...settings}>
              {lightboxImages.map((images, index) => (
                <div key={index}>
                  <div className="thumbnail-banner">
                    <Slider {...settings2} className="">
                      {images.map((image, imgIndex) => (
                        <div
                        className="thumbnail-img thumbnail-banner-slide-img"
                        key={imgIndex}
                        onClick={() => this.openLightboxForIndex(index)}
                      >
                        <img className="full-width" src={image} alt={`Image ${imgIndex}`} />
                        <div
                            className="header-copyright top-right"
                            dangerouslySetInnerHTML={{ __html: copyrightTexts[index][imgIndex] }}
                          />
                      </div>
                      ))}
                    </Slider>
                  </div>
                {index === 0 && (
                  <div className="content">
                      <p className="number violet weight-medium">1</p>
                      <h3>The Whangamomona Hotel</h3>
                      <p>Catch a break within the very four walls where this town's independence was declared</p>
                      <p className="small-text light-italic location">351 Kearoa Road, RD1, Horohoro, <br/>Rotorua, New Zealand</p>
                  </div>  
                )}
                {index === 1 && (
                  <div className="content">
                      <p className="number violet weight-medium">2</p>
                      <h3>River's Edge Ruru Tent</h3>
                      <p>Unwind with a glass of New Zealand's finest while admiring the clear winter night sky</p>
                      <p className="small-text light-italic location">239B Ruakokoputuna Road, <br/>Martinborough 5781</p>
                  </div>  
                )}
                {index === 2 && (
                  <div className="content">
                      <p className="number violet weight-medium">3</p>
                      <h3>Wanaka TOP 10 Holiday Park</h3>
                      <p>Impressive mountain views with ski and snowboard facilities during the winter months</p>
                      <p className="small-text light-italic location">263 Studholme Road, North, <br/>Wānaka 9305</p>
                  </div>  
                )}
                {index === 3 && (
                  <div className="content">
                      <p className="number violet weight-medium">4</p>
                      <h3>Plateau Lodge</h3>
                      <p>Great for cars and campervans, with shuttles to Tongariro Alpine Crossing</p>
                      <p className="small-text light-italic location">17 Carroll Street, National Park <br/>3948</p>
                  </div>  
                )}
                  {/* Lightbox for mobile */}
                  {isOpen && isMobile && openLightbox[index] && (
                    <Lightbox
                      mainSrc={lightboxImages[index][photoIndex]}
                      nextSrc={lightboxImages[index][(photoIndex + 1) % lightboxImages[index].length]}
                      prevSrc={lightboxImages[index][(photoIndex + lightboxImages[index].length - 1) % lightboxImages[index].length]}
                      onCloseRequest={() => this.setState({ isOpen: false, openLightbox: Array(4).fill(false), activeIndex: null })}
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + lightboxImages[index].length - 1) % lightboxImages[index].length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + 1) % lightboxImages[index].length,
                        })
                      }
                    />
                  )}
                </div>
              ))}
            </Slider>
          </div>
        );
      }
    }