import React, { useState, useEffect } from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import day1 from '../img/4-Chill Out and About/4-KOL Itinerary - Sherlyn/Sherlyn - Hobbiton.png';
import day2 from '../img/4-Chill Out and About/4-KOL Itinerary - Sherlyn/Sherlyn - Auckland Sky Tower.png';
import day3 from '../img/4-Chill Out and About/4-KOL Itinerary - Sherlyn/Sherlyn - Wanaka Tree.png';
import day4 from '../img/4-Chill Out and About/4-KOL Itinerary - Sherlyn/Sherlyn - Wanaka Lavender Farm.png';
import day5 from '../img/4-Chill Out and About/4-KOL Itinerary - Sherlyn/Sherlyn - Coronet Peak.png';
import '../TimelineWithProgressBar.css';

const ChillOutItinerary = () => {
    const [expandContent1, setExpandContent1] = useState(false);
    const [expandContent2, setExpandContent2] = useState(false);
    const [expandContent3, setExpandContent3] = useState(false);
    const [expandContent4, setExpandContent4] = useState(false);
    const [expandContent5, setExpandContent5] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const content1 = (
        <>
          <p>We started our holiday with a visit to the magical movie set for the Lord of the Rings trilogy, where we joined a
guided tour that brought us around the Shire. We got to learn about how the movie was made, visited some
iconic sites from the film, learnt about some movie magic, and most importantly got tons of photo
opportunities to capture beautiful memories.</p><p></p>
        </>
    );
    const content2 = (
        <>
         <p>Auckland is easy to explore on foot, with lots of beautiful parks and landmarks, city squares, galleries and
more. Winters are mild in the city, so it's definitely very comfortable to walk everywhere. We decided to take
the kids 186 metres up the Sky Tower's observation deck, where we spent some time enjoying the 360˚ views
of Auckland – the skies were so clear we could even see the mountains in the distance.</p><p></p>
        </>
    );
    const content3 = (
        <>
          <p>We flew from Auckland to Queenstown, and visited Lake Wanaka as our first stop to check out the Wanaka
Tree. Known as the “lone tree of Lake Wanaka”, the tree emerges from the lake waters and is backed by
dramatic mountain peaks, creating the perfect backdrop to rest and relax. With a beach right next to the tree,
you can relax with a picnic while watching the stunning view and a quick dip of your feet in the chilly waters. </p><p></p>
        </>
    );
    const content4 = (
        <>
           <p>Even though it s winter, the Wanaka Lavender Farm is still worth a visit. While the lavender bushes are busy
                    “hibernating”, we got to meet and also hand-feed the most adorable and friendliest alpacas around – something you can only do during winter. With a tearoom and shop area, it's a great stop to have an afternoon
snack, take a stroll and let the weight fall off your shoulders.</p><p></p>
        </>
    );
    const content5 = (
        <>
          <p>What's winter without enjoying a little snow? We took a 1.5 hour drive from Wanaka to visit the snowy slopes
of Coronet Peak for a relaxing day at the top. It's a great ski resort which caters to families with young children,
and even grown-ups with minimal to zero skiing skills. After all that fun out on the ski fields, we took a load off
at the Coronet Peak Cafe, soaking up the amazing views of The Remarkables.</p><p></p>
        </>
    );
    const truncatedContent1 = expandContent1 ? content1 : (
        <>
          <p>{content1.props.children[0].props.children.substring(0, 150)}...</p>
          <p style={{ display: expandContent1 ? 'block' : 'none' }}>{content1.props.children[1]}</p>
        </>
      );
  
      const truncatedContent2 = expandContent2 ? content2 : (
          <>
            <p>{content2.props.children[0].props.children.substring(0, 150)}...</p>
            <p style={{ display: expandContent2 ? 'block' : 'none' }}>{content2.props.children[1]}</p>
          </>
      );
      const truncatedContent3 = expandContent3 ? content3 : (
        <>
          <p>{content3.props.children[0].props.children.substring(0, 150)}...</p>
          <p style={{ display: expandContent3 ? 'block' : 'none' }}>{content3.props.children[1]}</p>
        </>
      );
      const truncatedContent4 = expandContent4 ? content4 : (
        <>
          <p>{content4.props.children[0].props.children.substring(0, 150)}...</p>
          <p style={{ display: expandContent4 ? 'block' : 'none' }}>{content4.props.children[1]}</p>
        </>
      );
      const truncatedContent5 = expandContent5 ? content5 : (
        <>
          <p>{content5.props.children[0].props.children.substring(0, 150)}...</p>
          <p style={{ display: expandContent5 ? 'block' : 'none' }}>{content5.props.children[1]}</p>
        </>
      );
  
      const toggleContent1 = () => {
          setExpandContent1(!expandContent1);
      };
  
      const toggleContent2 = () => {
        setExpandContent2(!expandContent2);
      };
  
      const toggleContent3 = () => {
        setExpandContent3(!expandContent3);
      };
      const toggleContent4 = () => {
        setExpandContent4(!expandContent4);
      };
      const toggleContent5 = () => {
        setExpandContent5(!expandContent5);
      };
  
      useEffect(() => {
          const handleResize = () => {
            setIsMobile(window.innerWidth < 767);
          };
      
          window.addEventListener('resize', handleResize);
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };
      }, []);
  return (
    <>
    <VerticalTimeline layout="1-column">
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ color: '#fff' }}
          contentArrowStyle={{ borderRight: '0px solid  rgb(33, 150, 243)' }}
          iconStyle={{ background: '#fff', color: '#fff' }}
        >
            <div className="timeline-body">
                <div className="timeline-content full-width px-0">
                    <p className="sub-header mt-0">DAY 1</p>
                    <h3 className="vertical-timeline-element-title">Step into the world of Middle-earth and explore the sleepy hills of Hobbiton</h3>
                    <div className="vertical-timeline-element-body">
                            {isMobile ? truncatedContent1 : content1}
                    </div>
                    {isMobile && content1.props.children[0].props.children.length > 150 && (
                        <button onClick={toggleContent1}>
                        {expandContent1 ? 'Read less' : 'Read more'}
                        </button>
                    )}
                    <img alt="" className="full-width pt-50" src={day1}/>
                </div>
            </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ color: '#fff' }}
          contentArrowStyle={{ borderRight: '0px solid  rgb(33, 150, 243)' }}
          iconStyle={{ background: '#fff', color: '#fff' }}
        >
            <div className="timeline-body">
                <div className="timeline-content full-width px-0">
                    <p className="sub-header mt-0">DAY 2</p>
                    <h3 className="vertical-timeline-element-title">Take in all of Auckland without breaking a sweat at the city's iconic Sky Tower</h3>
                    <div className="vertical-timeline-element-body">
                        {isMobile ? truncatedContent2 : content2}
                    </div>
                    {isMobile && content2.props.children[0].props.children.length > 150 && (
                        <button onClick={toggleContent2}>
                        {expandContent2 ? 'Read less' : 'Read more'}
                        </button>
                    )}
                    <img alt="" className="full-width pt-50" src={day2}/>
                </div>
            </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ color: '#fff' }}
          contentArrowStyle={{ borderRight: '0px solid  rgb(33, 150, 243)' }}
          iconStyle={{ background: '#fff', color: '#fff' }}
        >
            <div className="timeline-body">
                <div className="timeline-content full-width px-0">
                    <p className="sub-header mt-0">DAY 3</p>
                    <h3 className="vertical-timeline-element-title">Relax in solitude with a lovely family picnic and stroll around the Wanaka Tree</h3>
                    <div className="vertical-timeline-element-body">
                        {isMobile ? truncatedContent3 : content3}
                    </div>
                    {isMobile && content3.props.children[0].props.children.length > 150 && (
                        <button onClick={toggleContent3}>
                        {expandContent3 ? 'Read less' : 'Read more'}
                        </button>
                    )}
                    <img alt="" className="full-width pt-50" src={day3}/>
                </div>
            </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ color: '#fff' }}
          contentArrowStyle={{ borderRight: '0px solid  rgb(33, 150, 243)' }}
          iconStyle={{ background: '#fff', color: '#fff' }}
        >
            <div className="timeline-body">
                <div className="timeline-content full-width px-0">
                    <p className="sub-header mt-0">DAY 4</p>
                    <h3 className="vertical-timeline-element-title">Explore the natural wonders of the region and make a couple of new furry friends along the way</h3>
                    <div className="vertical-timeline-element-body">
                        {isMobile ? truncatedContent4 : content4}
                    </div>
                    {isMobile && content4.props.children[0].props.children.length > 150 && (
                        <button onClick={toggleContent4}>
                        {expandContent4 ? 'Read less' : 'Read more'}
                        </button>
                    )}
                    <img alt="" className="full-width pt-50" src={day4}/>
                </div>
            </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ color: '#fff' }}
          contentArrowStyle={{ borderRight: '0px solid  rgb(33, 150, 243)' }}
          iconStyle={{ background: '#fff', color: '#fff' }}
        >
            <div className="timeline-body">
                <div className="timeline-content full-width px-0 py-0">
                    <p className="sub-header mt-0">DAY 5</p>
                    <h3 className="vertical-timeline-element-title">Experience a different kind of chill on the snowy slopes of Coronet Peak</h3>
                    <div className="vertical-timeline-element-body">
                        {isMobile ? truncatedContent5 : content5}
                    </div>
                    {isMobile && content5.props.children[0].props.children.length > 150 && (
                        <button onClick={toggleContent5}>
                        {expandContent5 ? 'Read less' : 'Read more'}
                        </button>
                    )}
                    <img alt="" className="full-width pt-50" src={day5}/>
                </div>
            </div>
        </VerticalTimelineElement>
    </VerticalTimeline>
    </>
  );
};

export default ChillOutItinerary;
