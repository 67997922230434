import React, { useState, useEffect } from 'react';
import '../HomeFirstSection.css'; // Import your CSS file
import peakThrills from '../img/1-Homepage/1-Content Bucket Banners/Peak Thrills.png';
import wonders from '../img/1-Homepage/1-Content Bucket Banners/Wonders in the Wild.png';
import chillOut from '../img/1-Homepage/1-Content Bucket Banners/Chill out and About.png';
import vroom from '../img/1-Homepage/1-Content Bucket Banners/Vroom with a View.png';

import peakThrillsMobile from '../img/Mobile/1-Homepage/1-Content Bucket Banners/Peak Thrills.png';
import wondersMobile from '../img/Mobile/1-Homepage/1-Content Bucket Banners/Wonders in the Wild.png';
import chillOuMobile from '../img/Mobile/1-Homepage/1-Content Bucket Banners/Chill out and About.png';
import vroomMobile from '../img/Mobile/1-Homepage/1-Content Bucket Banners/Vroom with a View.png';

const HomeFirstSection = () => {
    const [showFirstText, setShowFirstText] = useState(false);
    const [showSecondText, setShowSecondText] = useState(false);

    useEffect(() => {
        // First div text fades in after 1 second
        const firstTextTimer = setTimeout(() => {
          setShowFirstText(true);
        }, 1000);
    
        // Entire first div fades out and second div text fades in after 3 seconds
        const secondTextTimer = setTimeout(() => {
          setShowFirstText(false);
          setShowSecondText(true);
        }, 4000);
    
        return () => {
          clearTimeout(firstTextTimer);
          clearTimeout(secondTextTimer);
        };
      }, []);

      const trackHoverEvent = (section) => {
        const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

          if (isMobile) {
          // Console logs for debugging
          //console.log(`Hover detected on section: ${section}`);
        
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'trackEventNoEcommerce',
            eventDetails: {
              category: 'homemenu',
              action: 'hover',
              label: 'winterflydeas',
              value: section,
            },
          });
        
          // Console log after pushing to dataLayer
          console.log('DataLayer after push:', window.dataLayer);
        }
      };

  return (
    <div className="homeFirstContainer">
      <div
        className={`first-div ${showFirstText ? 'fade-in' : ''}`}
      >
        <div className="first-div-content white">
          <h1>Find your ideal<br/> winter holiday</h1>
          <p>Click to explore</p>
        </div>
      </div>
      <div
        className={`second-div ${showSecondText ? 'fade-in' : ''}`}
      >
        <div className={`box ${showSecondText ? 'fade-in' : ''}`}
        onMouseEnter={() => {
          trackHoverEvent('peakthrills');
        }}>
            <img className="desktop" src={peakThrills} alt="peak thrills" />
            <img className="mobile" src={peakThrillsMobile} alt="peak thrills" />
          <div className="second-div-content">
            <h2>Peak<br/> Thrills</h2>
            <p>Get your ski gear ready to conquer New Zealand's best slopes</p>
          </div>
          <a className="button" href="/peak-thrills">Take me there!</a>
        </div>
        <div className={`box ${showSecondText ? 'fade-in' : ''}`}
        onMouseEnter={() => {
          trackHoverEvent('wondersinthewild');
        }}>
            <img className="desktop" src={wonders} alt="wonders in the wild" />
            <img className="mobile" src={wondersMobile} alt="peak thrills" />
            <div className="second-div-content white">
              <h2>Wonders in<br/> the Wild</h2>
              <p>Cosy up with stunning landscapes and surprising outdoor experiences</p>
            </div>
            <a className="button white" href="/wonders-in-the-wild">Take me there!</a>
        </div>
        <div className={`box ${showSecondText ? 'fade-in' : ''}`}
        onMouseEnter={() => {
          trackHoverEvent('chilloutandabout');
        }}>
            <img className="desktop" src={chillOut} alt="chill out and about" />
            <img className="mobile" src={chillOuMobile} alt="peak thrills" />
            <div className="second-div-content">
              <h2>Chill Out<br/> and About</h2>
              <p>Leave your worries behind and embrace New Zealand's culture and cuisine</p>
            </div>
            <a className="button white desktop" href="/chill-out-and-about">Take me there!</a>
            <a className="button mobile" href="/chill-out-and-about">Take me there!</a>
        </div>
        <div className={`box ${showSecondText ? 'fade-in' : ''}`}
        onMouseEnter={() => {
          trackHoverEvent('vroomwithaview');
        }}>
          <img className="desktop" src={vroom} alt="vroom with a view" />
          <img className="mobile" src={vroomMobile} alt="peak thrills" />
          <div className="second-div-content">
            <h2>Vroom with<br/> a View</h2>
            <p>Get on the road for an epic self-drive with views to match</p>
          </div>
          <a className="button" href="/vroom-with-a-view">Take me there!</a>
        </div>
      </div>
    </div>
  );
};

export default HomeFirstSection;
