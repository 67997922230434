import React, { useRef, useEffect } from 'react';
import HomeFirstSection from '../components/HomeFirstSection';
import HomeTravelInsiders from '../components/HomeTravelInsiders';
import '../home.css';
import GetInspiredCarousel from '../components/getinspired-carousel';
import scrolldown from '../img/Arrow Down.png';
import ImageWithHotspots from '../components/ImageWithHotspots';
import mountain from '../img/6-Across All Pages/Footer Image_Base Mountain.png';
import booknow from '../img/Book Now Button.png';

const Home = () => {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);

    const handleClick = (ref) => (event) => {
        event.preventDefault();
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth" });
        }
    };

  return (
    <div className="homepage">
      <div className=""><HomeFirstSection/></div>
      <div className="scroll-down z-index-2">
          <a href="travel-insiders" onClick={handleClick(ref1)} className="scroll-down-btn"><img src={scrolldown} alt="scroll-down" /></a>
      </div>
      <div className="section purple-bg center minus-margin-top">
        <div className="anchor" id="travel-insiders" ref={ref1}></div>
        <div className="section-header">
          <h2 className="violet">Get inspired by our<br/> travel insiders</h2>
          <p className="weight-reg sub-header">Flexible itineraries to kickstart<br/> your winter escape</p>
        </div>
        <HomeTravelInsiders/>
      </div>
      <div className="scroll-down z-index-2">
          <a href="map" onClick={handleClick(ref2)} className="scroll-down-btn"><img src={scrolldown} alt="scroll-down" /></a>
      </div>
      <div className="adventure-map darkpurple-bg minus-margin-top section">
          <div className="anchor" id="map" ref={ref2}></div>
          <h2 className="violet">Locate <br/>your next <br/>adventure</h2>
          <ImageWithHotspots/>
      </div>
      <div className="scroll-down z-index-2">
          <a href="airnzwinterflydeas" onClick={handleClick(ref3)} className="scroll-down-btn"><img src={scrolldown} alt="scroll-down" /></a>
      </div>
      <div className="section get-inspired-carousel center minus-margin-top">
        <div className="anchor" id="airnzwinterflydeas" ref={ref3}></div>
        <div className="section-header">
          <h2 className="violet">#AirNZWinterFlydeas</h2>
          <p className="weight-reg sub-header">Where can Air New Zealand take you next?</p>
        </div>
        <GetInspiredCarousel />
      </div>
      <div className="mountainImg">
                <img className="full-width" src={mountain} alt="mountain" />
            </div>
            <div className="footer-CTA text-center px-5">
                <h2 className="pb-4">Ready for your winter escape?</h2>
                <a href="https://flightbookings.airnewzealand.com.sg/vbook/actions/ext-search" target="_blank" rel="noopener noreferrer" id="winter-escape-cta"><img className="bookNow-btn" src={booknow} alt="booknow" /></a>
            </div>
            <div className="section pt-0 empty"></div>
      <div className="services-page">
        <div className="scroll-down text-center absolute full-width">
          <p>Back to the top</p>
          <a href="#top"><img src={scrolldown} alt="scroll-down" /></a>
        </div>
      </div>
    </div>
  );
};
export default Home;
