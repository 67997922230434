import logo from '../img/6-Across All Pages/2-Brand logo/ANZ Logo/Air NZ Wordmark_white_Icon.png';
import alliance from '../img/6-Across All Pages/2-Brand logo/Star Alliance Logo/star alliance logo (white).png';
import fb from '../img/Social/Facebook.png';
import ig from '../img/Social/Instagram.png';
import tw from '../img/Social/Twitter.png';
import yt from '../img/Social/Youtube.png';

const Footer = () => {
  return (
    <div className="footer text-right z-index-4">
        <div className="row align-items-center">
          <div className="col-6">
            <div className="footerSocials mobile minus-margin-top">
              <ul>
                  <li><a href="https://www.facebook.com/airnewzealand" target="_blank" rel="noopener noreferrer"><img src={fb}/></a></li>
                  <li><a href="https://www.instagram.com/airnz/" target="_blank" rel="noopener noreferrer"><img src={ig}/></a></li>
                  <li><a href="https://twitter.com/flyairnz" target="_blank" rel="noopener noreferrer"><img src={tw}/></a></li>
                  <li><a href="https://www.youtube.com/c/airnz" target="_blank" rel="noopener noreferrer"><img src={yt}/></a></li>
              </ul>
          </div>
          </div>
          <div className="col-6">
            <div className="footerLogo"><img src={logo}/></div>
          </div>
        </div>
        <div className="footerSocials py-5 desktop">
            <ul>
                <li><a href="https://www.facebook.com/airnewzealand" target="_blank" rel="noopener noreferrer"><img src={fb}/></a></li>
                <li><a href="https://www.instagram.com/airnz/" target="_blank" rel="noopener noreferrer"><img src={ig}/></a></li>
                <li><a href="https://twitter.com/flyairnz" target="_blank" rel="noopener noreferrer"><img src={tw}/></a></li>
                <li><a href="https://www.youtube.com/c/airnz" target="_blank" rel="noopener noreferrer"><img src={yt}/></a></li>
            </ul>
        </div>
        <div className="alliance"><img src={alliance}/></div>
        <div className="footerCopyright pt-2">Copyright © 2024 Air New Zealand Limited</div>
    </div>
  );
};
export default Footer;
