import React, { Component } from "react";
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import bluerose1 from '../img/4-Chill Out and About/2-Food/Blue Rose Cafe/Blue Rose Cafe 1.png';
import bluerose2 from '../img/4-Chill Out and About/2-Food/Blue Rose Cafe/Blue Rose Cafe 2.png';
import darksky1 from '../img/4-Chill Out and About/2-Food/Dark Sky Diner/Dark Sky Diner 1.png';
import darksky2 from '../img/4-Chill Out and About/2-Food/Dark Sky Diner/Dark Sky Diner 2.png';
import pataka1 from '../img/4-Chill Out and About/2-Food/Pataka Kai Restaurant/Pataka Kai Restaurant 1.png';
import pataka2 from '../img/4-Chill Out and About/2-Food/Pataka Kai Restaurant/Pataka Kai Restaurant 2.png';
import stclaire1 from '../img/4-Chill Out and About/2-Food/St Clair Vineyard Kitchen/St Clair Vineyard Kitchen 1.png';
import stclaire2 from '../img/4-Chill Out and About/2-Food/St Clair Vineyard Kitchen/St Clair Vineyard Kitchen 2.png';
import yealands1 from '../img/4-Chill Out and About/2-Food/Yealands Estate/Yealands Estate 1.png';
import yealands2 from '../img/4-Chill Out and About/2-Food/Yealands Estate/Yealands Estate 2.png';
import NextArrow from '../img/slider-right.png';
import PrevArrow from '../img/slider-left.png';
import NextSliderArrow from '../img/Arrow Right.png';
import PrevSliderArrow from '../img/Arrow Left.png';

const SampleNextArrow = ({ onClick }) => (
  <div className="custom-arrow next" onClick={(e) => { onClick(e); handleClick('NextArrow'); }}>
    <img src={NextArrow} alt="Next" />
  </div>
);

const SamplePrevArrow = ({ onClick }) => (
  <div className="custom-arrow prev" onClick={(e) => { onClick(e); handleClick('PrevArrow'); }}>
    <img src={PrevArrow} alt="Previous" />
  </div>
);
  const SliderPrevArrow = ({ onClick }) => (
    <div className="custom-arrow next slick-arrow slick-next" onClick={onClick}>
      <img src={PrevSliderArrow} alt="Previous" />
    </div>
  );
  
  const SliderNextArrow = ({ onClick }) => (
    <div className="custom-arrow prev slick-arrow slick-prev" onClick={onClick}>
      <img src={NextSliderArrow} alt="Next" />
    </div>
  );

  const handleClick = (section) => {
    const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

    if (isMobile) {
      // Console logs for debugging
      //console.log(`Hover detected on section: ${section}`);
    
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'trackEventNoEcommerce',
        eventDetails: {
          category: 'photoscarousel',
          action: 'arrowclick',
          label: 'winterflydeas',
          value: 'food',
        },
      });
    
      // Console log after pushing to dataLayer
      //console.log('DataLayer after push:', window.dataLayer);
    }
  };
  
  const copyrightTexts = [
    [
      '<span class="white">© Dark Sky Project</span>',
      '<span class="white">© Dark Sky Project</span>',
    ],
    [
      '<span class="white">© St Claire Vineyard Kitchen</span>',
      '<span class="white">© St Claire Vineyard Kitchen</span>',
    ],
    [
      '<span class="white">© Yealands Estate</span>',
      '<span class="white">© Yealands Estate</span>',
    ],
    [
      '<span class="white">© Iconic Auckland Eats</span>',
      '',
    ],
    [
      '<span class="white">© Pātaka Kai Restaurant</span>',
      '<span class="white">© Pātaka Kai Restaurant</span>',
    ],
  ];

export default class SimpleSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      lightboxImages: [
        [darksky1,darksky2],
        [stclaire1, stclaire2],
        [yealands1, yealands2],
        [bluerose1, bluerose2],
        [pataka1, pataka2],
      ],
      lightboxPhotoIndexes: Array(4).fill(0).map(() => 0), // Individual indexes for each banner
      openLightbox: Array(4).fill(false), // Array to track open lightbox for each slider
      isMobile: window.innerWidth <= 980, // Initial check for mobile screen size
      activeIndex: null, // To track the active slider
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 980 });
  };

  openLightboxForIndex = (index) => {
    this.setState((prevState) => ({
      isOpen: prevState.isMobile,
      openLightbox: prevState.openLightbox.map((item, idx) => (idx === index ? true : item)),
      activeIndex: index,
      photoIndex: 0, // Set photoIndex to 0 when opening the lightbox for a particular index
    }));
  };


  render() {
    const {
      isOpen,
      lightboxImages,
      lightboxPhotoIndexes,
      isMobile,
      photoIndex,
      openLightbox,
      activeIndex,
    } = this.state;

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4.99,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPrevArrow />,
        draggable: false, // Disable mouse dragging
        swipe: false,
        responsive: [
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 3.99,
            }
          },
          {
            breakpoint: 980,
            settings: {
              slidesToShow: 2.99,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1.99,
            }
          },
        ],
    };

    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
      };
      return (
        <div className="lodging-carousel">
          <Slider {...settings}>
            {lightboxImages.map((images, index) => (
              <div key={index}>
                <div className="thumbnail-banner">
                  <Slider {...settings2} className="">
                    {images.map((image, imgIndex) => (
                      <div
                      className="thumbnail-img thumbnail-banner-slide-img"
                      key={imgIndex}
                      onClick={() => this.openLightboxForIndex(index)}
                    >
                      <img className="full-width" src={image} alt={`Image ${imgIndex}`} />
                      <div
                          className="header-copyright top-right"
                          dangerouslySetInnerHTML={{ __html: copyrightTexts[index][imgIndex] }}
                        />
                    </div>
                    ))}
                  </Slider>
                </div>
              {index === 0 && (
                <div className="content">
                    <p className="number violet weight-medium">5</p>
                    <h3>Dark Sky Diner, <br/><span className="violet">Lake Tekapo</span></h3>
                    <p>Stunning views of the lake to complement an international cuisine</p>
                    <p className="small-text light-italic location">1 Motuariki Lane, Lake Tekapo <br/>7999, New Zealand</p>
                </div>  
              )}
              {index === 1 && (
                <div className="content">
                    <p className="number violet weight-medium">1</p>
                    <h3>St Clair Vineyard Kitchen, <br/><span className="violet">Marlborough</span></h3>
                    <p>Dinner by the fireplace for a toasty & tasty meal</p>
                    <p className="small-text light-italic location">13 Selmes Road, Blenheim 7273, <br/>Marlborough, New Zealand</p>
                </div>  
              )}
              {index === 2 && (
                <div className="content">
                    <p className="number violet weight-medium">2</p>
                    <h3>Yealands Estate, <br/><span className="violet">Marlborough</span></h3>
                    <p>Sustainable wine tasting to pair with delicious platters</p>
                    <p className="small-text light-italic location">Cnr Seaview and Reserve Roads New Zealand Seddon, Blenheim 7285, <br/>New Zealand</p>
                </div>  
              )}
              {index === 3 && (
                <div className="content">
                    <p className="number violet weight-medium">3</p>
                    <h3>Blue Rose Café, <br/><span className="violet">Auckland</span></h3>
                    <p>Rich Māori flavours packed within this hāngī pie</p>
                    <p className="small-text light-italic location">414 Sandringham Road, Sandringham, <br/>Auckland 1025, New Zealand</p>
                </div>  
              )}
              {index === 4 && (
                <div className="content">
                    <p className="number violet weight-medium">4</p>
                    <h3>Pātaka Kai Restaurant, <br/><span className="violet">Rotorua</span></h3>
                    <p>Savour Maori cuisine including puddings steamed with natural geothermal heat</p>
                    <p className="small-text light-italic location">20 Hemo Road, Whakarewarewa, <br/>Rotorua 3010, New Zealand</p>
                </div>  
              )}
                {/* Lightbox for mobile */}
                {isOpen && isMobile && openLightbox[index] && (
                  <Lightbox
                    mainSrc={lightboxImages[index][photoIndex]}
                    nextSrc={lightboxImages[index][(photoIndex + 1) % lightboxImages[index].length]}
                    prevSrc={lightboxImages[index][(photoIndex + lightboxImages[index].length - 1) % lightboxImages[index].length]}
                    onCloseRequest={() => this.setState({ isOpen: false, openLightbox: Array(4).fill(false), activeIndex: null })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + lightboxImages[index].length - 1) % lightboxImages[index].length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % lightboxImages[index].length,
                      })
                    }
                  />
                )}
              </div>
            ))}
          </Slider>
        </div>
      );
    }
  }