import { NavHashLink } from 'react-router-hash-link';
import logo from '../img/6-Across All Pages/2-Brand logo/ANZ Logo/Air NZ Wordmark_white.png';
import mobileLogo from '../img/6-Across All Pages/2-Brand logo/ANZ Logo/Air NZ Wordmark_white_Icon.png';
import menu from '../img/menu.png';

const Navbar = () => {
  return (
    <>
      <nav className="navbar position-fixed">
        <div className="navbar-wrap">
          <ul className="navbar-nav d-flex align-items-center flex-wrap flex-row justify-content-between">
            <li className="nav-item">
              <a data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample"><img src={menu}/></a>
            </li>
            <li className="nav-item title">
              <NavHashLink to='/'>Winter Fly-deas</NavHashLink>
            </li>
            <li className="nav-item navbar-brand">
              <a href="https://www.airnewzealand.com.sg" target="_blank" rel="noopener noreferrer"><img className="desktop" src={logo} alt="logo" />
                <img className="mobile" src={mobileLogo} alt="logo" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-body-wrap d-flex flex-wrap justify-content-center align-items-center">
          <div className="offcanvas-header">
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body text-center px-5">
            <span>About</span>
            <h2 className="mt-0">Winter<br />Fly-deas</h2>
            <p className="pt-4 ">Did you know that winter season in New Zealand starts in June? Whether you're seeking adventure or a relaxing retreat, get inspired with Air New Zealand to find your ideal winter escape.</p>
          </div>
        </div>
      </div>
      {/*<div ref={booknowStickyBtn} className="booknow-sticky">
        <a href="https://flightbookings.airnewzealand.com.sg/vbook/actions/ext-search" target="_blank" rel="noopener noreferrer"><img className="bookNow-btn" src={booknow} alt="booknow" /></a>
  </div>*/}
    </>
  );
};
export default Navbar;
