import React, { useState, useEffect } from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import jetImg from '../img/2-Peak Thrills/4-KOL Itinerary - Munah/Shotover Jet.png';
import jetVid from '../img/2-Peak Thrills/4-KOL Itinerary - Munah/Shotover Jet.mp4';
import helicopter from '../img/2-Peak Thrills/4-KOL Itinerary - Munah/Garden City Helicopter.png'
import bungyImg from '../img/2-Peak Thrills/4-KOL Itinerary - Munah/Bungy Jumping.png';
import bungyVid from '../img/2-Peak Thrills/4-KOL Itinerary - Munah/Bungy Jumping.mp4';
import '../TimelineWithProgressBar.css';

const PeakThrillsItinerary = () => {
    const [isPlayingJet, setIsPlayingJet] = useState(false);
    const [isPlayingBungy, setIsPlayingBungy] = useState(false);
    const [expandContent1, setExpandContent1] = useState(false);
    const [expandContent2, setExpandContent2] = useState(false);
    const [expandContent3, setExpandContent3] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
    const togglePlayJet = () => {
        setIsPlayingJet(!isPlayingJet);
        setIsPlayingBungy(false); // Ensure other video is stopped
    };

    const togglePlayBungy = () => {
        setIsPlayingBungy(!isPlayingBungy);
        setIsPlayingJet(false); // Ensure other video is stopped
    };

    const content1 = (
        <>
          <p>
            The world-famous Shotover Jet has thrilled over 4 million people since it began in 1965, and continues to draw adrenaline junkies all year round, even during the winter months.
          </p>
          <p>
            Even as it blazes across the river moving wall to wall across canyons with its might, we still got to enjoy the stunning beauty of New Zealand all around us. We just had to brace ourselves for the chilly waters that hit us!
          </p>
        </>
    );
    const content2 = (
        <>
         <p>Learn fun and interesting facts about Christchurch while soaring high above the city on the Garden City Helicopter ride, and count the number of gardens all over the city that give Christchurch its nickname.</p>
                        <p>The pilot doubled as our tour guide, pointing out iconic landmarks
    on the ground such as the Cathedral, new landmarks including the
    city library, as well as some of the upcoming projects that will
    reshape the city. He also brought us to catch the spectacular
    scenery of the Banks Peninsula that is formed from three now-dormant volcanoes, before making a round trip back.</p>
        </>
    );
    const content3 = (
        <>
          <p>This is the place that started it all – the World Home of Bungy since
    1988. Nothing could prepare me before I took that leap of faith, as I
    (hesitantly) moved my feet forward inch by inch. I could feel my
    heart pounding harder and faster as I got closer to the edge!</p>
                        <p>It was an experience like no other, “diving” between rocks before
    hovering just over the river, all the while screaming my lungs out as I
    swung back and forth.</p>
        </>
    );

    const truncatedContent1 = expandContent1 ? content1 : (
      <>
        <p>{content1.props.children[0].props.children.substring(0, 150)}...</p>
        <p style={{ display: expandContent1 ? 'block' : 'none' }}>{content1.props.children[1]}</p>
      </>
    );

    const truncatedContent2 = expandContent2 ? content2 : (
        <>
          <p>{content2.props.children[0].props.children.substring(0, 150)}...</p>
          <p style={{ display: expandContent2 ? 'block' : 'none' }}>{content2.props.children[1]}</p>
        </>
    );
    const truncatedContent3 = expandContent3 ? content3 : (
      <>
        <p>{content3.props.children[0].props.children.substring(0, 150)}...</p>
        <p style={{ display: expandContent3 ? 'block' : 'none' }}>{content3.props.children[1]}</p>
      </>
    );

    const toggleContent1 = () => {
        setExpandContent1(!expandContent1);
    };

    const toggleContent2 = () => {
      setExpandContent2(!expandContent2);
    };

    const toggleContent3 = () => {
      setExpandContent3(!expandContent3);
    };

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 767);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    
  return (
    <>
    <VerticalTimeline layout="1-column">
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ color: '#fff' }}
          contentArrowStyle={{ borderRight: '0px solid  rgb(33, 150, 243)' }}
          iconStyle={{ background: '#fff', color: '#fff' }}
        >
            <div className="timeline-body">
                <div className="timeline-vid">
                    <img alt="" className="full-width" src={jetImg} onClick={togglePlayJet} />
                    {isPlayingJet && (
                        <video control controls={true} autoPlay={true} muted={true}>
                            <source src={jetVid} type="video/mp4" />
                        </video>
                    )}
                </div>
                <div className="timeline-content">
                    <p className="sub-header">DAY 1</p>
                    <h3 className="vertical-timeline-element-title">Get wet and wild on the world's most exciting jet boat ride</h3>
                    <div className="vertical-timeline-element-body">
                            {isMobile ? truncatedContent1 : content1}
                    </div>
                    {isMobile && content1.props.children[0].props.children.length > 150 && (
                        <button onClick={toggleContent1}>
                        {expandContent1 ? 'Read less' : 'Read more'}
                        </button>
                    )}
                </div>
            </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ color: '#fff' }}
          contentArrowStyle={{ borderRight: '0px solid  rgb(33, 150, 243)' }}
          iconStyle={{ background: '#fff', color: '#fff' }}
        >
            <div className="timeline-body">
                <div className="timeline-vid">
                    <img alt="" className="full-width" src={helicopter} />
                </div>
                <div className="timeline-content">
                    <p className="sub-header">DAY 2-3</p>
                    <h3 className="vertical-timeline-element-title">Take flight over Christchurch for a bird's eye view of the garden city</h3>
                    <div className="vertical-timeline-element-body">
                        {isMobile ? truncatedContent2 : content2}
                    </div>
                    {isMobile && content2.props.children[0].props.children.length > 150 && (
                        <button onClick={toggleContent2}>
                        {expandContent2 ? 'Read less' : 'Read more'}
                        </button>
                    )}
                </div>
            </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{color: '#fff' }}
          contentArrowStyle={{ borderRight: '0px solid  rgb(33, 150, 243)' }}
          iconStyle={{ background: '#fff', color: '#fff' }}
        >
            <div className="timeline-body">
                <div className="timeline-vid">
                    <img alt="" className="full-width" src={bungyImg} onClick={togglePlayBungy} />
                    {isPlayingBungy && (
                        <video control controls={true} autoPlay={true} muted={true}>
                            <source src={bungyVid} type="video/mp4" />
                        </video>
                    )}
                </div>
                <div className="timeline-content">
                    <p className="sub-header">DAY 4</p>
                    <h3 className="vertical-timeline-element-title">Testing my limits at the original bungy jump, 43 metres over the Kawarau River</h3>
                    <div className="vertical-timeline-element-body">
                        {isMobile ? truncatedContent3 : content3}
                    </div>
                    {isMobile && content3.props.children[0].props.children.length > 150 && (
                        <button onClick={toggleContent3}>
                        {expandContent3 ? 'Read less' : 'Read more'}
                        </button>
                    )}
                </div>
            </div>         
        </VerticalTimelineElement>
    </VerticalTimeline>
    </>
  );
};

export default PeakThrillsItinerary;
