import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import './style.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home';
import Error from './pages/Error';
import SharedLayout from './pages/SharedLayout';
import PeakThrills from './pages/Peak-thrills';
import ChillOut from './pages/Chill-out-and-about';
import Wonders from './pages/Wonders-in-the-wild';
import Vroom from './pages/Vroom-with-a-view';
import ScrollToTop from './components/ScrollToTop';
import WinterEscapeIntersector from './components/WinterEscapeIntersector';

function App() {
  useEffect(() => {
    Aos.init({
      easing: 'ease-in-out',
      duration: 800,
      offset: 200, // delay the animation until the element is 200 pixels from the viewport
    });
  }, []);

  //AOS scroll animation fix work-around
  useEffect(() => {
    let scrollRef = 0;
    window.addEventListener('scroll', function () {
      // increase value up to 5, then refresh AOS to avoid refreshing while component is being rendered
      scrollRef <= 5 ? scrollRef++ : Aos.refresh()
    });

    return () => {
      window.removeEventListener('scroll')
    }
  }, [])


  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<SharedLayout/>}>
          <Route index element={<Home />} />
          <Route path='peak-thrills'  element={<PeakThrills/>}/>
          <Route path='wonders-in-the-wild' element={<Wonders/>} />
          <Route path='chill-out-and-about' element={<ChillOut/>} />
          <Route path='vroom-with-a-view' element={<Vroom/>} />
          <Route path='*' element={<Error />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
