import React, { useState, useEffect } from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import day1 from '../img/3-Wonders in the Wild/4-KOL Itinerary - Nicole/Zealandia.png';
import day2 from '../img/3-Wonders in the Wild/4-KOL Itinerary - Nicole/Llamas.png';
import day3 from '../img/3-Wonders in the Wild/4-KOL Itinerary - Nicole/Kaikoura.png';
import '../TimelineWithProgressBar.css';

const WondersItinerary = () => {
const [expandContent1, setExpandContent1] = useState(false);
const [expandContent2, setExpandContent2] = useState(false);
const [expandContent3, setExpandContent3] = useState(false);
const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

const content1 = (
    <>
      <p>This amazing award-winning eco-attraction is part of a mega restoration project, and it's just minutes away from
downtown Wellington. I got to see some of New Zealand's rarest birds, reptiles and insects, all living wild in their
natural habitat in The Sanctuary Valley. There's also an exhibition where they took me back in time to the “day
before humans arrived”, to learn about the story of Zealandia that's 80 million years in the making.</p>
                    <p>The experienced guide took me on an very informative walk through this huge sanctuary (make sure you wear
good walking shoes), and shared his knowledge of birdsong, habitats, and local flora to help track down rare
animals for us to see. It's nice to see many of these native animals getting to live on the mainland once again.</p>
    </>
);
const content2 = (
    <>
     <p>Learn fun and interesting facts about Christchurch while soaring high above the city on the Garden City Helicopter ride, and count the number of gardens all over the city that give Christchurch its nickname.</p>
                    <p>The pilot doubled as our tour guide, pointing out iconic landmarks
on the ground such as the Cathedral, new landmarks including the
city library, as well as some of the upcoming projects that will
reshape the city. He also brought us to catch the spectacular
scenery of the Banks Peninsula that is formed from three now-dormant volcanoes, before making a round trip back.</p>
    </>
);
const content3 = (
    <>
      <p>I got to hop on a helicopter that took us up close to see the Kaikoura Seaward ranges and Mt Fyffe for a
different view from our trek the day before. I also got to experience a winter snow landing for the first time, as
the helicopter landed up on the mountain, where they let us spend a couple of minutes off the helicopter to
enjoy the fresh, crisp New Zealand winter air.</p>
                    <p>It was really a surreal and memorable experience standing up there, above the clouds and surrounded by
snow-capped peaks. It really made me appreciate how amazingly wonderful nature is.</p>
    </>
);

const truncatedContent1 = expandContent1 ? content1 : (
    <>
      <p>{content1.props.children[0].props.children.substring(0, 150)}...</p>
      <p style={{ display: expandContent1 ? 'block' : 'none' }}>{content1.props.children[1]}</p>
    </>
  );

  const truncatedContent2 = expandContent2 ? content2 : (
      <>
        <p>{content2.props.children[0].props.children.substring(0, 150)}...</p>
        <p style={{ display: expandContent2 ? 'block' : 'none' }}>{content2.props.children[1]}</p>
      </>
  );
  const truncatedContent3 = expandContent3 ? content3 : (
    <>
      <p>{content3.props.children[0].props.children.substring(0, 150)}...</p>
      <p style={{ display: expandContent3 ? 'block' : 'none' }}>{content3.props.children[1]}</p>
    </>
  );

const toggleContent1 = () => {
    setExpandContent1(!expandContent1);
};

const toggleContent2 = () => {
  setExpandContent2(!expandContent2);
};

const toggleContent3 = () => {
  setExpandContent3(!expandContent3);
};

useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 767);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
}, []);

  return (
    <>
    <VerticalTimeline layout="1-column">
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ color: '#fff' }}
          contentArrowStyle={{ borderRight: '0px solid  rgb(33, 150, 243)' }}
          iconStyle={{ background: '#fff', color: '#fff' }}
        >
            <div className="timeline-body">
                <div className="timeline-content full-width px-0">
                    <p className="sub-header mt-0">DAY 1-2</p>
                    <h3 className="vertical-timeline-element-title">Step into a nature lover's paradise at ZEALANDIA and just go wild</h3>
                    <div className="vertical-timeline-element-body">
                            {isMobile ? truncatedContent1 : content1}
                    </div>
                    {isMobile && content1.props.children[0].props.children.length > 150 && (
                        <button onClick={toggleContent1}>
                        {expandContent1 ? 'Read less' : 'Read more'}
                        </button>
                    )}
                    <img alt="" className="full-width pt-50" src={day1}/>
                </div>
            </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ color: '#fff' }}
          contentArrowStyle={{ borderRight: '0px solid  rgb(33, 150, 243)' }}
          iconStyle={{ background: '#fff', color: '#fff' }}
        >
            <div className="timeline-body">
                <div className="timeline-content full-width px-0">
                    <p className="sub-header mt-0">DAY 3-4</p>
                    <h3 className="vertical-timeline-element-title">Head south to Kaikoura by car, before joining llamas on foot for a fun trekking adventure</h3>
                    <div className="vertical-timeline-element-body">
                        {isMobile ? truncatedContent2 : content2}
                    </div>
                    {isMobile && content2.props.children[0].props.children.length > 150 && (
                        <button onClick={toggleContent2}>
                        {expandContent2 ? 'Read less' : 'Read more'}
                        </button>
                    )}
                    <img alt="" className="full-width pt-50" src={day2}/>
                </div>
            </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ color: '#fff' }}
          contentArrowStyle={{ borderRight: '0px solid  rgb(33, 150, 243)' }}
          iconStyle={{ background: '#fff', color: '#fff' }}
        >
            <div className="timeline-body">
                <div className="timeline-content full-width px-0 py-0">
                    <p className="sub-header mt-0">DAY 4-5</p>
                    <h3 className="vertical-timeline-element-title">Feel on top of the world (literally) with an incredible scenic flight above Kaikōura</h3>
                    <div className="vertical-timeline-element-body">
                        {isMobile ? truncatedContent3 : content3}
                    </div>
                    {isMobile && content3.props.children[0].props.children.length > 150 && (
                        <button onClick={toggleContent3}>
                        {expandContent3 ? 'Read less' : 'Read more'}
                        </button>
                    )}
                    <img alt="" className="full-width pt-50" src={day3}/>
                </div>
            </div>
        </VerticalTimelineElement>
    </VerticalTimeline>
    </>
  );
};

export default WondersItinerary;
