import React, { useEffect, useState, useRef } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import Slider from "react-slick";
import scrolldown from '../img/Arrow Down.png';
import banner from '../img/2-Peak Thrills/Hero Banner_Peak Thrills.png';
import bannerMobile from '../img/Mobile/2-Peak Thrills/Hero Banner_Peak Thrills.png';
import knoll from '../img/2-Peak Thrills/3-Lodging/Knoll Ridge Chalet.png';
import knollMobile from '../img/Mobile/2-Peak Thrills/3-Lodging/Knoll Ridge Chalet.png';
import cardrona1 from '../img/2-Peak Thrills/1-Activities/Cardrona Alpine Resort/Cardrona Alpine Resort 1.png';
import cardrona2 from '../img/2-Peak Thrills/1-Activities/Cardrona Alpine Resort/Cardrona Alpine Resort 2.png';
import cardrona1Mobile from '../img/Mobile/2-Peak Thrills/1-Activities/Cardrona Alpine Resort/Cardrona Alpine Resort 1.png';
import cardrona2Mobile from '../img/Mobile/2-Peak Thrills/1-Activities/Cardrona Alpine Resort/Cardrona Alpine Resort 2.png';
import heli1 from '../img/2-Peak Thrills/1-Activities/Heli Hike/Heli Hike 1.png';
import heli2 from '../img/2-Peak Thrills/1-Activities/Heli Hike/Heli Hike 2.png';
import snowmobiling1 from '../img/2-Peak Thrills/1-Activities/Snowmobiling/Snowmobiling 1.png';
import snowmobiling2 from '../img/2-Peak Thrills/1-Activities/Snowmobiling/Snowmobiling 2.png';
import snowmobiling1Mobile from '../img/Mobile/2-Peak Thrills/1-Activities/Snowmobiling/Snowmobiling 1.png';
import snowmobiling2Mobile from '../img/Mobile/2-Peak Thrills/1-Activities/Snowmobiling/Snowmobiling 2.png';
import snowtubing1 from '../img/2-Peak Thrills/1-Activities/Snowtubing/Snowtubing 1.png';
import snowtubing2 from '../img/2-Peak Thrills/1-Activities/Snowtubing/Snowtubing 2.png';
import skydiving1 from '../img/2-Peak Thrills/1-Activities/Skydiving/Skydiving 1.png';
import skydiving2 from '../img/2-Peak Thrills/1-Activities/Skydiving/Skydiving 2.png';
import skydiving1Mobile from '../img/Mobile/2-Peak Thrills/1-Activities/Skydiving/Skydiving 1.png';
import skydiving2Mobile from '../img/Mobile/2-Peak Thrills/1-Activities/Skydiving/Skydiving 2.png';
import Dining from '../components/PeakThrillsDining';
import ImageWithHotspots from '../components/ImageWithHotspots-PeakThrills';
import LodgingCarousel from '../components/PeakThrillsLodging';
import Itinerary from '../components/PeakThrillsItinerary';
import NextArrow from '../img/slider-right.png';
import PrevArrow from '../img/slider-left.png'
import familyIcon from '../img/Family Friendly Icon.png';
import mountain from '../img/6-Across All Pages/Footer Image_Base Mountain.png';
import booknow from '../img/Book Now Button.png';
import { useStickyBox } from 'react-sticky-box';
const PeakThrills = () => {
    const [activeLink, setActiveLink] = useState('');
    const [parentWidths, setParentWidths] = useState({
        child1: 0,
        child2: 0,
        child3: 0,
        child4: 0,
    });

    const childRefs = {
        childRef1: useRef(null),
        childRef2: useRef(null),
        childRef3: useRef(null),
        childRef4: useRef(null),
    };
    const location = useLocation();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const newsletter = useRef(null);

    const trackClickEvent = (section) => {
        const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

        if (isMobile) {
            // Console logs for debugging
            //console.log(`Click detected on section: ${section}`);

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'trackEvent',
                eventDetails: {
                    category: 'StickyMenu',
                    action: section,
                },
            });

            // Console log after pushing to dataLayer
            //console.log('DataLayer after push:', window.dataLayer);
        }
    };

    const handleClick = (ref, sectionName) => (event) => {
        event.preventDefault();

        // Call trackClickEvent with the sectionName parameter
        trackClickEvent(sectionName);

        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });

            // Set a timeout to delay setting the active state
            setTimeout(() => {
                setActiveLink(sectionName);
            }, 500); // Delay in milliseconds (adjust as needed)
        }
    };

    const getParentWidth = (ref) => {
        if (ref.current) {
            const parentStyles = window.getComputedStyle(ref.current.parentElement);
            const widthWithoutPadding =
                ref.current.parentElement.offsetWidth -
                parseFloat(parentStyles.paddingLeft) -
                parseFloat(parentStyles.paddingRight);
            return widthWithoutPadding;
        }
        return 0;
    };

    useEffect(() => {
        const getSectionHeightByClass = (className) => {
            const sections = document.querySelectorAll(className);
            if (!sections || sections.length === 0) return 0;

            // Assuming all sections have the same height
            const section = sections[0];
            const sectionStyles = window.getComputedStyle(section);
            return parseFloat(sectionStyles.height);
        };

        let initialPositions = {};
        let desktopInitialPositions = {};
        let bottomPositions = {};
        let newBottomHeight = {};
        let heights = {};
        let stickyLeftMenuHeight = {};

        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            for (const section in initialPositions) {
                const topPosition = initialPositions[section];
                const bottomPosition = bottomPositions[section];

                const isMobile = window.innerWidth <= 980;
                if (isMobile) {
                    if (scrollTop >= (topPosition - 200) && scrollTop <= bottomPosition) {
                        setActiveLink(section);
                    }
                } else {
                    if (scrollTop >= (topPosition - 500) && scrollTop <= (bottomPosition)) {
                        setActiveLink(section);
                        //console.log('Current Section:', section);
                    }
                }
            }
        };

        const handleResize = () => {
            setTimeout(() => {
                const width1 = getParentWidth(childRefs.childRef1);
                const width2 = getParentWidth(childRefs.childRef2);
                const width3 = getParentWidth(childRefs.childRef3);
                const width4 = getParentWidth(childRefs.childRef4);

                setParentWidths({
                    child1: width1,
                    child2: width2,
                    child3: width3,
                    child4: width4,
                });

                initialPositions = {
                    activities: ref1.current.getBoundingClientRect().top + window.pageYOffset,
                    food: ref2.current.getBoundingClientRect().top + window.pageYOffset,
                    lodging: ref3.current.getBoundingClientRect().top + window.pageYOffset,
                    itinerary: ref4.current.getBoundingClientRect().top + window.pageYOffset,
                };
                desktopInitialPositions = {
                    activities: initialPositions.activities + 100,
                    food: initialPositions.food,
                    lodging: initialPositions.lodging,
                    itinerary: initialPositions.itinerary + 15,
                };

                heights = {
                    activities: getSectionHeightByClass('.activities'),
                    food: getSectionHeightByClass('.food'),
                    lodging: getSectionHeightByClass('.lodging'),
                    itinerary: getSectionHeightByClass('.itinerary'),
                };

                stickyLeftMenuHeight = {
                    activities: getSectionHeightByClass('.activities .sticky-left-menu'),
                    food: getSectionHeightByClass('.food .sticky-left-menu'),
                    lodging: getSectionHeightByClass('.lodging .sticky-left-menu'),
                    itinerary: getSectionHeightByClass('.itinerary .sticky-left-menu'),
                }

                // Calculate bottom positions
                bottomPositions = {
                    activities: initialPositions.activities + heights.activities,
                    food: initialPositions.food + heights.food,
                    lodging: initialPositions.lodging + heights.lodging,
                    itinerary: initialPositions.itinerary + heights.itinerary,
                };
                newBottomHeight = {
                    activities: bottomPositions.activities - stickyLeftMenuHeight.activities,
                    food: bottomPositions.food - stickyLeftMenuHeight.food - 120,
                    lodging: bottomPositions.lodging - stickyLeftMenuHeight.lodging,
                    itinerary: bottomPositions.itinerary - stickyLeftMenuHeight.itinerary - 165,
                };

                handleScroll();
            }, 1000);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        // Initial call to handleResize
        handleResize();
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    useEffect(() => {
        window.onload = () => {
            if (location.hash === "#itinerary") {
                setTimeout(() => {
                    ref4.current.scrollIntoView({ behavior: "smooth" });
                }, 500)
            }
        };
    }, [location]);

    const sliderHandleClick = (section) => {
        const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

        if (isMobile) {
            // Console logs for debugging
            //console.log(`Hover detected on section: ${section}`);

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'trackEventNoEcommerce',
                eventDetails: {
                    category: 'photoscarousel',
                    action: 'arrowclick',
                    label: 'winterflydeas',
                    value: 'activities',
                },
            });

            // Console log after pushing to dataLayer
            //console.log('DataLayer after push:', window.dataLayer);
        }
    };


    const SampleNextArrow = ({ onClick }) => {
        return (
            <div className="custom-arrow next" onClick={(e) => { onClick(e); sliderHandleClick('NextArrow'); }}>
                <img src={NextArrow} alt="Next" />
            </div>
        );
    };

    const SamplePrevArrow = ({ onClick }) => {
        return (
            <div className="custom-arrow prev" onClick={(e) => { onClick(e); sliderHandleClick('PrevArrow'); }}>
                <img src={PrevArrow} alt="Previous" />
            </div>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const activitiesStickyRef = useStickyBox(
        {
            offsetTop: 50,
            offsetBottom: 20,
        }
    );
    const foodStickyRef = useStickyBox(
        {
            offsetTop: 50,
            offsetBottom: 20
        }
    );
    const lodgingStickyRef = useStickyBox(
        {
            offsetTop: 50,
            offsetBottom: 20
        }
    );
    const itineraryStickyRef = useStickyBox(
        {
            offsetTop: 50,
            offsetBottom: 20
        }
    );

    return (
        <div className="subpage-body peakThrills-page">
            <div id="top" className="subPage-banner" ref={ref5}>
                <div className="banner-img desktop"><img alt="" src={banner} /></div>
                <div className="banner-img mobile"><img alt="" src={bannerMobile} /></div>
                <div className="banner-header">
                    <h1>Peak Thrills</h1>
                    <p className="sub-header">Get your ski gear ready to conquer New Zealand's best slopes</p>
                </div>
                <div className="header-copyright">
                    <div className="header-copyright-left">Cardrona Alpine Resort</div>
                    <div className="header-copyright-right">© PeakNZ</div>
                </div>
            </div>
            <div className="sticky-menu z-index-1" >
                <ul className="">
                    <li className={activeLink === 'activities' ? 'active' : ''}>
                        <a href="#activities" onClick={handleClick(ref1, 'activities')}>
                            Activities
                        </a>
                    </li>
                    <li className={activeLink === 'food' ? 'active' : ''}>
                        <a href="#food" onClick={handleClick(ref2, 'food')}>
                            Food
                        </a>
                    </li>
                    <li className={activeLink === 'lodging' ? 'active' : ''}>
                        <a href="#lodging" onClick={handleClick(ref3, 'lodging')}>
                            Lodging
                        </a>
                    </li>
                    <li className={activeLink === 'itinerary' ? 'active' : ''} >
                        <a className="mobile" href="#itinerary" onClick={handleClick(ref4, 'itinerary')}>
                            Flexi-tinerary
                        </a>
                        <a className="desktop" href="#itinerary" onClick={handleClick(ref4, 'itinerary')}>
                            Flexi-tinerary: Munah Bagharib
                        </a>
                    </li>
                </ul>
            </div>
            <div className="activities subpage-section">
                <div className="anchor" id="activities" ref={ref1}></div>
                <div ref={activitiesStickyRef} className="subpage-left parent">
                    <div className="sticky-left-menu child" ref={childRefs.childRef1} style={{ width: `${parentWidths.child1}px` }}>
                        <h2 className="violet">Activities</h2>
                        <h3>Conquer the slopes with a winter<br /> adventure that's truly e-peak!</h3>
                        <p>Let Air New Zealand bring you on an unforgettable winter escape with postcard views, epic ski fields and fun-filled slopes.</p>
                        <p>Perfect for adrenaline experts and even little adventurers, New Zealand's ski season starts from late June to as late as November, spreading out the crowds so you can enjoy more moments feeling like the mountains are all yours.</p>
                    </div>
                </div>
                <div className="subpage-right">
                    <div className="w-100">
                        <div className="thumbnail-banner">
                            <Slider className="desktop" {...settings}>
                                <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={cardrona1} />
                                    <div className="header-copyright top-right">
                                        <div className="header-copyright-right white">© Destination Queenstown</div>
                                    </div>
                                </div>
                                <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={cardrona2} />
                                    <div className="header-copyright top-right">
                                        <div className="header-copyright-right">© Cardrona Alpine Resort</div>
                                    </div>
                                </div>
                            </Slider>
                            <Slider className="mobile" {...settings}>
                                <div className="tumbnail-banner-slide-img"><img alt="" className="full-width" src={cardrona1Mobile} />
                                    <div className="header-copyright top-right">
                                        <div className="header-copyright-right white">© Destination Queenstown</div>
                                    </div>
                                </div>
                                <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={cardrona2Mobile} />
                                    <div className="header-copyright top-right">
                                        <div className="header-copyright-right">© Brendan Doran</div>
                                    </div>
                                </div>
                            </Slider>
                            <div className="travel-tip desktop">
                                <span className="weight-medium">Travel Pro Tip:</span><br />
                                Want more time on the slopes? Book your tickets or passes online beforehand so you can skip the ticketing queues and start having fun sooner!
                            </div>
                        </div>
                        <div className="content">
                            <h3>Cardrona Alpine Resort, <span className="violet">Cardrona</span></h3>
                            <div className="family-tooltip">
                                <img alt="" className="family-icon" src={familyIcon} />
                                <span className="tooltip-hover-text">Family-friendly</span>
                            </div>
                            <p>Enjoy a fun family day out on the slopes, where even kids as young as 3 months can enjoy a snowy good time. Explore
                                beginner-friendly slopes, join classes for different age groups, or take up private group lessons to learn together as a family.</p>
                            <p>Cardrona offers many family-friendly features including special parking for single parents, convenient drop-offs for family
                                groups, and a Ski Kindy to care for the little ones while mum and dad hit the slopes.</p>
                            <p className="small-text light-italic location">Cardrona Valley Road, Cardrona 9305, New Zealand</p>

                            <div className="travel-tip mobile">
                                <span className="weight-medium">Travel Pro Tip:</span><br />
                                Want more time on the slopes? Book your tickets or passes online beforehand so you can skip the ticketing queues and start having fun sooner!
                            </div>
                        </div>
                    </div>
                    <div className="two-col">
                        <div className="w-6">
                            <div className="thumbnail-banner">
                                <Slider {...settings}>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={heli1} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right white">© Franz Josef Glacier Guides</div>
                                        </div>
                                    </div>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={heli2} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right white">© Miles Holden</div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                        <div className="w-4 content">
                            <h3>Heli Hike, <span className="violet">Franz Josef</span></h3>
                            <p>Enjoy a scenic helicopter ride over the glacier valley, followed by a guided ice hike that takes you through terrain like ice towers and caves that are always changing – no two hikes are alike.</p>
                            <p>It's a leisurely trek with lots of photoworthy stops, making it great for a wider range of fitness levels to experience this natural wonder.</p>
                            <p className="small-text light-italic location desktop">Glacier Base, 63 Cron Street, Franz Josef/ <br />Waiau 7886, New Zealand</p>
                        </div>
                    </div>
                    <div className="two-col">
                        <div className="w-55 content left">
                            <h3>Snowmobiling, <span className="violet">Queenstown</span></h3>
                            <p>Trade your snowshoes for an adrenaline-fuelled snowmobile. Traverse the snowy backcountry for a fast-paced adventure through our pristine winter wonderland – no prior driving experience required.</p>
                            <p>Just bring your excitement and eagerness to explore the “road” less travelled.</p>
                            <p className="small-text light-italic location desktop">35 Lucas Place, Frankton, Queenstown 9300,  <br />New Zealand</p>

                            <div className="tip-box luggage">
                                <p>
                                    <span className="weight-medium">Fly Savvy with Air New Zealand:</span><br />
                                    Bringing your own ski gear? We've got you covered with a maximum allowed weight of 32kg and oversize length of 2.5m (98.4") for sports equipment!
                                </p>
                                <button className="learn-more"><a target="blank" rel="noreferrer" href="https://www.airnewzealand.com.sg/oversized-items?utm_campaign=SG_202304_Winter&utm_content=oversized_tip&_gl=1*11xodzn*_ga*MTk5NjE0NTEwMS4xNzA1NjM2OTgz*_ga_7W0V730RP5*MTcwNTg5MTU0MS4zLjEuMTcwNTg5MTU0Ni4wLjAuMA">LEARN MORE</a></button>
                            </div>
                        </div>
                        <div className="w-45 image right">
                            <div className="thumbnail-banner">
                                <Slider className="desktop" {...settings}>
                                    <div><img alt="" className="full-width" src={snowmobiling1} /></div>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={snowmobiling2} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right white">© NZ.com</div>
                                        </div>
                                    </div>
                                </Slider>
                                <Slider className="mobile" {...settings}>
                                    <div><img alt="" className="full-width" src={snowmobiling1Mobile} /></div>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={snowmobiling2Mobile} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right white">© NZ.com</div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className="w-100">
                        <div className="thumbnail-banner">
                            <Slider className="desktop" {...settings}>
                                <div><img alt="" className="full-width" src={skydiving1} /></div>
                                <div><img alt="" className="full-width" src={skydiving2} /></div>
                            </Slider>
                            <Slider className="mobile" {...settings}>
                                <div><img alt="" className="full-width" src={skydiving1Mobile} /></div>
                                <div><img alt="" className="full-width" src={skydiving2Mobile} /></div>
                            </Slider>
                        </div>
                        <div className="content">
                            <h3>Skydiving, <span className="violet">Fox Glacier</span></h3>
                            <p>Missing the views you saw on your Air New Zealand flight in? Here's your chance to get a literal birds eye view of snowy peaks and glacial valleys with New Zealand's highest skydive at 20,000ft.</p>
                            <p>Experience an epic freefall of over 85 seconds as you take in views of the Southern Alps. It's not for the faint of heart, but in return you'll get to witness the natural wonders of winter that's more than rewarding for the effort.</p>
                            <p className="small-text light-italic location">Franz Josef Glacier Base, 69 Cron Street, Franz Josef / Waiau 7886, New Zealand</p>
                        </div>
                    </div>
                    <div className="two-col">
                        <div className="w-4 content left">
                            <h3>Snow Tubing, <span className="violet">Tekapo Springs</span></h3>
                            <div className="family-tooltip">
                                <img alt="" className="family-icon" src={familyIcon} />
                                <span className="tooltip-hover-text">Family-friendly</span>
                            </div>
                            <p>Enjoy an hour-long tubing session with thrilling rides down a 150m snowy slope, before being whisked back up to do it all over again. Incredibly fun for the whole family, especially the little ones (minimum height of 1 metre).</p>
                            <p className="small-text light-italic location">6 Lakeside Drive, Lake Tekapo 7999, New Zealand</p>
                        </div>
                        <div className="w-6 image right">
                            <div className="thumbnail-banner">
                                <Slider {...settings}>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={snowtubing1} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right">© Tekapo Springs</div>
                                        </div>
                                    </div>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={snowtubing2} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right">© Tekapo Springs</div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="scroll-down section pt-0">
                <p><span className="serif-bold">Next stop,</span> <span className="violet">Food</span></p>
                <a href="#lodging" onClick={handleClick(ref2)} className="scroll-down-btn"><img src={scrolldown} alt="scroll-down" /></a>
            </div>

            <div className="food purple-bg subpage-section minus-margin-top section z-index-1">
                <div className="anchor" id="food" ref={ref2}></div>
                <div ref={foodStickyRef} className="subpage-left w-27 purple-bg parent">
                    <div className="sticky-left-menu" ref={childRefs.childRef2} style={{ width: `${parentWidths.child2}px` }}>
                        <h2 className="violet">Food</h2>
                        <h3>Worked up an appetite<br /> on the slopes? Refuel <br />with delicious food, all<br /> locally sourced.</h3>
                        <p>Get a taste of New Zealand's seasonal dishes made with fresh ingredients from land and sea for the winter season.</p>
                        <p>Whether you're after warm, hearty meals to end off a day of hitting the slopes, or enjoying a coffee break in between, you'll be in for a treat any time of the day.</p>
                    </div>
                </div>
                <div className="subpage-right w-73">
                    <Dining />
                </div>
            </div>

            <div className="banner thumbnail-banner z-index-2">
                <img alt="" className="full-width desktop" src={knoll} />
                <img alt="" className="full-width mobile" src={knollMobile} />
                <div className="scroll-down">
                    <p><span className="serif-bold white">Next stop,</span> <span className="white">Lodging</span></p>
                    <a href="#lodging" onClick={handleClick(ref3)} className="scroll-down-btn"><img src={scrolldown} alt="scroll-down" /></a>
                </div>
            </div>

            <div className="lodging subpage-section minus-margin-top section">
                <div className="anchor" id="lodging" ref={ref3}></div>
                <div ref={lodgingStickyRef} className="subpage-left w-27 white-bg parent">
                    <div className="sticky-left-menu" ref={childRefs.childRef3} style={{ width: `${parentWidths.child3}px` }}>
                        <h2 className="violet">Lodging</h2>
                        <h3>Stay at the heart of <br />the action.</h3>
                        <p>Ever stayed on top of a mountain? Explore unique and exciting accommodations while enjoying off-peak prices during the winter months – perfect for all budgets and preferences.</p>
                    </div>
                </div>
                <div className="subpage-right w-73">
                    <LodgingCarousel />
                </div>
            </div>

            <div className="scroll-down section">
                <p><span className="serif-bold">Next stop,</span> <span className="violet">Flexi-itinerary</span></p>
                <a href="#lodging" onClick={handleClick(ref4)} className="scroll-down-btn"><img src={scrolldown} alt="scroll-down" /></a>
            </div>

            <div className="itinerary violet-bg subpage-section minus-margin-top section">
                <div className="anchor" id="itinerary" ref={ref4}></div>
                <div ref={itineraryStickyRef} className="subpage-left w-35 violet-bg parent">
                    <div className="sticky-left-menu" ref={childRefs.childRef4} style={{ width: `${parentWidths.child4}px` }}>
                        <h2 className="lightpurple">Flexi-tinerary</h2>
                        <h3>Munah Bagharib (@munahbagharib)</h3>
                        <p>Get a glimpse at some of the many adrenaline-fuelled adventures that Munah enjoyed during her short but action-packed trip to New Zealand.</p>
                        <p>Get inspired to plan your own itinerary with her flexi-tinerary as your base — just mix and match with any suggestions from above.</p>
                    </div>
                </div>
                <div className="subpage-right w-65">
                    <Itinerary />
                    <button className="download-pdf"><a onClick={handleClick(newsletter)}>Download PDF</a></button>
                </div>
            </div>

            <div className="adventure-map darkpurple-bg subpage-section minus-margin-top section z-index-4">
                <h2 className="violet">Locate <br />your next <br />adventure</h2>
                <ImageWithHotspots />
            </div>

            <div className="darkpurple-bg mountainImg z-index-4">
                <img className="full-width" src={mountain} alt="mountain" />
            </div>

            <div className="footer-CTA text-center px-5 z-index-4 white-bg">
                <h2 className="pb-4">Ready for your winter escape?</h2>
                <a href="https://flightbookings.airnewzealand.com.sg/vbook/actions/ext-search" target="_blank" rel="noopener noreferrer" id="winter-escape-cta"><img className="bookNow-btn" src={booknow} alt="booknow" /></a>
            </div>

            <div className="newsletter purple-bg section text-center z-index-4">
                <div className="anchor" id="newsletter" ref={newsletter}></div>
                <h2 className="pb-4">Download this itinerary plus get the latest flight deals, travel tips and more</h2><br />
                <iframe src="https://xd.wayin.com/display/container/dc/5a286949-e63d-46e2-a54b-6dd107541d8b" frameborder="0" scrolling="no" width="100%" height="670" id="ngxFrame5a286949-e63d-46e2-a54b-6dd107541d8b"  allowTransparency="true"></iframe>
            </div>

            <div className="services-page sub-page purple-bg z-index-4">
                <div className="d-flex flex-wrap">
                    <div className="explore-more text-center">
                        <p className="pb-5 violet">Explore more winter holidays below</p>
                        <div className="scroll-down text-center absolute full-width pt-5">
                            <img src={scrolldown} alt="scroll-down" />
                        </div>
                    </div>
                    <div className='services-pagination-container'>
                        <div className="col-4 services-pagination-col wonders d-flex flex-wrap align-items-center justify-content-center">
                            <NavHashLink to='/wonders-in-the-wild'><h2>Wonders in <br />the Wild</h2></NavHashLink>
                        </div>
                        <div className="col-4 services-pagination-col chillOut d-flex flex-wrap align-items-center justify-content-center">
                            <NavHashLink to='/chill-out-and-about'><h2>Chill Out <br />and About</h2></NavHashLink>
                        </div>
                        <div className="col-4 services-pagination-col vroom d-flex flex-wrap align-items-center justify-content-center">
                            <NavHashLink to='/vroom-with-a-view'><h2>Vroom with <br />a View</h2></NavHashLink>
                        </div>
                    </div>
                    <div className="scroll-down text-center absolute full-width">
                        <p className="white">Back to the top</p>
                        <a href="#top"><img src={scrolldown} alt="scroll-down" /></a>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PeakThrills;