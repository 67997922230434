import { Link, Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
//import Cursor from '../components/Cursor'

const Home = () => {
  return (
    <>
    {/*<Cursor/>*/}
      <Navbar/>
      <Outlet/>
      <Footer/>
    </>
  );
};
export default Home;
