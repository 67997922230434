import React, { Component } from "react";
import Slider from "react-slick";
import munah from '../img/1-Homepage/2-Travel Insiders_ Inspiration/Peak Thrills - Munah.png';
import nicole from '../img/1-Homepage/2-Travel Insiders_ Inspiration/Wonders in the Wild - Nicole.png';
import aiken from '../img/1-Homepage/2-Travel Insiders_ Inspiration/Vroom with a View - Aiken.png';
import sherlyn from '../img/1-Homepage/2-Travel Insiders_ Inspiration/Chill Out and About - Sherlyn.png';
import NextArrow from '../img/Arrow Right.png';
import PrevArrow from '../img/Arrow Left.png'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block"}}
        onClick={onClick}><img className="arrow" src={NextArrow} alt="Next"/></div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}><img className="arrow" src={PrevArrow} alt="Previous"/></div>
    );
  }

export default class SimpleSlider extends Component {
  state = {
    showPrevArrow: false,
  };
  handleAfterChange = (currentSlide) => {
    // Show previous arrow after the first slide
    if (currentSlide > 0) {
      this.setState({ showPrevArrow: true });
    } else {
      this.setState({ showPrevArrow: false });
    }
  };
  render() {
    const { showPrevArrow } = this.state;
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        variableWidth: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: showPrevArrow ? <SamplePrevArrow /> : null, // Conditionally render the SamplePrevArrow component
        draggable: false, // Disable mouse dragging
        swipe: false,
        responsive: [
          {
            breakpoint: 980,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
            }
          },
        ],
        afterChange: this.handleAfterChange, // Handle slide change event
    };
    return (
      <div className="home-travels-insider">
        <Slider {...settings} className="full-width-carousel">
            <div className="insider-cards">
                <img alt="" className="full-width" src={munah}/>
                <div className="content">
                <p className="small-text weight-reg">4 DAYS OF THRILL</p>
                    <h3>Munah<br/> Bagharib</h3> 
                </div>  
                <a href="/peak-thrills#itinerary" className="button white">READ RECOMMENDATIONS</a>
            </div> 
            <div className="insider-cards">
                <img alt="" className="full-width" src={nicole}/>
                <div className="content">
                <p className="small-text weight-reg">4 DAYS IN NATURE</p>
                    <h3>Nicole<br/> Chen</h3> 
                </div>  
                <a href="/wonders-in-the-wild#itinerary" className="button white">READ RECOMMENDATIONS</a>
            </div> 
            <div className="insider-cards">
                <img alt="" className="full-width" src={aiken}/>
                <div className="content">
                <p className="small-text weight-reg">7 DAYS ON THE ROAD</p>
                    <h3>Aiken<br/> Chia</h3> 
                </div>  
                <a href="/vroom-with-a-view#itinerary" className="button white">READ RECOMMENDATIONS</a>
            </div> 
            <div className="insider-cards">
                <img alt="" className="full-width" src={sherlyn}/>
                <div className="content">
                    <p className="small-text weight-reg">5 DAYS OF CALM</p>
                    <h3>Sherlyn<br/> Chan</h3> 
                </div>  
                <a href="/chill-out-and-about#itinerary" className="button white">READ RECOMMENDATIONS</a>
            </div> 
        </Slider>
      </div>
    );
  }
}