import React, { Component, useState, useEffect } from 'react';
import Hammer from 'hammerjs';
import '../ImageWithHotspots.css'; // Import your CSS file
import map from '../img/2-Peak Thrills/5-Interactive Map/Peak Thrills Map.png';
import snowmobiling from '../img/2-Peak Thrills/5-Interactive Map/Artboard 1.png';
import skydiving from '../img/2-Peak Thrills/5-Interactive Map/Artboard 2.png';
import cardrona from '../img/2-Peak Thrills/5-Interactive Map/Artboard 3.png';
import heli from '../img/2-Peak Thrills/5-Interactive Map/Artboard 4.png';
import snowtubing from '../img/2-Peak Thrills/5-Interactive Map/Snowtubing.png';
import southernlights from '../img/3-Wonders in the Wild/5-Interactive Map/Southern Lights.png';
import milford from '../img/3-Wonders in the Wild/5-Interactive Map/Milford Sound Cruise.png';
import whalewatching from '../img/3-Wonders in the Wild/5-Interactive Map/Whale Watching.png';
import zealandia from '../img/3-Wonders in the Wild/5-Interactive Map/Zealandia.png';
import tranzalpine from '../img/3-Wonders in the Wild/5-Interactive Map/Tranz Alpine Train.png';
import winterfire from '../img/4-Chill Out and About/5-Interactive Map/Winter Fireplace Trail.png';
import taupo from '../img/4-Chill Out and About/5-Interactive Map/Taupo DeBretts Spa Resort.png';
import greytown from '../img/4-Chill Out and About/5-Interactive Map/Greytown Festival of Christmas.png';
import tikitere from '../img/4-Chill Out and About/5-Interactive Map/Tikitere - Hells Gate.png';
import matariki from '../img/4-Chill Out and About/5-Interactive Map/Matariki Festival.png';
import southerscenic from '../img/5-Vroom with a View/5-Interactive Map/Southern Scenic Route.png';
import crossisland from '../img/5-Vroom with a View/5-Interactive Map/Cross-island Wine Trail.png';
import snowhighway from '../img/5-Vroom with a View/5-Interactive Map/Snow Highway.png';
import forgottenworld from '../img/5-Vroom with a View/5-Interactive Map/Forgotten World Highway.png';
import freeneasy from '../img/5-Vroom with a View/5-Interactive Map/Free _ Easy Cross-island Campervan.png';
import volcanicloop from '../img/5-Vroom with a View/5-Interactive Map/The Volcanic Loop.png';
import StickyBox from 'react-sticky-box';

class ImageWithHotspotsPeakThrills extends Component {

  constructor() {
    super();
    this.state = {
      activeImageIndex: 0,
      images: [
        {
          src: map,
          hotspots: [
            {
              desktopX: 14,
              desktopY: 75,
              title: 'Snowmobiling, Queenstown',
              text: '35 Lucas Place, Frankton, Queenstown 9300, New Zealand', // Include HTML line break tags
              imageUrl: snowmobiling,
            },
            {
              desktopX: 21,
              desktopY: 73,
              title: 'Cardrona Alpine Resort, Cardrona',
              text: 'Cardrona Valley Road, Cardrona 9305, New Zealand', // Include HTML line break tags
              imageUrl: cardrona,
            },
            {
              desktopX: 30,
              desktopY: 71,
              title: 'Snow Tubing, Tekapo Springs',
              text: '6 Lakeside Drive, Lake Tekapo 7999, New Zealand', // Include HTML line break tags
              imageUrl: snowtubing,
            },
            {
              desktopX: 30,
              desktopY: 64,
              title: 'Skydiving, Fox Glacier',
              text: 'Franz Josef Glacier Base, 69 Cron Street, Franz Josef / Waiau 7886, New Zealand', // Include HTML line break tags
              imageUrl: skydiving,
            },
            {
              desktopX: 35,
              desktopY: 61.5,
              title: 'Heli Hike, Josef',
              text: 'Glacier Base, 63 Cron Street, Franz Josef/ Waiau 7886, New Zealand', // Include HTML line break tags
              imageUrl: heli,
            },
          ],
        },
        {
          src: map,
          hotspots: [
            {
              desktopX: 60,
              desktopY: 54.5,
              title: 'Winter Fireplace Trail, Marlborough',
              text: 'Marlborough, New Zealand', // Include HTML line break tags
              imageUrl: winterfire,
            },
            {
              desktopX: 70,
              desktopY: 54.5,
              title: 'Greytown Festival of Christmas, Greytown',
              text: 'Greytown, New Zealand', // Include HTML line break tags
              imageUrl: greytown,
            },
            {
              desktopX: 78,
              desktopY: 38.5,
              title: 'Taupo DeBretts Spa Resort, Taupo',
              text: '76 Napier-Taupo Road, SH 5, Taupo, New Zealand', // Include HTML line break tags
              imageUrl: taupo,
            },
            {
              desktopX: 81.5,
              desktopY: 32,
              title: "Tikitere (Hell's Gate), Rotorua",
              text: '351 State Highway 30 Tikitere, Rotorua, New Zealand', // Include HTML line break tags
              imageUrl: tikitere,
            },
            {
              desktopX: 74.5,
              desktopY: 20,
              title: 'Matariki Festival, Auckland',
              text: 'Auckland, New Zealand', // Include HTML line break tags
              imageUrl: matariki,
            },
          ],
        },
        {
          src: map,
          hotspots: [
            {
              desktopX: 8,
              desktopY: 93,
              title: 'Southern Lights, Stewart Island',
              text: 'Stewart Island, New Zealand', // Include HTML line break tags
              imageUrl: southernlights,
            },
            {
              desktopX: 16,
              desktopY: 70,
              title: 'Milford Sound Cruise',
              text: '1 Milford Sound Highway, Milford Sound 9679, New Zealand', // Include HTML line break tags
              imageUrl: milford,
            },
            {
              desktopX: 47,
              desktopY: 69,
              title: 'TranzAlpine Train, Southern Alps',
              text: 'Troup Drive, Addington, Christchurch 8011, New Zealand', // Include HTML line break tags
              imageUrl: tranzalpine,
            },
            {
              desktopX: 56.5,
              desktopY: 60.5,
              title: 'Whale Watching, Kaikōura',
              text: 'Whaleway Station Road, Kaikōura 7340, New Zealand', // Include HTML line break tags
              imageUrl: whalewatching,
            },
            {
              desktopX: 67,
              desktopY: 56,
              title: 'Zealandia, Wellington',
              text: '53 Waiapu Road, Karori, Wellington 6012, New Zealand', // Include HTML line break tags
              imageUrl: zealandia,
            },
          ],
        },
        {
          src: map,
          hotspots: [
            {
              desktopX: 12,
              desktopY: 89,
              title: 'Southern Scenic Route',
              text: 'Invercargill, South Island', // Include HTML line break tags
              imageUrl: southerscenic,
            },
            {
              desktopX: 27,
              desktopY: 78,
              title: 'Free & Easy Cross-island Campervan',
              text: '', // Include HTML line break tags
              imageUrl: freeneasy,
            },
            {
              desktopX: 30.5,
              desktopY: 66.5,
              title: 'Snow Highway',
              text: 'Queenstown, South Island', // Include HTML line break tags
              imageUrl: snowhighway,
            },
            {
              desktopX: 57,
              desktopY: 56,
              title: 'Cross-island Wine Trail',
              text: 'State Highway 2 & 1S, North & South Islands', // Include HTML line break tags
              imageUrl: crossisland,
            },
            {
              desktopX: 76,
              desktopY: 38.5,
              title: 'The Volcanic Loop',
              text: 'Tongariro National Park, North Island', // Include HTML line break tags
              imageUrl: volcanicloop,
            },
            {
              desktopX: 72,
              desktopY: 34.5,
              title: 'Forgotten World Highway',
              text: 'State Highway 43, North Island', // Include HTML line break tags
              imageUrl: forgottenworld,
            },
          ],
        },
      ],
      activeHotspot: null,
      popupPosition: { left: 0, top: 0 },
      buttonLabels: ['Peak Thrills', 'Chill Out and About', 'Wonders in the Wild', 'Vroom with a View'],
      isMobile: window.innerWidth <= 980, // Check if mobile based on width

      initialPosition: 0,
      mapHeight: 0,
      mapBottomPosition: 0,
    };
  }

  trackHoverEvent = (eventName) => {
    // Define the implementation of trackHoverEvent here
    console.log(`Tracking hover event: ${eventName}`);
  };

  
  ccomponentDidMount() {
    // Initialize Hammer.js on your hotspot container
    const hotspotContainer = document.getElementById('hotspot-image-container');
    const hammer = new Hammer(hotspotContainer);

    // Add event listeners
    hammer.on('tap', (event) => {
      const hotspot = /* calculate hotspot based on event */
      this.handleHotspotClick(hotspot);
      
      if (Hammer.DIRECTION_ALL === 'Android') {
        this.trackHoverEvent('chilloutandabout Android'); // Use this.trackHoverEvent
      } else {
        this.trackHoverEvent('chilloutandabout iOS'); // Use this.trackHoverEvent
      }
    });

    hammer.on('press', (event) => {
      const hotspot = /* calculate hotspot based on event */
      this.handleHotspotHover(hotspot);
  
      if (Hammer.DIRECTION_ALL !== 'Android') {
        this.trackHoverEvent('chilloutandabout iOS'); // Use this.trackHoverEvent
      }
    });

    window.addEventListener('resize', this.handleWindowResize);

    // Set activeHotspot to the first hotspot of the initial image
    const initialHotspot = this.state.images[this.state.activeImageIndex].hotspots[0];
    this.setState({ activeHotspot: initialHotspot });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('resize', this.handleWindowResize);
  }
  
  getSectionHeightByClass = (className) => {
    const element = document.querySelector(className);
    return element ? element.getBoundingClientRect().height : 0;
  };

  handleResize = () => {
    const mapElement = document.querySelector('.map');
    const stickyLeftBtns = document.querySelector('.sticky-left-btns');

    if (mapElement) {
      const mapRect = mapElement.getBoundingClientRect();
      const mapHeight = this.getSectionHeightByClass('.map');
      const initialPosition = mapRect.top + window.pageYOffset - 80;
      const stickyBtnsHeight = stickyLeftBtns.offsetHeight;
      let mapBottomPosition;

      const isSmallDesktop = window.innerWidth <= 1439 && window.innerWidth >= 981;
      const XXLScreen = window.innerWidth >= 2560;
      const XLScreen = window.innerWidth <= 2559 && window.innerWidth >= 1920;
      const LScreen = window.innerWidth <= 1919 && window.innerWidth >= 1440;

      if (isSmallDesktop) {
        mapBottomPosition = initialPosition + mapHeight - stickyBtnsHeight - 20;
      } else if (XXLScreen) {
        mapBottomPosition = initialPosition + mapHeight - stickyBtnsHeight - 130;
      } else if (XLScreen) {
        mapBottomPosition = initialPosition + mapHeight - stickyBtnsHeight - 80;
      } else if (LScreen) {
        mapBottomPosition = initialPosition + mapHeight - stickyBtnsHeight - 60;
      } else {
        mapBottomPosition = initialPosition + mapHeight - stickyBtnsHeight - 20;
      }

      this.setState({ initialPosition, mapHeight, mapBottomPosition });

      this.handleWindowResize();
    }
  };

  handleWindowResize = () => {
    this.setState({ isMobile: window.innerWidth <= 980 });
  };

  handleHotspotHover = (hotspot) => {
    if (!this.state.isMobile) {
      this.setState({ activeHotspot: hotspot });
    }
  };

  handleHotspotLeave = () => {
    if (!this.state.isMobile && this.state.activeHotspot) {
      // Check if the mouse is moving to another hotspot before removing activeHotspot
      const stillHovering = this.state.images[this.state.activeImageIndex].hotspots.some(
        hotspot =>
          hotspot === this.state.activeHotspot ||
          (this.state.activeHotspot &&
            hotspot.desktopX === this.state.activeHotspot.desktopX &&
            hotspot.desktopY === this.state.activeHotspot.desktopY)
      );

      if (!stillHovering) {
        setTimeout(() => {
          this.setState({ activeHotspot: null });
        }, 300); // Adjust the delay time to match your CSS transition duration
      }
    }
  };
  handleHotspotClick = (hotspot) => {
    if (this.state.isMobile) {
      if (this.state.activeHotspot === hotspot) {
        setTimeout(() => {
          this.setState({ activeHotspot: null });
        }, 100); // Adjust the delay time as needed
      } else {
        setTimeout(() => {
          this.setState({ activeHotspot: hotspot });
        }, 100); // Adjust the delay time as needed
      }
    }
  };

  handleImageChange = (imageIndex) => {
    const newActiveHotspot = this.state.images[imageIndex].hotspots[0];
    this.setState({ activeImageIndex: imageIndex, activeHotspot: newActiveHotspot });
  }

  handleClosePopup = () => {
    this.setState({ activeHotspot: null });
  };


  render() {
    const { images, activeImageIndex, activeHotspot, buttonLabels, isMobile } = this.state;
    const currentImage = images[activeImageIndex];

    return (
      <div className="map">
        <div className="image-container hotspot-image-container" id="hotspot-image-container">
          {images.map((image, index) => (
            <div
              key={index}
              className={`image ${index === activeImageIndex ? 'active' : ''}`}
              style={{ display: index === activeImageIndex ? 'block' : 'none' }}
            >
              <img
                src={image.src}
                alt={`Image ${index + 1}`}
                style={{ width: '100%', height: 'auto' }}
              />
              {image.hotspots.map((hotspot, hotspotIndex) => (
                <>
                  <div className={`popup mobile ${activeHotspot === hotspot ? 'active' : ''}`}>
                    {activeHotspot === hotspot && (
                      <>
                        <button className="popup-close" onClick={() => this.handleClosePopup()}>
                          X
                        </button>
                        <img src={activeHotspot.imageUrl} alt="Hotspot Image" />
                        <div className="hotspot-content">
                          <h3 className="serif-bold" dangerouslySetInnerHTML={{ __html: activeHotspot.title }} />
                          <p className="small-text light-italic" dangerouslySetInnerHTML={{ __html: activeHotspot.text }} />
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    key={hotspotIndex}
                    className={`hotspot-container ${activeHotspot === hotspot ? 'hover-effect' : ''}`}
                    style={{
                      left: `${hotspot.desktopX}%`, // Use percentage value for left position
                      top: `${hotspot.desktopY}%`, // Use percentage value for top position
                      width: '20px',
                      height: '20px',
                    }}
                    onMouseEnter={() => this.handleHotspotHover(hotspot)}
                    onMouseLeave={this.handleHotspotLeave}
                    onClick={() => this.handleHotspotClick(hotspot)}
                    onTouchStart={(e) => {
                      e.preventDefault();
                      this.handleHotspotClick(hotspot);
                    }}
                    onTouchEnd={(e) => {
                      e.preventDefault();
                      this.handleHotspotClick(hotspot);
                      // ... additional code
                    }}
                  >
                    <div className="hotspot" />
                    <div className={`popup desktop ${activeHotspot === hotspot ? 'active' : ''}`}>
                      {activeHotspot === hotspot && (
                        <>
                          <button className="popup-close" onClick={() => this.handleClosePopup()}>
                            X
                          </button>
                          <img src={activeHotspot.imageUrl} alt="Hotspot Image" />
                          <div className="hotspot-content">
                            <h3 className="serif-bold" dangerouslySetInnerHTML={{ __html: activeHotspot.title }} />
                            <p className="small-text light-italic" dangerouslySetInnerHTML={{ __html: activeHotspot.text }} />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ))}
            </div>
          ))}
        </div>

        <StickyBox offsetTop={80} offsetBottom={20}>
          <div className="sticky-left-btns">
            <div className="image-buttons">
              {images.map((image, index) => (
                <button
                  key={index}
                  onClick={() => this.handleImageChange(index)}
                  className={index === activeImageIndex ? 'active violet' : 'violet'}
                >
                  {buttonLabels[index]}
                </button>
              ))}
            </div>
          </div>
        </StickyBox>
      </div>
    );
  }
}

export default ImageWithHotspotsPeakThrills;