import React, { Component } from "react";
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import hapuku1 from '../img/3-Wonders in the Wild/3-Lodging/Hapuku Lodge + Tree Houses/Hapuku Lodge + Tree Houses 1.png';
import hapuku2 from '../img/3-Wonders in the Wild/3-Lodging/Hapuku Lodge + Tree Houses/Hapuku Lodge + Tree Houses 2.png';
import silverstream1 from '../img/3-Wonders in the Wild/3-Lodging/Silverstream Alpaca Farmstay/Silverstream Alpaca Farmstay 1.png';
import silverstream2 from '../img/3-Wonders in the Wild/3-Lodging/Silverstream Alpaca Farmstay/Silverstream Alpaca Farmstay 2.png';
import milford1 from '../img/3-Wonders in the Wild/3-Lodging/Milford Sound Lodge/Milford Sound Lodge 1.png';
import milford2 from '../img/3-Wonders in the Wild/3-Lodging/Milford Sound Lodge/Milford Sound Lodge 2.png';
import boho1 from '../img/3-Wonders in the Wild/3-Lodging/Boho Bach/Boho Bach 1.png';
import boho2 from '../img/3-Wonders in the Wild/3-Lodging/Boho Bach/Boho Bach 2.png';
import hereweka1 from '../img/3-Wonders in the Wild/3-Lodging/Hereweka Garden Retreat/Hereweka Garden Retreat 1.png';
import hereweka2 from '../img/3-Wonders in the Wild/3-Lodging/Hereweka Garden Retreat/Hereweka Garden Retreat 2.png';
import wilderness1 from '../img/3-Wonders in the Wild/3-Lodging/Wilderness Lodge Arthurs Pass/Wilderness Lodge Arthurs Pass 1.png';
import wilderness2 from '../img/3-Wonders in the Wild/3-Lodging/Wilderness Lodge Arthurs Pass/Wilderness Lodge Arthurs Pass 2.png';
import NextArrow from '../img/slider-right.png';
import PrevArrow from '../img/slider-left.png';
import NextSliderArrow from '../img/Arrow Right.png';
import PrevSliderArrow from '../img/Arrow Left.png';

const SampleNextArrow = ({ onClick }) => (
  <div className="custom-arrow next" onClick={(e) => { onClick(e); handleClick('NextArrow'); }}>
    <img src={NextArrow} alt="Next" />
  </div>
);

const SamplePrevArrow = ({ onClick }) => (
  <div className="custom-arrow prev" onClick={(e) => { onClick(e); handleClick('PrevArrow'); }}>
    <img src={PrevArrow} alt="Previous" />
  </div>
);
  const SliderPrevArrow = ({ onClick }) => (
    <div className="custom-arrow next slick-arrow slick-next" onClick={onClick}>
      <img src={PrevSliderArrow} alt="Previous" />
    </div>
  );
  
  const SliderNextArrow = ({ onClick }) => (
    <div className="custom-arrow prev slick-arrow slick-prev" onClick={onClick}>
      <img src={NextSliderArrow} alt="Next" />
    </div>
  );

  const handleClick = (section) => {
    const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

    if (isMobile) {
      // Console logs for debugging
      //console.log(`Hover detected on section: ${section}`);
    
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'trackEventNoEcommerce',
        eventDetails: {
          category: 'photoscarousel',
          action: 'arrowclick',
          label: 'winterflydeas',
          value: 'lodging',
        },
      });
    
      // Console log after pushing to dataLayer
      //console.log('DataLayer after push:', window.dataLayer);
    }
  };

  const copyrightTexts = [
    [
      '<span class="white">© Jake Wilton</span>',
      '<span class="white">© Jake Wilton</span>',
    ],
    [
      '<span class="white">© Silverstream Alpaca Farmstay</span>',
      '<span>© Silverstream Alpaca Farmstay</span>',
    ],
    [
      '<span class="white">© Milford Sound Lodge</span>',
      '<span class="white">© Milford Sound Lodge</span>',
    ],
    [
      '<span class="white">© Boho Bach</span>',
      '<span class="white">© Boho Bach</span>',
    ],
    [
      '<span class="white">© Hereweka Garden Retreat</span>',
      '',
    ],
    [
      "<span class='white'>© Wilderness Lodge Arthur's Pass</span>",
      "<span class='white'>© Wilderness Lodge Arthur's Pass</span>",
    ],
  ];

  export default class SimpleSlider extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isOpen: false,
        lightboxImages: [
          [hapuku1,hapuku2],
          [silverstream1,silverstream2],
          [milford1,milford2],
          [boho1,boho2],
          [hereweka1,hereweka2],
          [wilderness1,wilderness2],
        ],
        lightboxPhotoIndexes: Array(4).fill(0).map(() => 0), // Individual indexes for each banner
        openLightbox: Array(4).fill(false), // Array to track open lightbox for each slider
        isMobile: window.innerWidth <= 980, // Initial check for mobile screen size
        activeIndex: null, // To track the active slider
      };
    }
  
    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
    }
  
    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }
  
    handleResize = () => {
      this.setState({ isMobile: window.innerWidth <= 980 });
    };
  
    openLightboxForIndex = (index) => {
      this.setState((prevState) => ({
        isOpen: prevState.isMobile,
        openLightbox: prevState.openLightbox.map((item, idx) => (idx === index ? true : item)),
        activeIndex: index,
        photoIndex: 0, // Set photoIndex to 0 when opening the lightbox for a particular index
      }));
    };
  
  
    render() {
      const {
        isOpen,
        lightboxImages,
        lightboxPhotoIndexes,
        isMobile,
        photoIndex,
        openLightbox,
        activeIndex,
      } = this.state;
  
      const settings = {
          dots: false,
          infinite: true,
          slidesToShow: 5,
          slidesToScroll: 1,
          arrows: true,
          nextArrow: <SliderNextArrow />,
          prevArrow: <SliderPrevArrow />,
          draggable: false, // Disable mouse dragging
          swipe: false,  
          responsive: [
            {
              breakpoint: 1366,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 980,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
              }
            },
          ],
      };
  
      const settings2 = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        };
        return (
          <div className="lodging-carousel">
            <Slider {...settings}>
              {lightboxImages.map((images, index) => (
                <div key={index}>
                  <div className="thumbnail-banner">
                    <Slider {...settings2} className="">
                      {images.map((image, imgIndex) => (
                        <div
                        className="thumbnail-img thumbnail-banner-slide-img"
                        key={imgIndex}
                        onClick={() => this.openLightboxForIndex(index)}
                      >
                        <img className="full-width" src={image} alt={`Image ${imgIndex}`} />
                        <div
                            className="header-copyright top-right"
                            dangerouslySetInnerHTML={{ __html: copyrightTexts[index][imgIndex] }}
                          />
                      </div>
                      ))}
                    </Slider>
                  </div>
                {index === 0 && (
                  <div className="content">
                      <p className="number violet weight-medium">1</p>
                      <h3 className="desktop">Hapuku Lodge + Tree <br/>Houses, <span className="violet">Kaikōura</span></h3>
                      <h3 className="mobile">Hapuku Lodge + <br/>Tree Houses, <br/><span className="violet">Kaikōura</span></h3>
                      <p>A literal elevated lodging experience set in a manuka grove</p>
                      <p className="small-text light-italic location">State Highway 1, Station Road RD 1, <br/>Kaikōura 7371, New Zealand</p>
                  </div>  
                )}
                {index === 1 && (
                  <div className="content">
                      <p className="number violet weight-medium">2</p>
                      <h3>Silverstream Alpaca <br/>Farmstay, <span className="violet">Ohapuku</span></h3>
                      <p>Share the farm with these teddy-like creatures that children will love</p>
                      <p className="small-text light-italic location">68 Moodys Road, RD 2, Kaiapoi 7692, Christchurch, North <br/>Canterbury, New Zealandd</p>
                  </div>  
                )}
                {index === 2 && (
                  <div className="content">
                      <p className="number violet weight-medium">3</p>
                      <h3>Milford Sound <br/>Lodge, <span className="violet">Fiordland</span></h3>
                      <p>Get close to these magnificent wonders with front-row seats right at your doorstep.</p>
                      <p className="small-text light-italic location">Milford Sound / Piopiotahi, <br/>Southland 9679, New Zealand</p>
                  </div>  
                )}
                {index === 3 && (
                  <div className="content">
                      <p className="number violet weight-medium">4</p>
                      <h3>Boho Bach, <br/><span className="violet">Wellington</span></h3>
                      <p>Stay close to nature, and also the heart of Wellington in this 1950s bach (cottage)</p>
                      <p className="small-text light-italic location">Fortification Road, Karaka Bays, <br/>Wellington 6022, New Zealand</p>
                  </div>  
                )}
                {index === 4 && (
                  <div className="content">
                      <p className="number violet weight-medium">5</p>
                      <h3>Hereweka Garden <br/>Retreat, <span className="violet">Dunedin</span></h3>
                      <p>Awake to the floral scents of the garden with this self-contained, eco-friendly retreat</p>
                      <p className="small-text light-italic location">10 Hoopers Inlet Road, RD2 <br/>Dunedin 9077, New Zealand</p>
                  </div>  
                )}
                {index === 5 && (
                  <div className="content">
                      <p className="number violet weight-medium">6</p>
                      <h3>Wilderness Lodge <br/>Arthur's Pass, <br/><span className="violet">Arthur's Pass Village</span></h3>
                      <p>Nestled between the mountains of Arthur's Pass, with a working sheep farm at your door</p>
                      <p className="small-text light-italic location">State Highway 73, 16km East of Arthur's Pass Village, Arthur's Pass Village 7654, New Zealand</p>
                  </div>  
                )}
                  {/* Lightbox for mobile */}
                  {isOpen && isMobile && openLightbox[index] && (
                    <Lightbox
                      mainSrc={lightboxImages[index][photoIndex]}
                      nextSrc={lightboxImages[index][(photoIndex + 1) % lightboxImages[index].length]}
                      prevSrc={lightboxImages[index][(photoIndex + lightboxImages[index].length - 1) % lightboxImages[index].length]}
                      onCloseRequest={() => this.setState({ isOpen: false, openLightbox: Array(4).fill(false), activeIndex: null })}
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + lightboxImages[index].length - 1) % lightboxImages[index].length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + 1) % lightboxImages[index].length,
                        })
                      }
                    />
                  )}
                </div>
              ))}
            </Slider>
          </div>
        );
      }
    }