import React, { Component } from "react";
import Slider from "react-slick";
import aiken1 from '../img/1-Homepage/3-AirNZWinterFlydeas/Aiken 1.png';
import aiken2 from '../img/1-Homepage/3-AirNZWinterFlydeas/Aiken 2.png';
import munah1 from '../img/1-Homepage/3-AirNZWinterFlydeas/Munah 1.png';
import munah2 from '../img/1-Homepage/3-AirNZWinterFlydeas/Munah 2.png';
import nicole1 from '../img/1-Homepage/3-AirNZWinterFlydeas/Nicole 1.png';
import nicole2 from '../img/1-Homepage/3-AirNZWinterFlydeas/Nicole 2.png';
import sherlyn1 from '../img/1-Homepage/3-AirNZWinterFlydeas/Sherlyn 1.png';
import sherlyn2 from '../img/1-Homepage/3-AirNZWinterFlydeas/Sherlyn 2.png';
import NextArrow from '../img/Arrow Right.png';
import PrevArrow from '../img/Arrow Left.png'

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block"}}
      onClick={onClick}><img className="arrow" src={NextArrow} alt="Next"/></div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}><img className="arrow" src={PrevArrow} alt="Previous"/></div>
  );
}

export default class SimpleSlider extends Component {
  render() {
    const settings = {
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        variableWidth: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        draggable: false, // Disable mouse dragging
        swipe: false,
        responsive: [
          {
            breakpoint: 1439,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1
            }
          },
      ]
    };
    return (
      <div className="getinspired-carousel-container">
        <Slider {...settings} className="getinspired-carousel">
            <div>
                <div className="thumbnail-img">
                    <img alt="" className="full-width" src={munah1}/>
                </div>
                <div className="content">
                    <p className="serif-bold">@munahbagharib<br/></p>
                    <p className="small-text">Nothing gets the adrenaline pumping more than racing down rapids with chilly waters splashing at you. Made it through with a smile!</p>
                </div>  
            </div> 
            <div>
                <div className="thumbnail-img">
                    <img alt="" className="full-width" src={nicole1}/>
                </div>
                <div className="content">
                    <p className="serif-bold">@nicoleliel<br/></p>
                    <p className="small-text">Feels like I'm literally standing on top of the world. The helicopter ride up to the snowcapped mountains was breathtaking at every second.</p>
                </div>   
            </div> 
            <div>
                <div className="thumbnail-img">
                    <img alt="" className="full-width" src={aiken1}/>
                </div>
                <div className="content">
                    <p className="serif-bold">@aikenchia<br/></p>
                    <p className="small-text">You won't hear me whining with all these delicious wines waiting for me to try out, made right here in the Martinborough wine region.</p>
                </div>  
            </div> 
            <div>
                <div className="thumbnail-img">
                    <img alt="" className="full-width" src={sherlyn1}/>
                </div>
                <div className="content">
                    <p className="serif-bold">@sherlynchanwp<br/></p>
                    <p className="small-text">Spend the day stepping into the magical world of the Lord of the Rings. So many fun, beautiful spots to capture photos with my little ones. Plus I got to see familiar scenes from the movie.</p>
                </div>  
            </div> 
            <div>
                <div className="thumbnail-img">
                    <img alt="" className="full-width" src={munah2}/>
                </div>
                <div className="content">
                    <p className="serif-bold">@munahbagharib<br/></p>
                    <p className="small-text">Take a helicopter ride over Christchurch for a bird's-eye view of the garden city, with an informative tour highlighting the city's vibrant present and future landmarks.</p>
                </div>  
            </div> 
            <div>
                <div className="thumbnail-img">
                    <img alt="" className="full-width" src={nicole2}/>
                </div>
                <div className="content">
                    <p className="serif-bold">@nicoleliel<br/></p>
                    <p className="small-text">Get paired with a llama and go on an exciting guided trek along the riverbank next to a preserved forest. It's the best way to experience New Zealand's natural heritage with an "unusual" companion.</p>
                </div>  
            </div> 
            <div>
                <div className="thumbnail-img">
                    <img alt="" className="full-width" src={aiken2}/>
                </div>
                <div className="content">
                    <p className="serif-bold">@aikenchia<br/></p>
                    <p className="small-text">The fuel for my road trip begins with brunch. New Zealand knows how to serve up hearty dishes that will keep you satisfied for hours.</p>
                </div>  
            </div> 
            <div>
                <div className="thumbnail-img">
                    <img alt="" className="full-width" src={sherlyn2}/>
                </div>
                <div className="content">
                    <p className="serif-bold">@sherlynchanwp<br/></p>
                    <p className="small-text">The best way to an alpaca's heart is also through its tummy! Befriend these furry creatures and get to experience a fun hand-feeding session with them – something you can only do during New Zealand's winter season!</p>
                </div>  
            </div> 
          </Slider>
      </div>
    );
  }
}