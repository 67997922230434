import React, { Component } from "react";
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import cardrona1 from '../img/2-Peak Thrills/3-Lodging/Cardrona Alpine Apartments/Cardrona Alpine Apartments 1.png';
import cardrona2 from '../img/2-Peak Thrills/3-Lodging/Cardrona Alpine Apartments/Cardrona Alpine Apartments 2.png';
import kelly1 from '../img/2-Peak Thrills/3-Lodging/Kelly Hut/Kelly Hut 1.png';
import kelly2 from '../img/2-Peak Thrills/3-Lodging/Kelly Hut/Kelly Hut 2.png';
import tophut1 from '../img/2-Peak Thrills/3-Lodging/Top Hut/Top Hut 1.png';
import tophut2 from '../img/2-Peak Thrills/3-Lodging/Top Hut/Top Hut 2.png';
import kelman1 from '../img/2-Peak Thrills/3-Lodging/Kelman Hut/Kelman Hut 1.png';
import kelman2 from '../img/2-Peak Thrills/3-Lodging/Kelman Hut/Kelman Hut 2.png';
import matakauri1 from '../img/2-Peak Thrills/3-Lodging/Matakauri Lodge/Matakauri Lodge 1.png';
import matakauri2 from '../img/2-Peak Thrills/3-Lodging/Matakauri Lodge/Matakauri Lodge 2.png';
import whare1 from '../img/2-Peak Thrills/3-Lodging/Whare Kea Lodge/Whare Kea Lodge 1.png';
import whare2 from '../img/2-Peak Thrills/3-Lodging/Whare Kea Lodge/Whare Kea Lodge 2.png';
import NextArrow from '../img/slider-right.png';
import PrevArrow from '../img/slider-left.png';
import NextSliderArrow from '../img/Arrow Right.png';
import PrevSliderArrow from '../img/Arrow Left.png';

const SampleNextArrow = ({ onClick }) => (
  <div className="custom-arrow next" onClick={(e) => { onClick(e); handleClick('NextArrow'); }}>
    <img src={NextArrow} alt="Next" />
  </div>
);

const SamplePrevArrow = ({ onClick }) => (
  <div className="custom-arrow prev" onClick={(e) => { onClick(e); handleClick('PrevArrow'); }}>
    <img src={PrevArrow} alt="Previous" />
  </div>
);

  const SliderPrevArrow = ({ onClick }) => (
    <div className="custom-arrow next slick-arrow slick-next" onClick={onClick}>
      <img src={PrevSliderArrow} alt="Previous" />
    </div>
  );
  
  const SliderNextArrow = ({ onClick }) => (
    <div className="custom-arrow prev slick-arrow slick-prev" onClick={onClick}>
      <img src={NextSliderArrow} alt="Next" />
    </div>
  );

  const handleClick = (section) => {
    const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

    if (isMobile) {
      // Console logs for debugging
      //console.log(`Hover detected on section: ${section}`);
    
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'trackEventNoEcommerce',
        eventDetails: {
          category: 'photoscarousel',
          action: 'arrowclick',
          label: 'winterflydeas',
          value: 'lodging',
        },
      });
    
      // Console log after pushing to dataLayer
      //console.log('DataLayer after push:', window.dataLayer);
    }
  };

  const copyrightTexts = [
    [
      '<span class="white">© Matakauri Lodge</span>',
      '<span class="white">© Matakauri Lodge</span>',
    ],
    [
      '<span class="white">© Whare Kea Lodge</span>',
      '<span class="white">© Whare Kea Lodge</span>',
    ],
    [
      '<span class="white">© Mountainhut.nz</span>',
      '<span class="white">© Dan Kelly</span>',
    ],
    [
      '<span class="white">© Sam Worsp</span>',
      '<span class="white">© Mt Olympus</span>',
    ],
    [
      '<span class="white">© Adventure Consultants</span>',
      '<span class="white">© Adventure Consultants</span>',
    ],
    [
      '',
      '<span>© Cardrona Alpine Resorts</span>',
    ],
  ];

  export default class SimpleSlider extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isOpen: false,
        lightboxImages: [
          [matakauri1,matakauri2],
          [whare1,whare2],
          [kelly1,kelly2],
          [tophut1,tophut2],
          [kelman1,kelman2],
          [cardrona1,cardrona2],
        ],
        lightboxPhotoIndexes: Array(4).fill(0).map(() => 0), // Individual indexes for each banner
        openLightbox: Array(4).fill(false), // Array to track open lightbox for each slider
        isMobile: window.innerWidth <= 980, // Initial check for mobile screen size
        activeIndex: null, // To track the active slider
      };
    }
  
    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
    }
  
    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }
  
    handleResize = () => {
      this.setState({ isMobile: window.innerWidth <= 980 });
    };
  
    openLightboxForIndex = (index) => {
      this.setState((prevState) => ({
        isOpen: prevState.isMobile,
        openLightbox: prevState.openLightbox.map((item, idx) => (idx === index ? true : item)),
        activeIndex: index,
        photoIndex: 0, // Set photoIndex to 0 when opening the lightbox for a particular index
      }));
    };
  
  
    render() {
      const {
        isOpen,
        lightboxImages,
        lightboxPhotoIndexes,
        isMobile,
        photoIndex,
        openLightbox,
        activeIndex,
      } = this.state;
  
      const settings = {
          dots: false,
          infinite: true,
          slidesToShow: 5,
          slidesToScroll: 1,
          arrows: true,
          nextArrow: <SliderNextArrow />,
          prevArrow: <SliderPrevArrow />,
          draggable: false, // Disable mouse dragging
          swipe: false,  
          responsive: [
            {
              breakpoint: 1366,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 980,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
              }
            },
          ],
      };
  
      const settings2 = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        };
        return (
          <div className="lodging-carousel">
            <Slider {...settings}>
              {lightboxImages.map((images, index) => (
                <div key={index}>
                  <div className="thumbnail-banner">
                    <Slider {...settings2} className="">
                      {images.map((image, imgIndex) => (
                        <div
                        className="thumbnail-img thumbnail-banner-slide-img"
                        key={imgIndex}
                        onClick={() => this.openLightboxForIndex(index)}
                      >
                        <img className="full-width" src={image} alt={`Image ${imgIndex}`} />
                        <div
                            className="header-copyright top-right"
                            dangerouslySetInnerHTML={{ __html: copyrightTexts[index][imgIndex] }}
                          />
                      </div>
                      ))}
                    </Slider>
                  </div>
                {index === 0 && (
                  <div className="content">
                      <p className="number violet weight-medium">1</p>
                      <h3>Matakauri Lodge, <br/><span className="violet">Queenstown</span></h3>
                      <p>Luxury lodging with panoramic views of the Remarkables amidst nearby peaks</p>
                      <p className="small-text light-italic location">Farrycroft Row, 569 Glenorchy Road, Queenstown 9348, New Zealand</p>
                  </div>  
                )}
                {index === 1 && (
                  <div className="content">
                      <p className="number violet weight-medium">2</p>
                      <h3>Whare Kea Lodge, <br/><span className="violet">Wanaka</span></h3>
                      <p>A timeless retreat set against the dramatic backdrop of the Southern Alps</p>
                      <p className="small-text light-italic location">494 Mount Aspiring Road, Wanaka 9343, New Zealand</p>
                  </div>  
                )}
                {index === 2 && (
                  <div className="content">
                      <p className="number violet weight-medium">3</p>
                      <h3>Kelly Hut, <br/><span className="violet">Mount Larkins</span></h3>
                      <p>Fly in by helicopter to your 1880m-high lodge with winter activities at your doorstep</p>
                      <p className="small-text light-italic location">Stewart Island / Rakiura, 9818, New Zealand</p>
                  </div>  
                )}
                {index === 3 && (
                  <div className="content">
                      <p className="number violet weight-medium">4</p>
                      <h3>Top Hut, <br/><span className="violet">My Olympus</span></h3>
                      <p>Stay near the action, with night-skiing and outdoor hot tubs for the adventurous lodger</p>
                      <p className="small-text light-italic location">11220 Harper Road, Lake Coleridge Windwhistle, Canterbury, New Zealand</p>
                  </div>  
                )}
                {index === 4 && (
                  <div className="content">
                      <p className="number violet weight-medium">5</p>
                      <h3>Kelman Hut, <br/><span className="violet">Mount Cook/Aoraki</span></h3>
                      <p>Perched right next to the Tasman Glacier for spectacular views unlike any other</p>
                      <p className="small-text light-italic location">State Highway 5, Mangakakahi Rotorua 3015, New Zealand</p>
                  </div>  
                )}
                {index === 5 && (
                  <div className="content">
                      <p className="number violet weight-medium">6</p>
                      <h3>Cardrona Alpine <br/>Apartments, <span className="violet">Cardrona</span></h3>
                      <p>Stay at one of the few ski-in-ski-out resorts where you're minutes away from fun</p>
                      <p className="small-text light-italic location">Cardrona Vallery Road, Cardrona 9305, New Zealand</p>
                  </div>  
                )}
                  {/* Lightbox for mobile */}
                  {isOpen && isMobile && openLightbox[index] && (
                    <Lightbox
                      mainSrc={lightboxImages[index][photoIndex]}
                      nextSrc={lightboxImages[index][(photoIndex + 1) % lightboxImages[index].length]}
                      prevSrc={lightboxImages[index][(photoIndex + lightboxImages[index].length - 1) % lightboxImages[index].length]}
                      onCloseRequest={() => this.setState({ isOpen: false, openLightbox: Array(4).fill(false), activeIndex: null })}
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + lightboxImages[index].length - 1) % lightboxImages[index].length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + 1) % lightboxImages[index].length,
                        })
                      }
                    />
                  )}
                </div>
              ))}
            </Slider>
          </div>
        );
      }
    }