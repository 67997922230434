import React, { useEffect, useState, useRef } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import Slider from "react-slick";
import scrolldown from '../img/Arrow Down.png';
import banner from '../img/4-Chill Out and About/Hero Banner_Chill Out and About.png';
import bannerMobile from '../img/Mobile/4-Chill out and About/Hero Banner_Chill Out and About.png';
import greytown1 from '../img/4-Chill Out and About/1-Activities/Greytown Festival of Christmas/Greytown Festival of Christmas.png';
import greytown1Mobile from '../img/Mobile/4-Chill out and About/1-Activities/Greytown Festival of Christmas/Greytown Festival of Christmas.png';
import matariki1 from '../img/4-Chill Out and About/1-Activities/Matariki Festival/Matariki Festival 1.png';
import matariki2 from '../img/4-Chill Out and About/1-Activities/Matariki Festival/Matariki Festival 2.png';
import taupo1 from '../img/4-Chill Out and About/1-Activities/Taupo DeBretts Spa Resort/Taupo DeBretts Spa Resort 1.png';
import taupo2 from '../img/4-Chill Out and About/1-Activities/Taupo DeBretts Spa Resort/Taupo DeBretts Spa Resort 2.png';
import taupo1Mobile from '../img/Mobile/4-Chill out and About/1-Activities/Taupo DeBretts Spa Resort/Taupo DeBretts Spa Resort 1.png';
import taupo2Mobile from '../img/Mobile/4-Chill out and About/1-Activities/Taupo DeBretts Spa Resort/Taupo DeBretts Spa Resort 2.png';
import tikitere1 from '../img/4-Chill Out and About/1-Activities/Tikitere - Hells Gate/Tikitere - Hells Gate 1.png';
import tikitere2 from '../img/4-Chill Out and About/1-Activities/Tikitere - Hells Gate/Tikitere - Hells Gate 2.png';
import tikitere1Mobile from '../img/Mobile/4-Chill out and About/1-Activities/Tikitere - Hells Gate/Tikitere - Hells Gate 1.png';
import tikitere2Mobile from '../img/Mobile/4-Chill out and About/1-Activities/Tikitere - Hells Gate/Tikitere - Hells Gate 2.png';
import winter1 from '../img/4-Chill Out and About/1-Activities/Winter Fireplace Trail/Winter Fireplace Trail 1.png';
import winter2 from '../img/4-Chill Out and About/1-Activities/Winter Fireplace Trail/Winter Fireplace Trail 2.png';
import winter1Mobile from '../img/Mobile/4-Chill out and About/1-Activities/Winter Fireplace Trail/Winter Fireplace Trail 1.png';
import winter2Mobile from '../img/Mobile/4-Chill out and About/1-Activities/Winter Fireplace Trail/Winter Fireplace Trail 2.png';
import Dining from '../components/ChillOutDining';
import ImageWithHotspots from '../components/ImageWithHotspots-ChillOut';

import LodgingCarousel from '../components/ChillOutLodging';
import Itinerary from '../components/ChillOutItinerary';
import NextArrow from '../img/slider-right.png';
import PrevArrow from '../img/slider-left.png'
import familyIcon from '../img/Family Friendly Icon.png';
import mountain from '../img/6-Across All Pages/Footer Image_Base Mountain.png';
import booknow from '../img/Book Now Button.png';
import { useStickyBox } from 'react-sticky-box';


const ChillOut = () => {
    const [activeLink, setActiveLink] = useState('');
    const [parentWidths, setParentWidths] = useState({
        child1: 0,
        child2: 0,
        child3: 0,
        child4: 0,
    });

    const childRefs = {
        childRef1: useRef(null),
        childRef2: useRef(null),
        childRef3: useRef(null),
        childRef4: useRef(null),
    };
    const location = useLocation();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const newsletter = useRef(null);

    const trackClickEvent = (section) => {
        const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

        if (isMobile) {
            // Console logs for debugging
            //console.log(`Click detected on section: ${section}`);

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'trackEvent',
                eventDetails: {
                    category: 'StickyMenu',
                    action: section,
                },
            });

            // Console log after pushing to dataLayer
            //console.log('DataLayer after push:', window.dataLayer);
        }
    };

    const handleClick = (ref, sectionName) => (event) => {
        event.preventDefault();

        // Call trackClickEvent with the sectionName parameter
        trackClickEvent(sectionName);

        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });

            // Set a timeout to delay setting the active state
            setTimeout(() => {
                setActiveLink(sectionName);
            }, 500); // Delay in milliseconds (adjust as needed)
        }
    };

    const getParentWidth = (ref) => {
        if (ref.current) {
            const parentStyles = window.getComputedStyle(ref.current.parentElement);
            const widthWithoutPadding =
                ref.current.parentElement.offsetWidth -
                parseFloat(parentStyles.paddingLeft) -
                parseFloat(parentStyles.paddingRight);
            return widthWithoutPadding;
        }
        return 0;
    };

    useEffect(() => {
        const getSectionHeightByClass = (className) => {
            const sections = document.querySelectorAll(className);
            if (!sections || sections.length === 0) return 0;

            // Assuming all sections have the same height
            const section = sections[0];
            const sectionStyles = window.getComputedStyle(section);
            return parseFloat(sectionStyles.height);
        };

        let initialPositions = {};
        let desktopInitialPositions = {};
        let bottomPositions = {};
        let newBottomHeight = {};
        let heights = {};
        let stickyLeftMenuHeight = {};

        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            for (const section in initialPositions) {

                const topPosition = initialPositions[section];
                const bottomPosition = bottomPositions[section];

                const isMobile = window.innerWidth <= 980;
                if (isMobile) {
                    if (scrollTop >= (topPosition - 200) && scrollTop <= bottomPosition) {
                        setActiveLink(section);
                    }
                } else {
                    if (scrollTop >= (topPosition - 500) && scrollTop <= (bottomPosition)) {
                        setActiveLink(section);
                        //console.log('Current Section:', section);
                    }
                }
            }
        };

        const handleResize = () => {
            setTimeout(() => {
                const width1 = getParentWidth(childRefs.childRef1);
                const width2 = getParentWidth(childRefs.childRef2);
                const width3 = getParentWidth(childRefs.childRef3);
                const width4 = getParentWidth(childRefs.childRef4);

                setParentWidths({
                    child1: width1,
                    child2: width2,
                    child3: width3,
                    child4: width4,
                });

                initialPositions = {
                    activities: ref1.current.getBoundingClientRect().top + window.pageYOffset,
                    food: ref2.current.getBoundingClientRect().top + window.pageYOffset,
                    lodging: ref3.current.getBoundingClientRect().top + window.pageYOffset,
                    itinerary: ref4.current.getBoundingClientRect().top + window.pageYOffset,
                };
                desktopInitialPositions = {
                    activities: initialPositions.activities + 100,
                    food: initialPositions.food - 5,
                    lodging: initialPositions.lodging - 5,
                    itinerary: initialPositions.itinerary - 20,
                };

                heights = {
                    activities: getSectionHeightByClass('.activities'),
                    food: getSectionHeightByClass('.food'),
                    lodging: getSectionHeightByClass('.lodging'),
                    itinerary: getSectionHeightByClass('.itinerary'),
                };

                stickyLeftMenuHeight = {
                    activities: getSectionHeightByClass('.activities .sticky-left-menu'),
                    food: getSectionHeightByClass('.food .sticky-left-menu'),
                    lodging: getSectionHeightByClass('.lodging .sticky-left-menu'),
                    itinerary: getSectionHeightByClass('.itinerary .sticky-left-menu'),
                }

                // Calculate bottom positions
                bottomPositions = {
                    activities: initialPositions.activities + heights.activities,
                    food: initialPositions.food + heights.food,
                    lodging: initialPositions.lodging + heights.lodging,
                    itinerary: initialPositions.itinerary + heights.itinerary,
                };
                newBottomHeight = {
                    activities: bottomPositions.activities - stickyLeftMenuHeight.activities - 80,
                    food: bottomPositions.food - stickyLeftMenuHeight.food - 120,
                    lodging: bottomPositions.lodging - stickyLeftMenuHeight.lodging + 10,
                    itinerary: bottomPositions.itinerary - stickyLeftMenuHeight.itinerary - 165,
                };

                handleScroll();
            }, 1000);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        // Initial call to handleResize
        handleResize();
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    useEffect(() => {
        window.onload = () => {
            if (location.hash === "#itinerary") {
                setTimeout(() => {
                    ref4.current.scrollIntoView({ behavior: "smooth" });
                }, 500)
            }
        };
    }, [location]);

    const sliderHandleClick = (section) => {
        const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

        if (isMobile) {
            // Console logs for debugging
            //console.log(`Hover detected on section: ${section}`);

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'trackEventNoEcommerce',
                eventDetails: {
                    category: 'photoscarousel',
                    action: 'arrowclick',
                    label: 'winterflydeas',
                    value: 'activities',
                },
            });

            // Console log after pushing to dataLayer
            //console.log('DataLayer after push:', window.dataLayer);
        }
    };

    const SampleNextArrow = ({ onClick }) => {
        return (
            <div className="custom-arrow next" onClick={(e) => { onClick(e); sliderHandleClick('NextArrow'); }}>
                <img src={NextArrow} alt="Next" />
            </div>
        );
    };

    const SamplePrevArrow = ({ onClick }) => {
        return (
            <div className="custom-arrow prev" onClick={(e) => { onClick(e); sliderHandleClick('PrevArrow'); }}>
                <img src={PrevArrow} alt="Previous" />
            </div>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const activitiesStickyRef = useStickyBox(
        {
            offsetTop: 50,
            offsetBottom: 20
        }
    );
    const foodStickyRef = useStickyBox(
        {
            offsetTop: 50,
            offsetBottom: 20
        }
    );
    const lodgingStickyRef = useStickyBox(
        {
            offsetTop: 50,
            offsetBottom: 20
        }
    );
    const itineraryStickyRef = useStickyBox(
        {
            offsetTop: 50,
            offsetBottom: 20
        }
    );


    return (
        <div className="subpage-body chillOut-page">
            <div id="top" className="subPage-banner" ref={ref5}>
                <div className="banner-img desktop"><img alt="" src={banner} /></div>
                <div className="banner-img mobile"><img alt="" src={bannerMobile} /></div>
                <div className="banner-header white">
                    <h1>Chill Out and About</h1>
                    <p className="sub-header">Leave your worries behind and embrace New Zealand's culture and cuisine</p>
                </div>
                <div className="header-copyright">
                    <div className="header-copyright-left white">Pohotu Geyser</div>
                    <div className="header-copyright-right desktop">© Pataka Kai Restaurant</div>
                    <div className="header-copyright-right mobile white">© Pataka Kai Restaurant</div>
                </div>
            </div>
            <div className="sticky-menu z-index-1" >
                <ul className="">
                    <li className={activeLink === 'activities' ? 'active' : ''}>
                        <a href="#activities" onClick={handleClick(ref1, 'activities')}>
                            Activities
                        </a>
                    </li>
                    <li className={activeLink === 'food' ? 'active' : ''}>
                        <a href="#food" onClick={handleClick(ref2, 'food')}>
                            Food
                        </a>
                    </li>
                    <li className={activeLink === 'lodging' ? 'active' : ''}>
                        <a href="#lodging" onClick={handleClick(ref3, 'lodging')}>
                            Lodging
                        </a>
                    </li>
                    <li className={activeLink === 'itinerary' ? 'active' : ''} >
                        <a className="desktop" href="#itinerary" onClick={handleClick(ref4, 'itinerary')}>
                            Flexi-tinerary: Sherlyn Chan
                        </a>
                        <a className="mobile" href="#itinerary" onClick={handleClick(ref4, 'itinerary')}>
                            Flexi-tinerary
                        </a>
                    </li>
                </ul>
            </div>
            <div className="activities subpage-section">
                <div className="anchor" id="activities" ref={ref1}></div>
                <div ref={activitiesStickyRef} className="subpage-left parent">
                    <div className="sticky-left-menu child" ref={childRefs.childRef1} style={{ width: `${parentWidths.child1}px` }}>
                        <h2 className="violet">Activities</h2>
                        <h3>Soak in the relaxing winter atmosphere while enjoying cultural and culinary delights.</h3>
                        <p>Escape the heat and the bustle of summer with Air New Zealand for a relaxing winter retreat – but don't forget your togs (swimwear)! Revel in the festivities of the Māori New Year (late June to early July) and savour the fruits of the land that will warm you up from the inside out. Check out some of our best picks for the season.</p>
                    </div>
                </div>
                <div className="subpage-right">
                    <div className="w-100">
                        <div className="thumbnail-banner">
                            <Slider className="desktop" {...settings}>
                                <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={tikitere1} />
                                    <div className="header-copyright top-right">
                                        <div className="header-copyright-right">© Miles Holden</div>
                                    </div>
                                </div>
                                <div><img alt="" className="full-width" src={tikitere2} /></div>
                            </Slider>
                            <Slider className="mobile" {...settings}>
                                <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={tikitere1Mobile} />
                                    <div className="header-copyright top-right">
                                        <div className="header-copyright-right">© Miles Holden</div>
                                    </div>
                                </div>
                                <div><img alt="" className="full-width" src={tikitere2Mobile} /></div>
                            </Slider>
                        </div>
                        <div className="content">
                            <div className="d-flex align-items-center">
                                <h3>Tikitere (Hell's Gate), <span className="violet">Rotorua</span></h3>
                                <div className="family-tooltip">
                                    <img alt="" className="family-icon" src={familyIcon} />
                                    <span className="tooltip-hover-text">Family-friendly</span>
                                </div>
                            </div>
                            <p>Enjoy a fun family soak as you discover the natural healing properties of geothermal mud and sulphur mineral water, used for generations by Māori to ease joint and muscle pain.</p>
                            <p>At New Zealand's only geothermal mud bath experience, you ll get to unwind amidst an otherworldly landscape, tucked by the warm embrace of the land a perfect complement to the dropping temperatures.</p>
                            <p className="small-text light-italic location">351 State Highway 30 Tikitere, Rotorua, New Zealand</p>
                        </div>
                    </div>
                    <div className="two-col">
                        <div className="w-5">
                            <div className="thumbnail-banner">
                                <Slider className="desktop" {...settings}>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={taupo1} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right white">© Love Taupo</div>
                                        </div>
                                    </div>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={taupo2} /></div>
                                </Slider>
                                <Slider className="mobile"{...settings}>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={taupo1Mobile} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right white">© Love Taupo</div>
                                        </div>
                                    </div>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={taupo2Mobile} /></div>
                                </Slider>
                            </div>
                        </div>
                        <div className="w-5">
                            <div className="content pt-0">
                                <h3>Taupo DeBretts Spa Resort, <span className="violet">Taupo</span></h3>
                                <div className="family-tooltip">
                                    <img alt="" className="family-icon" src={familyIcon} />
                                    <span className="tooltip-hover-text">Family-friendly</span>
                                </div>
                                <p>Explore this award winning thermal resort with lodging, spa and a massive kids warm water interactive playground that's guaranteed fun for the entire family.</p>
                                <p>While your little ones are off having fun, mum and dad can try out a hot winter soak in the natural mineral hot springs, said to help soothe dry and irritated skin while easing blood circulation thanks to its higher temperature.</p>
                                <p className="small-text light-italic location">76 Napier Taupo Road, SH 5, Taupo, New Zealand</p>
                                <div className="pt-50">
                                    <div className="tip-box seat">
                                        <p>
                                            <span className="weight-medium">Fly Savvy with Air New Zealand:</span><br />
                                            Get the best economy seat by upgrading to Economy Skycouch™. Enjoy unparalleled spaciousness to spread out and lie down for a snooze – it's like a sofa bed in the skies!
                                        </p>
                                        <button className="learn-more"><a target="_blank" rel="noopener noreferrer" href="https://www.airnewzealand.com.sg/economy-skycouch?utm_campaign=SG_202304_Winter&utm_content=skycouch_tip&_gl=1*njf29q*_ga*MTk5NjE0NTEwMS4xNzA1NjM2OTgz*_ga_7W0V730RP5*MTcwNTg5MTU0MS4zLjEuMTcwNTg5MTYxMS4wLjAuMA">LEARN MORE</a></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="two-col">
                        <div className="w-4 content left">
                            <div className="d-flex align-items-center">
                                <h3>Matariki Festival, <span className="violet">Auckland</span></h3>
                                <div className="family-tooltip">
                                    <img alt="" className="family-icon" src={familyIcon} />
                                    <span className="tooltip-hover-text">Family-friendly</span>
                                </div>
                            </div>
                            <p>Treat your children to a spectacle in the skies, while learning about Mātauranga Māori, the ancestral knowledge and wisdom that is the heart and soul of the celebration.</p>
                            <p>Stargaze for the Matariki cluster that marks the Māori New Year (late June to early July), as you experience the rich history and deep admiration for the world around us. Join in the rich celebrations where people from all walks of life come together to usher in this special time of year together.</p>
                        </div>
                        <div className="w-6 image right">
                            <div className="thumbnail-banner">
                                <Slider {...settings}>
                                    <div><img alt="" className="full-width" src={matariki1} /></div>
                                    <div><img alt="" className="full-width" src={matariki2} /></div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className="two-col">
                        <div className="w-55">
                            <div className="thumbnail-banner">
                                <img alt="" className="full-width desktop" src={greytown1} />
                                <img alt="" className="full-width mobile" src={greytown1Mobile} />
                            </div>
                        </div>
                        <div className="w-45 content pt-0">
                            <div className="d-flex align-items-center">
                                <h3>Greytown Festival of <br />Christmas, <span className="violet">Greytown</span></h3>
                                <div className="family-tooltip minus-margin-top-20">
                                    <img alt="" className="family-icon" src={familyIcon} />
                                    <span className="tooltip-hover-text">Family-friendly</span>
                                </div>
                            </div>
                            <p>Christmas comes extra, extra early in New Zealand at this festive event that takes place each July. This family-friendly festival has the entire town decked in festive lights, features holiday events like Christmas jumper making and chocolate workshops, a night market, magical winter wonderland, and more.</p>
                            <p>It also features Matariki activities to usher in the Māori New Year such as stargazing and artwork, with the festival becoming a uniquely fresh kiwi take on the year end festive season we've been accustomed to.</p>
                        </div>
                    </div>
                    <div className="two-col winter">
                        <div className="w-5 content left">
                            <h3>Winter Fireplace Trail, <span className="violet">Marlborough</span></h3>
                            <p>Savour the best of New Zealand wines such as Sauvignon Blanc or Pinot Noir, pair it with fresh local seafood or game, and take in the warmth of the crackling fireplace, all while overlooking sweeping views of wintry vineyards and blue skies, typical of a winter day.</p>
                            <p>As one of New Zealand's key wine regions, Marlborough has many cellar doors open to guests during the off-peak winter months. Blessed with sunshine, winters can be pleasantly mild while you enjoy hopping from vineyard to vineyard to savour a wide variety of New Zealand's finest wines.</p>
                            <div className="travel-tip mobile">
                                <span className="weight-medium">Travel Pro Tip:</span><br />
                                New Zealand winters can vary greatly from place to place. If you're sensitive to the cold, stay further north of North Island for milder, subtropical climates.
                            </div>
                        </div>
                        <div className="w-5 image right">
                            <div className="thumbnail-banner">
                                <Slider className="desktop" {...settings}>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={winter1} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right white">© Marlborough NZ</div>
                                        </div>
                                    </div>
                                    <div className="thumbnail-banner-slide-img"><img alt="" className="full-width" src={winter2} />
                                        <div className="header-copyright top-right">
                                            <div className="header-copyright-right white">© Marlborough NZ</div>
                                        </div>
                                    </div>
                                </Slider>
                                <Slider className="mobile" {...settings}>
                                    <div><img alt="" className="full-width" src={winter1Mobile} /></div>
                                    <div><img alt="" className="full-width" src={winter2Mobile} /></div>
                                </Slider>
                                <div className="travel-tip desktop winterfireplace">
                                    <span className="weight-medium">Travel Pro Tip:</span><br />
                                    New Zealand winters can vary greatly from place to place. If you're sensitive to the cold, stay further north of North Island for milder, subtropical climates.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="scroll-down section pt-0 next-stop-food">
                <p><span className="serif-bold">Next stop,</span> <span className="violet">Food</span></p>
                <a href="#lodging" onClick={handleClick(ref2)} className="scroll-down-btn"><img src={scrolldown} alt="scroll-down" /></a>
            </div>

            <div className="food purple-bg subpage-section minus-margin-top section">
                <div className="anchor" id="food" ref={ref2}></div>
                <div ref={foodStickyRef} className="subpage-left w-27 purple-bg z-index-2 parent">
                    <div className="sticky-left-menu child" ref={childRefs.childRef2} style={{ width: `${parentWidths.child2}px` }}>
                        <h2 className="violet">Food</h2>
                        <h3>Get a taste of culture <br />and comfort to pair with <br />some delicious drinks.</h3>
                        <p>Unlock the full experience of New Zealand with Māori cooking, and unwind (with wine) while enjoying nourishing meals to heal your mind, body and soul.</p>
                    </div>
                </div>
                <div className="subpage-right w-73">
                    <Dining />
                </div>
            </div>

            <div className="scroll-down section next-stop-lodging">
                <p><span className="serif-bold">Next stop,</span> <span className="violet">Lodging</span></p>
                <a href="#lodging" onClick={handleClick(ref3)} className="scroll-down-btn"><img src={scrolldown} alt="scroll-down" /></a>
            </div>

            <div className="lodging subpage-section minus-margin-top section">
                <div className="anchor" id="lodging" ref={ref3}></div>
                <div ref={lodgingStickyRef} className="subpage-left w-27 white-bg z-index-2 parent">
                    <div className="sticky-left-menu child" ref={childRefs.childRef3} style={{ width: `${parentWidths.child3}px` }}>
                        <h2 className="violet">Lodging</h2>
                        <h3>Your ideal rest-capade <br />is waiting for you.</h3>
                        <p>Explore a range of accommodations that focus on rest and rejuvenation. Heal your mind, body and soul with everything you need in one place.</p>
                    </div>
                </div>
                <div className="subpage-right w-73">
                    <LodgingCarousel />
                </div>
            </div>

            <div className="scroll-down section">
                <p><span className="serif-bold">Next stop,</span> <span className="violet">Flexi-itinerary</span></p>
                <a href="#lodging" onClick={handleClick(ref4)} className="scroll-down-btn"><img src={scrolldown} alt="scroll-down" /></a>
            </div>

            <div className="itinerary violet-bg subpage-section minus-margin-top section">
                <div className="anchor" id="itinerary" ref={ref4}></div>
                <div ref={itineraryStickyRef} className="subpage-left w-35 violet-bg z-index-2 parent">
                    <div className="sticky-left-menu child" ref={childRefs.childRef4} style={{ width: `${parentWidths.child4}px` }}>
                        <h2 className="lightpurple">Flexi-tinerary</h2>
                        <h3>Sherlyn Chan (@sherlynchanwp)</h3>
                        <p>Take a moment to chill out and immerse yourself in New Zealand's picturesque nature, just like Sherlyn did with her family during her quick retreat.</p>
                        <p>Take a peek at some of the highlights of their relaxing family vacation, and get inspired to plan your own itinerary with her flexi-tinerary as your base — just mix and match with any suggestions from above.</p>
                    </div>
                </div>
                <div className="subpage-right w-65">
                    <Itinerary />
                    <button className="download-pdf"><a onClick={handleClick(newsletter)}>Download PDF</a></button>
                </div>
            </div>

            <div className="adventure-map darkpurple-bg subpage-section minus-margin-top section z-index-4">
                <h2 className="violet">Locate <br />your next <br />adventure</h2>
                <ImageWithHotspots />
            </div>

            <div className="darkpurple-bg mountainImg z-index-4">
                <img className="full-width" src={mountain} alt="mountain" />
            </div>

            <div className="footer-CTA text-center px-5 z-index-4 white-bg" ref={newsletter}>
                <h2 className="pb-4">Ready for your winter escape?</h2>
                <a href="https://flightbookings.airnewzealand.com.sg/vbook/actions/ext-search" target="_blank" rel="noopener noreferrer" id="winter-escape-cta"><img className="bookNow-btn" src={booknow} alt="booknow" /></a>
            </div>

            <div className="newsletter purple-bg section text-center z-index-4">
                <div className="anchor" id="newsletter" ref={newsletter}></div>
                <h2 className="pb-4">Download this itinerary plus get the latest flight deals, travel tips and more</h2><br />
<iframe src="https://xd.wayin.com/display/container/dc/5a286949-e63d-46e2-a54b-6dd107541d8b" frameborder="0" scrolling="no" width="100%" height="670" id="ngxFrame5a286949-e63d-46e2-a54b-6dd107541d8b"  allowTransparency="true"></iframe>
            </div>

            <div className="services-page sub-page purple-bg z-index-4">
                <div className="d-flex flex-wrap">
                    <div className="explore-more text-center">
                        <p className="pb-5 violet">Explore more winter holidays below</p>
                        <div className="scroll-down text-center absolute full-width pt-5">
                            <img src={scrolldown} alt="scroll-down" />
                        </div>
                    </div>
                    <div className='services-pagination-container'>
                        <div className="col-4 services-pagination-col peak d-flex flex-wrap align-items-center justify-content-center">
                            <NavHashLink to='/peak-thrills'><h2>Peak <br />Thrills</h2></NavHashLink>
                        </div>
                        <div className="col-4 services-pagination-col wonders d-flex flex-wrap align-items-center justify-content-center">
                            <NavHashLink to='/wonders-in-the-wild'><h2>Wonders in <br />the Wild</h2></NavHashLink>
                        </div>
                        <div className="col-4 services-pagination-col vroom d-flex flex-wrap align-items-center justify-content-center">
                            <NavHashLink to='/vroom-with-a-view'><h2>Vroom with <br />a View</h2></NavHashLink>
                        </div>
                    </div>
                    <div className="scroll-down text-center absolute full-width">
                        <p className="white">Back to the top</p>
                        <a href="#top"><img src={scrolldown} alt="scroll-down" /></a>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ChillOut;