import React, { Component } from 'react';
import Hammer from 'hammerjs';
import '../ImageWithHotspots.css'; // Import your CSS file
import map from '../img/2-Peak Thrills/5-Interactive Map/Peak Thrills Map.png';
import southerscenic from '../img/5-Vroom with a View/5-Interactive Map/Southern Scenic Route.png';
import crossisland from '../img/5-Vroom with a View/5-Interactive Map/Cross-island Wine Trail.png';
import snowhighway from '../img/5-Vroom with a View/5-Interactive Map/Snow Highway.png';
import forgottenworld from '../img/5-Vroom with a View/5-Interactive Map/Forgotten World Highway.png';
import freeneasy from '../img/5-Vroom with a View/5-Interactive Map/Free _ Easy Cross-island Campervan.png';
import volcanicloop from '../img/5-Vroom with a View/5-Interactive Map/The Volcanic Loop.png';

class ImageWithHotspotsVroom extends Component {
    constructor() {
      super();
      this.state = {
        activeImageIndex: 0,
        images: [
          {
            src: map,
            hotspots: [
              {
                desktopX:12,
                desktopY: 89,
                title:'Southern Scenic Route',
                text: 'Invercargill, South Island', // Include HTML line break tags
                imageUrl: southerscenic,
              },
              {
                desktopX: 27,
                desktopY: 78,
                title: 'Free & Easy Cross-island Campervan',
                text: '', // Include HTML line break tags
                imageUrl: freeneasy,
              },
              {
                desktopX:30.5,
                desktopY: 66.5,
                title:'Snow Highway',
                text: 'Queenstown, South Island', // Include HTML line break tags
                imageUrl: snowhighway,
              },
              {
                desktopX:57,
                desktopY: 56,
                title:'Cross-island Wine Trail',
                text: 'State Highway 2 & 1S, North & South Islands', // Include HTML line break tags
                imageUrl: crossisland,
              },
              {
                desktopX:76,
                desktopY: 38.5,
                title:'The Volcanic Loop',
                text: 'Tongariro National Park, North Island', // Include HTML line break tags
                imageUrl: volcanicloop,
              },
              {
                desktopX:72,
                desktopY: 34.5,
                title:'Forgotten World Highway',
                text: 'State Highway 43, North Island', // Include HTML line break tags
                imageUrl: forgottenworld,
              },
            ],
          },
        ],
        activeHotspot: null,
        popupPosition: { left: 0, top: 0 },
        buttonLabels:['Peak Thrills','Chill Out and About','Wonders in the Wild','Vroom with a View'],
        isMobile: window.innerWidth <= 980, // Check if mobile based on width
      };
    }

    trackHoverEvent = (eventName) => {
      // Define the implementation of trackHoverEvent here
      console.log(`Tracking hover event: ${eventName}`);
    };

    
    componentDidMount() {
      // Initialize Hammer.js on your hotspot container
      const hotspotContainer = document.getElementById('hotspot-image-container');
      const hammer = new Hammer(hotspotContainer);

      // Add event listeners
      hammer.on('tap', (event) => {
        const hotspot = /* calculate hotspot based on event */
        this.handleHotspotClick(hotspot);
        
        if (Hammer.DIRECTION_ALL === 'Android') {
          this.trackHoverEvent('vroomwithaview Android'); // Use this.trackHoverEvent
        } else {
          this.trackHoverEvent('vroomwithaview iOS'); // Use this.trackHoverEvent
        }
      });

      hammer.on('press', (event) => {
        const hotspot = /* calculate hotspot based on event */
        this.handleHotspotHover(hotspot);
    
        if (Hammer.DIRECTION_ALL !== 'Android') {
          this.trackHoverEvent('vroomwithaview iOS'); // Use this.trackHoverEvent
        }
      });

      window.addEventListener('resize', this.handleWindowResize);

      // Set activeHotspot to the first hotspot of the initial image
      const initialHotspot = this.state.images[this.state.activeImageIndex].hotspots[0];
      this.setState({ activeHotspot: initialHotspot });
    }
  
    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowResize);
    }
  
    handleWindowResize = () => {
      this.setState({ isMobile: window.innerWidth <= 980 });
    };
  
    handleHotspotHover = (hotspot) => {
      if (!this.state.isMobile) {
        this.setState({ activeHotspot: hotspot });
      }
    };
    
    handleHotspotLeave = () => {
      if (!this.state.isMobile && this.state.activeHotspot) {
        // Check if the mouse is moving to another hotspot before removing activeHotspot
        const stillHovering = this.state.images[this.state.activeImageIndex].hotspots.some(
          hotspot =>
            hotspot === this.state.activeHotspot ||
            (this.state.activeHotspot &&
              hotspot.desktopX === this.state.activeHotspot.desktopX &&
              hotspot.desktopY === this.state.activeHotspot.desktopY)
        );
    
        if (!stillHovering) {
          setTimeout(() => {
            this.setState({ activeHotspot: null });
          }, 300); // Adjust the delay time to match your CSS transition duration
        }
      }
    };
    handleHotspotClick = (hotspot) => {
      if (this.state.isMobile) {
        if (this.state.activeHotspot === hotspot) {
          setTimeout(() => {
            this.setState({ activeHotspot: null });
          }, 100); // Adjust the delay time as needed
        } else {
          setTimeout(() => {
            this.setState({ activeHotspot: hotspot });
          }, 100); // Adjust the delay time as needed
        }
      }
    };
    
    handleImageChange = (imageIndex) => {
      const newActiveHotspot = this.state.images[imageIndex].hotspots[0];
      this.setState({ activeImageIndex: imageIndex, activeHotspot: newActiveHotspot });
    }

      handleClosePopup = () => {
        this.setState({ activeHotspot: null });
      };       
    
      render() {
        const { images, activeImageIndex, activeHotspot, buttonLabels, isMobile } = this.state;
        const currentImage = images[activeImageIndex];

        const trackHoverEvent = (section) => {
          const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

          if (isMobile) {
          // Console logs for debugging
          //console.log(`Hover detected on section: ${section}`);
        
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'trackEventNoEcommerce',
              eventDetails: {
                category: 'map',
                action: 'hotspotclick',
                label: 'winterflydeas',
                value: section,
              },
            });
          
            // Console log after pushing to dataLayer
            //console.log('DataLayer after push:', window.dataLayer);
          }
        };
        
        return (
          <div className="map">
            <div className="image-container hotspot-image-container" id="hotspot-image-container">
              {images.map((image, index) => (
                <div
                  key={index}
                  className={`image ${index === activeImageIndex ? 'active' : ''}`}
                  style={{ display: index === activeImageIndex ? 'block' : 'none' }}
                >
                  <img
                    src={image.src}
                    alt={`Image ${index + 1}`}
                    style={{ width: '100%', height: 'auto' }}
                  />
                  {image.hotspots.map((hotspot, hotspotIndex) => (
                    <div key={hotspotIndex}>
                      <div className={`popup mobile ${activeHotspot === hotspot ? 'active' : ''}`}>
                        {activeHotspot === hotspot && (
                          <>
                            <button className="popup-close" onClick={() => this.handleClosePopup()}>
                              X
                            </button>
                            <img src={activeHotspot.imageUrl} alt="Hotspot Image" />
                            <div className="hotspot-content">
                              <h3 className="serif-bold" dangerouslySetInnerHTML={{ __html: activeHotspot.title }} />
                              <p className="small-text light-italic" dangerouslySetInnerHTML={{ __html: activeHotspot.text }} />
                            </div>
                          </>
                        )}
                      </div>
                      <div
                        className={`hotspot-container ${activeHotspot === hotspot ? 'hover-effect' : ''}`}
                        style={{
                          left: `${hotspot.desktopX}%`, // Use percentage value for left position
                          top: `${hotspot.desktopY}%`, // Use percentage value for top position
                          width: '20px',
                          height: '20px',
                        }}
                        onMouseEnter={() => this.handleHotspotHover(hotspot)}
                        onMouseLeave={this.handleHotspotLeave}
                        onClick={() => this.handleHotspotClick(hotspot)}
                        onTouchStart={(e) => {
                          e.preventDefault();
                          this.handleHotspotClick(hotspot);
                          trackHoverEvent('vroomwithaview');
                        }}
                        onTouchEnd={(e) => {
                          e.preventDefault();
                          this.handleHotspotClick(hotspot);
                          // ... additional code
                        }}
                      >
                        <div className="hotspot" />
                          <div className={`popup desktop ${activeHotspot === hotspot ? 'active' : ''}`}>
                            {activeHotspot === hotspot && (
                              <>
                                <button className="popup-close" onClick={() => this.handleClosePopup()}>
                                  X
                                </button>
                                <img src={activeHotspot.imageUrl} alt="Hotspot Image" />
                                <div className="hotspot-content">
                                  <h3 className="serif-bold" dangerouslySetInnerHTML={{ __html: activeHotspot.title }} />
                                  <p className="small-text light-italic" dangerouslySetInnerHTML={{ __html: activeHotspot.text }} />
                                </div>
                              </>
                            )}
                          </div>

                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        );
      }
    }    
    export default ImageWithHotspotsVroom;