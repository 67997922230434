import React, { Component } from 'react';
import '../ImageWithHotspots.css'; // Import your CSS file
import map from '../img/2-Peak Thrills/5-Interactive Map/Peak Thrills Map.png';
import southernlights from '../img/3-Wonders in the Wild/5-Interactive Map/Southern Lights.png';
import milford from '../img/3-Wonders in the Wild/5-Interactive Map/Milford Sound Cruise.png';
import whalewatching from '../img/3-Wonders in the Wild/5-Interactive Map/Whale Watching.png';
import zealandia from '../img/3-Wonders in the Wild/5-Interactive Map/Zealandia.png';
import tranzalpine from '../img/3-Wonders in the Wild/5-Interactive Map/Tranz Alpine Train.png';

class ImageWithHotspotsWonders extends Component {
    constructor() {
      super();
      this.state = {
        activeImageIndex: 0,
        images: [
          {
            src: map,
            hotspots: [
              {
                desktopX: 8,
                desktopY: 93,
                title:'Southern Lights, Stewart Island',
                text: 'Stewart Island, New Zealand', // Include HTML line break tags
                imageUrl: southernlights,
              },
              {
                desktopX:16,
                desktopY: 70,
                title:'Milford Sound Cruise',
                text: '1 Milford Sound Highway, Milford Sound 9679, New Zealand', // Include HTML line break tags
                imageUrl: milford,
              },
              {
                desktopX:47,
                desktopY: 69,
                title:'TranzAlpine Train, Southern Alps',
                text: 'Troup Drive, Addington, Christchurch 8011, New Zealand', // Include HTML line break tags
                imageUrl: tranzalpine,
              },
              {
                desktopX:56.5,
                desktopY: 60.5,
                title:'Whale Watching,Kaikōura',
                text: 'Whaleway Station Road, Kaikōura 7340, New Zealand', // Include HTML line break tags
                imageUrl: whalewatching,
              },
              {
                desktopX:67,
                desktopY: 56,
                title:'Zealandia, Wellington',
                text: '53 Waiapu Road, Karori, Wellington 6012, New Zealand', // Include HTML line break tags
                imageUrl: zealandia,
              },
            ],
          },
        ],
        activeHotspot: null,
        popupPosition: { left: 0, top: 0 },
        buttonLabels:['Peak Thrills','Chill Out and About','Wonders in the Wild','Vroom with a View'],
        isMobile: window.innerWidth <= 980, // Check if mobile based on width
      };
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleWindowResize);
    
      // Set activeHotspot to the first hotspot of the initial image
      const initialHotspot = this.state.images[this.state.activeImageIndex].hotspots[0];
      this.setState({ activeHotspot: initialHotspot });
    }
  
    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowResize);
    }
  
    handleWindowResize = () => {
      this.setState({ isMobile: window.innerWidth <= 980 });
    };
  
    handleHotspotHover = (hotspot) => {
      if (!this.state.isMobile) {
        this.setState({ activeHotspot: hotspot });
      }
    };
    
    handleHotspotLeave = () => {
      if (!this.state.isMobile && this.state.activeHotspot) {
        // Check if the mouse is moving to another hotspot before removing activeHotspot
        const stillHovering = this.state.images[this.state.activeImageIndex].hotspots.some(
          hotspot =>
            hotspot === this.state.activeHotspot ||
            (this.state.activeHotspot &&
              hotspot.desktopX === this.state.activeHotspot.desktopX &&
              hotspot.desktopY === this.state.activeHotspot.desktopY)
        );
    
        if (!stillHovering) {
          setTimeout(() => {
            this.setState({ activeHotspot: null });
          }, 300); // Adjust the delay time to match your CSS transition duration
        }
      }
    };
    handleHotspotClick = (hotspot) => {
      if (this.state.isMobile) {
        if (this.state.activeHotspot === hotspot) {
          this.setState({ activeHotspot: null });
        } else {
          this.setState({ activeHotspot: hotspot });
        }
      }
    };
    
    handleImageChange = (imageIndex) => {
      const newActiveHotspot = this.state.images[imageIndex].hotspots[0];
      this.setState({ activeImageIndex: imageIndex, activeHotspot: newActiveHotspot });
    }

      handleClosePopup = () => {
        this.setState({ activeHotspot: null });
      };       
    
      render() {
        const { images, activeImageIndex, activeHotspot, buttonLabels, isMobile } = this.state;
        const currentImage = images[activeImageIndex];

        const trackHoverEvent = (section) => {
          const isMobile = window.innerWidth <= 767; // Adjust the threshold as needed

          if (isMobile) {
          // Console logs for debugging
          //console.log(`Hover detected on section: ${section}`);
        
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'trackEventNoEcommerce',
              eventDetails: {
                category: 'map',
                action: 'hotspotclick',
                label: 'winterflydeas',
                value: section,
              },
            });
          
            // Console log after pushing to dataLayer
            //console.log('DataLayer after push:', window.dataLayer);
          }
        };
        
        return (
          <div className="map">
            <div className="image-container hotspot-image-container">
              {images.map((image, index) => (
                <div
                  key={index}
                  className={`image ${index === activeImageIndex ? 'active' : ''}`}
                  style={{ display: index === activeImageIndex ? 'block' : 'none' }}
                >
                  <img
                    src={image.src}
                    alt={`Image ${index + 1}`}
                    style={{ width: '100%', height: 'auto' }}
                  />
                  {image.hotspots.map((hotspot, hotspotIndex) => (
                    <>
                      <div className={`popup mobile ${activeHotspot === hotspot ? 'active' : ''}`}>
                        {activeHotspot === hotspot && (
                          <>
                            <button className="popup-close" onClick={() => this.handleClosePopup()}>
                              X
                            </button>
                            <img src={activeHotspot.imageUrl} alt="Hotspot Image" />
                            <div className="hotspot-content">
                              <h3 className="serif-bold" dangerouslySetInnerHTML={{ __html: activeHotspot.title }} />
                              <p className="small-text light-italic" dangerouslySetInnerHTML={{ __html: activeHotspot.text }} />
                            </div>
                          </>
                        )}
                      </div>
                      <div
                        key={hotspotIndex}
                        className={`hotspot-container ${activeHotspot === hotspot ? 'hover-effect' : ''}`}
                        style={{
                          left: `${hotspot.desktopX}%`, // Use percentage value for left position
                          top: `${hotspot.desktopY}%`, // Use percentage value for top position
                          width: '20px',
                          height: '20px',
                        }}
                        onMouseEnter={() => this.handleHotspotHover(hotspot)}
                        onMouseLeave={this.handleHotspotLeave}
                        onClick={() => this.handleHotspotClick(hotspot)}
                        onTouchStart={(e) => {
                          e.preventDefault();
                          this.handleHotspotClick(hotspot);
                          trackHoverEvent('wondersinthewild');
                        }}
                      >
                        <div className="hotspot" />
                          <div className={`popup desktop ${activeHotspot === hotspot ? 'active' : ''}`}>
                            {activeHotspot === hotspot && (
                              <>
                                <button className="popup-close" onClick={() => this.handleClosePopup()}>
                                  X
                                </button>
                                <img src={activeHotspot.imageUrl} alt="Hotspot Image" />
                                <div className="hotspot-content">
                                  <h3 className="serif-bold" dangerouslySetInnerHTML={{ __html: activeHotspot.title }} />
                                  <p className="small-text light-italic" dangerouslySetInnerHTML={{ __html: activeHotspot.text }} />
                                </div>
                              </>
                            )}
                          </div>

                      </div>
                    </>
                  ))}
                </div>
              ))}
            </div>
          </div>
        );
      }
    }  
    
    export default ImageWithHotspotsWonders;