import React, { useState, useEffect } from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import cableImg from '../img/5-Vroom with a View/4-KOL Itinerary (Aiken)/Wellington Cable Car.png';
import cableVid from '../img/5-Vroom with a View/4-KOL Itinerary (Aiken)/Wellington Cable Car.mp4';
import cafe from '../img/5-Vroom with a View/4-KOL Itinerary (Aiken)/Caffe Laffare.png';
import honeyImg from '../img/5-Vroom with a View/4-KOL Itinerary (Aiken)/Greytown Honey.png';
import honeyVid from '../img/5-Vroom with a View/4-KOL Itinerary (Aiken)/Greytown Honey.mp4';
import wairapapaImg from '../img/5-Vroom with a View/4-KOL Itinerary (Aiken)/Wine Trail Roadtrip.png';
import wairapapaVid from '../img/5-Vroom with a View/4-KOL Itinerary (Aiken)/Wine Trail Roadtrip.mp4';
import '../TimelineWithProgressBar.css';

const VroomItinerary = () => {
    const [isPlayingWairapapa, setIsPlayingWairapapa] = useState(false);
    const [isPlayingCable, setIsPlayingCable] = useState(false);
    const [isPlayingHoney, setIsPlayingHoney] = useState(false);
    const [expandContent1, setExpandContent1] = useState(false);
    const [expandContent2, setExpandContent2] = useState(false);
    const [expandContent3, setExpandContent3] = useState(false);
    const [expandContent4, setExpandContent4] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const togglePlayWairapapa = () => {
        setIsPlayingWairapapa(!isPlayingWairapapa);
        setIsPlayingCable(false); // Ensure other video is stopped
        setIsPlayingHoney(false);
    };

    const togglePlayCable = () => {
        setIsPlayingCable(!isPlayingCable);
        setIsPlayingWairapapa(false); // Ensure other video is stopped
        setIsPlayingHoney(false);
    };
    const togglePlayHoney = () => {
        setIsPlayingHoney(!isPlayingHoney);
        setIsPlayingWairapapa(false); // Ensure other video is stopped
        setIsPlayingCable(false);
    };

    const content1 = (
        <>
          <p>Did you know that the capital of New Zealand isn't Auckland or
Christchurch, but rather Wellington? Located at the southern tip of
North Island and nestled between hills and a harbour, it's a compact
little city with a big heart.</p>
                    <p>We started the day right with breakfast at L'affare, a renowned
establishment that has been a cornerstone of Wellington's vibrant
café and coffee culture since 1990. Their extensive menu features
delectable pastries, sandwiches, and other delectable treats that
perfectly complement your morning coffee. Speaking of coffee, don't
forget to grab a bag of their freshly roasted beans during your visit!</p>
        </>
    );
    const content2 = (
        <>
         <p>Just a 5-minute drive away from the city centre lies one of
Wellington's most popular tourist attractions - perfect for capping off
3 chill days around the capital.</p>
                    <p>The bright red cable car departs from Lambton Quay every 10
minutes and makes its way up into the hills of Kelburn, rising 120m
over a length of 612m and emerging at the top of the Wellington
Botanic Garden for a stunning view of the capital. Get your cameras
ready because this is definitely a sight to behold.</p>
        </>
    );
    const content3 = (
        <>
          <p>Hop into the car and leave the city streets behind for endless views of
pastures, mountains, skies and lakes. We drove along State Highway
2, also known as the Wine Trail, that took us out of Wellington and
into the Wairapapa region.</p>
                    <p>Just like the age-old saying, "it's not about the destination, but the
journey," this drive promises to be an experience in itself. While only
an hour's drive away from Wellington, the path through the Remutaka
Range offers stunning views that are worth slowing down for.</p>
        </>
    );
    const content4 = (
        <>
           <p>Wairapapa is home to an eclectic mix of towns, each with their own
charming personality. While the region is well-known for its vineyards
(Martinborough has over 20), we've got another item in mind.</p>
                    <p>Our destination is Greytown Honey - a family business producing the
very highest quality Mānuka, Wildflower, Kāmahi, Kānuka, Rewarewa,
Native and Clover honey, straight from the land, in South Wairarapa.
Want to get up close and personal with their bees? Join the Beekeper
Experience, an engaging and immersive opportunity that allows us to
dive into the fascinating world of beekeeping. We even got to label
and package our very own jar of honey to take home!</p>
        </>
    );

    const truncatedContent1 = expandContent1 ? content1 : (
        <>
          <p>{content1.props.children[0].props.children.substring(0, 150)}...</p>
          <p style={{ display: expandContent1 ? 'block' : 'none' }}>{content1.props.children[1]}</p>
        </>
      );
  
      const truncatedContent2 = expandContent2 ? content2 : (
          <>
            <p>{content2.props.children[0].props.children.substring(0, 150)}...</p>
            <p style={{ display: expandContent2 ? 'block' : 'none' }}>{content2.props.children[1]}</p>
          </>
      );
      const truncatedContent3 = expandContent3 ? content3 : (
        <>
          <p>{content3.props.children[0].props.children.substring(0, 150)}...</p>
          <p style={{ display: expandContent3 ? 'block' : 'none' }}>{content3.props.children[1]}</p>
        </>
      );
      const truncatedContent4 = expandContent4 ? content4 : (
        <>
          <p>{content4.props.children[0].props.children.substring(0, 150)}...</p>
          <p style={{ display: expandContent4 ? 'block' : 'none' }}>{content4.props.children[1]}</p>
        </>
      );

      const toggleContent1 = () => {
        setExpandContent1(!expandContent1);
    };

    const toggleContent2 = () => {
      setExpandContent2(!expandContent2);
    };

    const toggleContent3 = () => {
      setExpandContent3(!expandContent3);
    };
    const toggleContent4 = () => {
      setExpandContent4(!expandContent4);
    };

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 767);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
  return (
    <>
    <VerticalTimeline layout="1-column">
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ color: '#fff' }}
          contentArrowStyle={{ borderRight: '0px solid  rgb(33, 150, 243)' }}
          iconStyle={{ background: '#fff', color: '#fff' }}
        >
            <div className="timeline-body">
                <div className="timeline-vid">
                    <img alt="" className="full-width" src={cafe} />
                </div>
                <div className="timeline-content">
                    <p className="sub-header">DAY 1-2</p>
                    <h3 className="vertical-timeline-element-title">Kick off your Wellington exploration with a hearty breakfast at Caffe L'affare</h3>
                    <div className="vertical-timeline-element-body">
                            {isMobile ? truncatedContent1 : content1}
                    </div>
                    {isMobile && content1.props.children[0].props.children.length > 150 && (
                        <button onClick={toggleContent1}>
                        {expandContent1 ? 'Read less' : 'Read more'}
                        </button>
                    )}
                </div>
            </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{ color: '#fff' }}
          contentArrowStyle={{ borderRight: '0px solid  rgb(33, 150, 243)' }}
          iconStyle={{ background: '#fff', color: '#fff' }}
        >
            <div className="timeline-body">
                <div className="timeline-vid">
                    <img alt="" className="full-width" src={cableImg} onClick={togglePlayCable} />
                    {isPlayingCable && (
                        <video control controls={true} autoPlay={true} muted={true}>
                            <source src={cableVid} type="video/mp4" />
                        </video>
                    )}
                </div>
                <div className="timeline-content">
                    <p className="sub-header">DAY 3</p>
                    <h3 className="vertical-timeline-element-title">Get a bird's-eye view of the city by taking the
Wellington Cable Car to Kelburn Lookout</h3>
                    <div className="vertical-timeline-element-body">
                        {isMobile ? truncatedContent2 : content2}
                    </div>
                    {isMobile && content2.props.children[0].props.children.length > 150 && (
                        <button onClick={toggleContent2}>
                        {expandContent2 ? 'Read less' : 'Read more'}
                        </button>
                    )}
                </div>
            </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{color: '#fff' }}
          contentArrowStyle={{ borderRight: '0px solid  rgb(33, 150, 243)' }}
          iconStyle={{ background: '#fff', color: '#fff' }}
        >
            <div className="timeline-body">
                <div className="timeline-vid">
                    <img alt="" className="full-width" src={wairapapaImg} onClick={togglePlayWairapapa} />
                    {isPlayingWairapapa && (
                        <video control controls={true} autoPlay={true} muted={true}>
                            <source src={wairapapaVid} type="video/mp4" />
                        </video>
                    )}
                </div>
                <div className="timeline-content">
                    <p className="sub-header">DAY 4-5</p>
                    <h3 className="vertical-timeline-element-title">Drive out of town to explore the countryside along the Wine Trail through Wairapapa</h3>
                    <div className="vertical-timeline-element-body">
                        {isMobile ? truncatedContent3 : content3}
                    </div>
                    {isMobile && content3.props.children[0].props.children.length > 150 && (
                        <button onClick={toggleContent3}>
                        {expandContent3 ? 'Read less' : 'Read more'}
                        </button>
                    )}
                </div>
            </div>         
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{color: '#fff' }}
          contentArrowStyle={{ borderRight: '0px solid  rgb(33, 150, 243)' }}
          iconStyle={{ background: '#fff', color: '#fff' }}
        >
            <div className="timeline-body">
                <div className="timeline-vid">
                    <img alt="" className="full-width" src={honeyImg} onClick={togglePlayHoney} />
                    {isPlayingHoney && (
                        <video control controls={true} autoPlay={true} muted={true}>
                            <source src={honeyVid} type="video/mp4" />
                        </video>
                    )}
                </div>
                <div className="timeline-content">
                    <p className="sub-header">DAY 6-7</p>
                    <h3 className="vertical-timeline-element-title">Getting a taste of the region's other favourite liquid gold: honey!
</h3>
<div className="vertical-timeline-element-body">
                        {isMobile ? truncatedContent4 : content4}
                    </div>
                    {isMobile && content4.props.children[0].props.children.length > 150 && (
                        <button onClick={toggleContent4}>
                        {expandContent4 ? 'Read less' : 'Read more'}
                        </button>
                    )}
                </div>
            </div>         
        </VerticalTimelineElement>
    </VerticalTimeline>
    </>
  );
};

export default VroomItinerary;
